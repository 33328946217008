import React, { Component, createRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";

import urlSocket from "../../../helpers/urlSocket";

class OBRNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getOBRComments(obrInfo) {
    this.props.closeMe()
    try {
      urlSocket
        .post("mngobvrs/getobrcomments", {
          observationInfo: obrInfo,
          userInfo: this.props.userInfo,
        })
        .then((response) => {
          if (response.data.response_code === 500) {
            console.log("_. obr edit")
            var mandc = response.data.data;
            this.props.loadImageEditor(obrInfo, mandc);
          }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  render() {
    return (
      <div
        style={{
          padding: "20px 25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%" }}>
            <div style={{ fontSize: "1.125rem" }}>Observation</div>
            <div><span className="text-primary">{this.props.notification.sender}</span> add new observation</div>
          </div>

          <div style={{ width: "95%", marginTop: 10 }}>
            <div style={{ fontSize: "1rem" }}>Title</div>
            <div className="text-primary">{this.props.notification.notification.title}</div>
            <div style={{ marginTop: 10 }}>
              {this.props.notification.notification.description}
            </div>
          </div>
          <div style={{ width: "5%" }}>
            <buttons
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{
                fontSize: 15,
                top: 19,
                padding: "0.78rem 1.29rem",
                right: 10,
              }}
              onClick={() => {
                this.props.closeMe();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </buttons>
          </div>
        </div>
        <div style={{ padding: "20px 0" }}>
          <Button
            onClick={() => {
              this.getOBRComments(this.props.notification.notification);
            }}
            color={"primary"}
          >
            Open
          </Button>
        </div>
      </div>
    );
  }
}

export default OBRNotification;
