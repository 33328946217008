import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";

import Success from "../../assets/images/success.png";
import Failed from "../../assets/images/failed.png";

var urlSocket = require("../../helpers/urlSocket")

class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            audit_list: "",
            selectorValues: [],
            selectorValue: "",
            scheduled_data: [],
            loaded: false,
            audit_sdl_id: '',
            select_valid: false,
            alert_show: false,
            alert_msg: '',
            is_success: null,
        };
    }

    async componentDidMount() {
        this.first_api_call()
    }

    first_api_call = () => {
        try {
            urlSocket.get('/info/reset_entity_summary_users')
                .then((res) => {
                    console.log(res.data.data)
                    this.setState({
                        scheduled_data: res.data.data,
                        loaded: true
                    })
                })

        } catch (error) {
            console.log(error.toString());
        }
    }

    select_audit = (event) => {
        this.setState({
            audit_list: event,
            audit_sdl_id: event._id
        })
    }

    on_rest = e => {
        this.setState(({ selectorValues, selectorValue }) => ({
            selectorValues: [...selectorValues, ...selectorValue.replace(/[ ,]+/g, ",").split(",")],
            // selectorValue: ""
        }));

        let valid = true
        if (this.state.audit_sdl_id == "") {
            this.setState({ select_valid: true })
            valid = false
        }

        if (this.state.selectorValue == "") {
            this.setState({ dealer_valid: true })
            valid = false
        }

        if (valid) {
            this.reset_api()
        }


    };
    reset_api = () => {
        console.log("audit_sdl_id", this.state.audit_sdl_id)
        console.log("selectorValues", this.state.selectorValues)
        try {
            urlSocket.post('/info/reset_entity', {
                audit_sdl_id: this.state.audit_sdl_id,
                entity_code: this.state.selectorValues,
            })

                .then((res) => {
                    console.log(res.data)
                    if (res.data.response_code == 500) {
                        this.setState({
                            audit_sdl_id: '',
                            selectorValues: '',
                            selectorValue: '',
                            alert_show: true,
                            alert_msg: res.data.message,
                            is_success: true,
                        })
                    }
                    else {
                        this.setState({
                            alert_msg: res.data.message,
                            is_success: false,
                            alert_show: true,
                        })
                    }

                })
            this.setState({
                audit_sdl_id: '',
                selectorValues: '',
                selectorValue: '',
                audit_list: null,

            })
        } catch (error) {
            console.log(error.toString());
        }
    }

    on_type_dealer = ({ target: { value } }) => {
        this.setState({ selectorValue: value });
    };

    render() {
        const { audit_list } = this.state;
        return (

            <React.Fragment>
                {!this.state.loaded ? (<div className="page-content" style={{ background: "white", minHeight: "100vh" }} ><i className="mdi mdi-spin mdi-loading loading"> Loading </i></div>)
                    :
                    <div className="page-content" style={{ background: "white", height: "100vh" }}>
                        <Container fluid>
                            <Row>
                                <Col lg="12">
                                    <div className="d-lg-flex">
                                        <h1>Reset</h1>
                                    </div>
                                </Col>
                            </Row>
                            <AvForm onValidSubmit={this.on_rest}>
                                <Row>
                                    <Col md={3}>
                                        <label style={{ color: this.state.select_valid ? "red" : null }}>Audit List</label>
                                        <Select
                                            value={audit_list}
                                            onChange={this.select_audit}
                                            options={this.state.scheduled_data}
                                            classNamePrefix="select2-selection"
                                            getOptionLabel={(option) => option.audit_name}
                                            onFocus={() => this.setState({ select_valid: false })}

                                        />
                                    </Col>
                                    <Col>
                                        <label style={{ color: this.state.dealer_valid ? "red" : null }}>Dealer Code</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Dealer Code"
                                            onChange={this.on_type_dealer}
                                            style={{ marginRight: 20 }}
                                            value={this.state.selectorValue}
                                            onFocus={() => this.setState({ dealer_valid: false })}
                                        />

                                    </Col>
                                </Row>
                                <div style={{ marginTop: 15 }}>
                                    <Button color="primary" type="submit" >Reset</Button>
                                </div>
                            </AvForm>

                            {this.state.alert_show ? (
                                <SweetAlert
                                    title={this.state.alert_msg}
                                    custom
                                    customIcon={this.state.is_success ? Success : Failed}
                                    onConfirm={() => this.setState({ alert_show: false })}
                                >
                                </SweetAlert>
                            ) : null}
                        </Container>
                    </div>
                }
            </React.Fragment>

        );
    }
}

export default Reset;