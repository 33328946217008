
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

// users
import _ from "lodash";
import user1 from '../../../assets/images/avatar.jpg';

var urlSocket = require("../../../helpers/urlSocket");

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            userName: '',
            dataLoaded:false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }
    componentDidMount() {
        this.getSessionData();
    }

    getSessionData() {
        var data = JSON.parse(sessionStorage.getItem("authUser"))
        let userName = data.user_data.first_name
        let user_code = data.user_data.user_code
        let userData = data.user_data
        this.setState({ userName, user_code,  userData, configData: data.config_data, dataLoaded:true})

       
        
    }
    logOut() {
        {
            try {
                var macAddress = ""
                urlSocket.post('/authentication/logout', {
                    user_code: this.state.user_code,
                    mac_address: "2e8beabfd6c04bc8"
                })
                    .then((res) => {
                        console.log(res)
                        sessionStorage.removeItem('authUser');
                        sessionStorage.clear();
                        window.sessionStorage.clear();
                        this.props.history.push({ pathname: '/login' })
                    })
            } catch (error) {
                console.log(error)
            }

        }
    }

    render() {
        if(this.state.dataLoaded)
        {
        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img
                            className="rounded-circle header-profile-user"
                            src={user1}
                            alt="Header Avatar"
                        />{" "}
                        <span className="d-none d-xl-inline-block ms-1">
                            {this.state.userName}
                        </span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" href="#"><i className="bx bx-user font-size-16 align-middle mr-1"></i>Profile</DropdownItem>
                        {/* <DropdownItem tag="a" href="#"><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>Settings</DropdownItem> */}
                        <div className="dropdown-divider"></div>

                        {/* <Link  className="dropdown-item" onClick={this.logOut}> */}
                        {/* <Link to="/Test" className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>Test</span>
                        </Link> */}

                        <div className="dropdown-item" onClick={() => this.logOut()} style={{ cursor: "pointer" }}>
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>Logout</span>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
                    }
                    else
                    {
                        return null
                    }
    }
}

export default withRouter(ProfileMenu);


