import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

//Import Images

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
       
    }

  
    render() {
        return (
            <React.Fragment>
                <div className="page-content" style={{background: "white"}}>
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        {/* <Breadcrumbs title="Skote" breadcrumbItem="Chat" /> */}

                        <Row>
                            <Col lg="12">
                                <div className="d-lg-flex">
                                Settings
                                </div>
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Settings;