import React, { Component, createRef, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  
} from "reactstrap";

import moment from "moment";

import Lobby from "./lobby";
import MeetingTemplate from "./MeetingTemplate";
import EndOfMeetingOrganizer from "./EndOfMeetingOrganizer";
import EndOfMeetingParticipant from "./EndOfMeetingParticipant";
import logoDark from "../../assets/images/360-logo-new.png";
import connectivity from "../../assets/images/connectivity.png";


const _ = require('lodash')

var ws;
var WebSocketsExist;
var krpano;
var WebSocketsConnected = false;
var sndmsgInterval;

class Conference extends Component {

  meetingPlayer = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      dataloaded: false,
      meetingStarted: false,
      meetingStatus: "0",
      socketStatus: "0",
      websocket: "",
      sharedData:{},
      loader: false,
      streamingStartHere:false,
      KRPanoSyncingStarted:false,
    };
  }

  async componentDidMount() {
    var hostMeetingStatus = JSON.parse(
      sessionStorage.getItem("hostMeetingStatus")
    );
    var people = JSON.parse(sessionStorage.getItem("people"));
    var meetingInfo = JSON.parse(sessionStorage.getItem("usermeetingInfo"));
    var confPlayerControl = await JSON.parse(
      sessionStorage.getItem("confPlayerControl")
    );
    if (meetingInfo === undefined || meetingInfo === null) {
      var meetingURL = JSON.parse(sessionStorage.getItem("meetingURL"));

      window.location.href = meetingURL;
    } else {
      this.setState(
        {
          meetingInfo,
          room_id: meetingInfo.code,
          //meetingStatus: hostMeetingStatus,
          confPlayerControl,
          people,
          display_name: confPlayerControl.displayInfo.displayname,
          loader: true,
        },
        () => {
          this.initialWebSocket();
        }
      );
    }
    console.log("meetingInfo ----", meetingInfo);
  }

  async initialWebSocket() {
    if (!WebSocketsConnected) {
      WebSocketsExist = true;

      try {
         ws = new WebSocket("wss://autolearn.in/demo360ws/");
        // ws = new WebSocket("ws://localhost:7071/");
      } catch (ex) {
        WebSocketsExist = false;
      }

      if (WebSocketsExist) {
        console.log("The current browser support websocket!!!");
      } else {
        console.log("The current browser not support websocket!");
        return;
      }

      this.setState({ websocket: ws });

      ws.onopen = this.WSonOpen;
      ws.onmessage = this.WSonMessage;
      ws.onclose = this.WSonClose;
      ws.onerror = this.WSonError;
    }
  }

  WSonOpen = () => {
    console.log("websocket opened success!");
    WebSocketsConnected = true;
    if (ws.readyState === WebSocket.OPEN) {
      this.createRoom(this.state.room_id);
      this.setState({ socketStatus: "1" });
    }
  };

  createRoom = (room) => {
    if (ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ createRoom: room }));
      this.joinRoom(room);
    }
  };

  joinRoom(room) {
    if (ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          joinUser: {
            roomId: room,
            user_code: this.state.people.user_code,
            userinfo: this.state.people,
            userDisplayName: this.state.display_name,
          },
        })
      );

      this.setState({ dataloaded: true });
      this.startMeeting(room);
    }
  }

  startMeeting = (room) => {
    console.log(
      "_. this.state.people.role",
      this.state.people.role,
      this.state.meetingStarted
    );
    if (this.state.people.role === "Organizer") {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(
          JSON.stringify({
            meetingStarted: {
              roomId: room,
            },
          })
        );
      }
    }
  };

  WSonMessage = (event) => {
    var data = JSON.parse(event.data);
   // console.log("_.meeting socket info", data);

    var getScokectUser = _.filter(data.users, {userSocketId:this.state.people.user_code})[0]
    if (data.meetingStatus === "1" && !this.state.meetingStarted) {
      this.sendActivities(data)
      this.setState({
        meetingStarted: true,
        meetingStatus: data.meetingStatus,
      })
      
    }

    if(data.meetingStatus === "1")
    {
      this.sendActivities(data)
    }

    if(data.meetingStatus === "2" && getScokectUser.role === "Organizer")
    {
      console.log("_.meeting over")
      this.setState({
        meetingStatus: data.meetingStatus,
      });
      ws.send(
        JSON.stringify({
          destroyRoom: {
            roomId: this.state.room_id,
          },
        })
      );
      ws.close()
      return
    }
   if(data.meetingStatus === "2"  && getScokectUser.role !== "Organizer"){
    console.log("_.am here  ")
      this.setState({
        meetingStatus: data.meetingStatus,
      });
      ws.close()
      return
    }

    if(data.asktoUnMute !== undefined)
    {
      
      this.setState({asktoUnmuteMe:true, unnmuteuser:data.asktoUnMute}, ()=>{
        this.meetingPlayer.current.asktoUnmuteMe()
      })
      
    }

    if(data.incomingChatMessage !== undefined)
    {
      this.setState({incomingChatMessage:data.incomingChatMessage}, () => {
        this.meetingPlayer.current.getChatMessage()
      })
      
    }

    if(data.streamingStarted !== undefined )
    {
      console.log("_.data.streamingStarted", data.streamingStarted, this.state.streamingStartHere)
      if(data.streamingStarted && !this.state.streamingStartHere)
      {
        console.log("_. stream sharing")
        this.setState({
          streamingData:{
            streamingStarted:data.streamingStarted,
            sharedData:data.sharedData,
            jitsiTileViewMode:data.setJitsiTileViewMode
          },
          streamingStartHere:true
        }, () => {
          this.meetingPlayer.current.getSharingData("from index")
        })
        
      }
      if(data.streamingStarted === false && this.state.streamingStartHere)
      {
        this.setState({
          streamingData:{
            streamingStarted:data.streamingStarted,
            jitsiTileViewMode:data.setJitsiTileViewMode
          },
          streamingStartHere:false
        }, () => {
          this.meetingPlayer.current.streamingStopped()
        })
      }
    }

    if(data.streamingStarted && data.flatPlayerSyncing === "start" && !getScokectUser.enableKRPanoSync)
    {
      this.setState({flatPlayerSyncingStarted:true}, () => {
        this.meetingPlayer.current.hideFlatPlayerControls()
      })
    }

    if(data.streamingStarted && data.flatPlayerSyncing === "stop" && !getScokectUser.enableKRPanoSync )
    {
      this.setState({flatPlayerSyncingStarted:false}, () => {
        this.meetingPlayer.current.showFlatPlayerControls()
      })
    }

    if(data.streamingStarted && data.fpPlayPause !== undefined && data.fpPlayPause !== null && data.muteAction === undefined)
    {
      console.log("_. fp play pause", data.fpPlayPause)
        this.meetingPlayer.current.playPauseFlatPlayer(data.fpPlayPause, data.videoCurrentTime)
    }

    if((data.muteStatus !== undefined && data.muteStatus !== null))
    {
      if(!getScokectUser.enableKRPanoSync && data.streamingStarted && data.flatPlayerSyncing === "start" ){
        console.log("_. fp play pause", data.fpPlayPause)
        this.meetingPlayer.current.muteUnmuteFlatPlayer(data.muteStatus, data.volumeLevel)
      }
    }
    

    if(data.KRPanoSyncing === "start" && !getScokectUser.enableKRPanoSync)
    {
      this.setState({KRPanoSyncingStarted:true}, () => {
        this.meetingPlayer.current.stopKRPanoMouseAction()
      })
    }


    if(data.KRPanoSyncing === "stop" )
    {
      this.setState({KRPanoSyncingStarted:false}, () => {
        this.meetingPlayer.current.startKRPanoMouseAction()
      })
    }

    if(data.KRPanoSyncing === null  && !this.state.socketUser.enableKRPanoSync)
    {
      this.setState({KRPanoSyncingStarted:false})
    }

    if(data.KRPanoViewPoints !== null && data.KRPanoViewPoints !== undefined && !this.state.socketUser.enableKRPanoSync)
    {
      //console.log("_. receiving data", data.KRPanoViewPoints)
      this.meetingPlayer.current.dataSyncingToKRPano(data.KRPanoViewPoints)
    }

    // console.log("_. streamplaypause", data.streamPlaying) 

    if(data.streamPlaying !== undefined && data.streamPlaying !== null)
    {
      console.log("_. streamplaypause", data.streamPlaying)
        this.meetingPlayer.current.playPauseSyncVideo(data.streamPlaying, data.videoCurrentTime)
    }

    if(data.obrNotification !== undefined && !this.state.socketUser.enableKRPanoSync)
    {
      this.meetingPlayer.current.getObrNotification(data.obrNotification)
    }


    if (data.message !== undefined) {
      this.setState({ meetingMessage: data.message });
    } else {
      this.setState({
        meetingData: data,
      });
    }

    if (data.allowPermission !== undefined) 
    {
      this.setState({ controlPermissions: data.allowPermission });
    }

   
  };


  sendActivities = (data) => {

    var getScokectUser = _.filter(data.users, {userSocketId:this.state.people.user_code})[0]
    console.log("_.roominfo", data)
    this.setState({participants: data.users, socketUser:getScokectUser, roomInfo:data})
    if(getScokectUser.MuteMicAll)
    {
      this.setState({MuteMicAll:true})
      this.meetingPlayer.current.muteMe()
      return
    }

    if(getScokectUser.MuteMicAll === false){
      this.setState({MuteMicAll:false})
      this.meetingPlayer.current.unMuteMe()
      return
    }

  }

  meetingEndSession(mtInfo) {
    //ws.close();
    this.setState({ meetingStatus: "finished", meetingSocketInfo: mtInfo });
  }


  WSonClose = (event) => {
    console.log("Websocket closed.");
    this.setState({ socketStatus: "0" });
    if (event.code !== 1000) {
      WebSocketsConnected = false;
      this.initialWebSocket();
    }
    else
    {
      // if(this.state.socketUser.role === "Organizer")
      // {
      //   this.setState({meetingStatus:"2"})
      // }
      this.setState({meetingStatus:"2"})
    }
  };

  WSonError() {
    console.log("Websocket error occur.");
  }

  WebSocketSendMessage = () => {};

  render() {
    if (this.state.dataloaded) {
      return (
        <React.Fragment>
          <div
            className="page-content"
            style={{
              height: "100vh",
              padding: "10px 0 0 0",
              backgroundColor:
                this.state.meetingStatus === "1" ? "#1a1a1a" : "#ffffff",
            }}
          >
            <Container
              fluid
              className="p-0 h-100"
              style={{ overflow: "hidden" }}
            >
              {/* ------- Header ---------- */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 5,
                  paddingLeft: 20,
                  alignItems: "center",
                }}
              >
                <div style={{width:400, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                  <div className="my-2" >
                    <img src={logoDark} alt="" height="40" />
                  </div>
                  <div style={{ width: "100%", padding: "0px 10px" }}>
                    <span
                      className={
                        this.state.meetingStatus === "1"
                          ? "font-size-18 fw-bold text-white"
                          : "font-size-18 fw-bold"
                      }
                    >
                      MEET 360
                    </span>
                  </div>
                </div>
                <div style={{width:"100%", textAlign:"end", paddingRight:20, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"end"}}>
                <span className="font-size-14 text-white">
                  {this.state.meetingInfo.mname}
                </span>
                <div>
                <span className="font-size-11 text-white">
                  Organized by 
                </span>
                <span className="font-size-11 text-white"> {this.state.meetingInfo.organized_by}</span>
                </div>
                
                </div>
              </div>

              {/* -------- Content Container */}

              {!this.state.meetingStarted ? (
                <Lobby />
              ) : this.state.meetingStatus === "1" ? (
                this.state.socketStatus === "1" ? (
                  <MeetingTemplate
                    ws={this.state.websocket}
                    room={this.state.room_id}
                    roomInfo={this.state.roomInfo}
                    loggedUserInfo={this.state.people}
                    user_code={this.state.people.user_code}
                    meetingData={this.state.meetingData}
                    meetingInfo={this.state.meetingInfo}
                    confPlayerControl={this.state.confPlayerControl}
                    permissions={this.state.controlPermissions}
                    participants={this.state.participants}
                    socketUser={this.state.socketUser}
                    asktoUnmuteMe={this.state.asktoUnmuteMe}
                    unnmuteuser={this.state.unnmuteuser}
                    incomingChatMessage={this.state.incomingChatMessage}
                    streamingData={this.state.streamingData}
                    KRPanoSyncingStarted={this.state.KRPanoSyncingStarted}
                    flatPlayerSyncingStarted={this.state.flatPlayerSyncingStarted}
                    ref={this.meetingPlayer}
                  />
                ) : null
              ) : this.state.meetingStatus === "2" ? (
                this.state.people.role === "Organizer" ? (
                  <EndOfMeetingOrganizer meetingInfo={this.state.meetingInfo} />
                ) : (
                  <EndOfMeetingParticipant meetingInfo={this.state.meetingInfo} />
                )
              ) : null}
            </Container>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className="page-content"
            style={{ height: "100vh", padding: 10 }}
          >
            <Container style={{ height: "99vh" }}>
              <div
                style={{
                  display: "flex",
                  height: "99vh",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={connectivity}
                    style={{ resizeMode: "contain", marginBottom: 20 }}
                  />
                  <div>Establishing connection</div>
                  <div style={{ fontsize: 10, color: "blue" }}>
                    If the connection is not established, please try again after
                    sometime.
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Conference;
