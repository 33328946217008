import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardTitle,
  Alert,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import profile from "../../assets/images/logo_blue.png";

import Logo from "../../assets/images/eaudit.jpg";
import logoDark from "../../assets/images/360_L.png";

import { Jutsu } from "react-jutsu"; // Component
import { useJitsi } from "react-jutsu"; // Custom hook
import moment from "moment";

var urlSocket = require("../../helpers/urlSocket");

class preJoinMeeting extends Component {
  domain = "jitsi.autolearn.in";
  api = {};

  constructor(props) {
    super(props);
    this.state = {
      login_status: false,
      loading: false,
      login_msg: "",
      height: window.innerHeight,
      isVideoMuted: false,
      isAudioMuted: false,
      dataloaded: false,
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // Login Submit
  handleValidSubmit(event, values) {
    this.setState({ hostStarted: false, dataloaded: false });

    setTimeout(() => {
      try {
        urlSocket
          .post("mngmtg/updatehost", {
            confInfo: values,
            meeting_status: "1",
            meetingInfo: this.state.meetingInfo,
          })
          .then((response) => {
            console.log("login", response.data);

            if (response.data.response_code == 500) {
              this.setState({ hostStarted: true }, function () {
                sessionStorage.removeItem("usermeetingInfo");
                sessionStorage.removeItem("confPlayerControl");

                var playerControl = {
                  isAudioMuted: this.state.isAudioMuted,
                  isVideoMuted: this.state.isVideoMuted,
                  displayInfo: values,
                };
                sessionStorage.setItem(
                  "usermeetingInfo",
                  JSON.stringify(this.state.meetingInfo)
                );
                sessionStorage.setItem(
                  "confPlayerControl",
                  JSON.stringify(playerControl)
                );
                this.props.history.push("/hostng");
              });
            }
          });
      } catch (error) {
        console.log(error.toString());
      }
    }, 3000);
  }

  componentDidMount() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var meetingInfo = JSON.parse(sessionStorage.getItem("usermeetingInfo"));
    this.setState(
      {
        userInfo: data.user_data,
        meetingInfo,
        meeting_id: meetingInfo._id,
        dataloaded: true,
      },
      () => {
        if (window.JitsiMeetExternalAPI) {
          this.startMeet();
        } else {
          alert("JitsiMeetExternalAPI not loaded");
        }
      }
    );
  }

  startMeet = () => {
    console.log("meeeeet start");
    const options = {
      roomName: this.state.meetingInfo.code,
      width: "100%",
      height: this.state.height - 500,
      configOverwrite: {
        prejoinPageEnabled: true,
        startWithVideoMuted: this.state.isVideoMuted,
        startWithAudioMuted: this.state.isAudioMuted,
        toolbarButtons: [],
        enableDisplayNameInStats: false,
        backgroundAlpha: 1,
        disableFilmstripAutohiding: true,
        generateRoom: false,
        remoteVideoMenu: {
          disableKick: true,
        },
      },

      interfaceConfigOverwrite: {
        DISABLE_VIDEO_BACKGROUND: true,
        SHOW_BRAND_WATERMARK: false,
        SHOW_JITSI_WATERMARK: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: true,
      },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName: this.state.userInfo.first_name,
      },
    };
    this.api = new window.JitsiMeetExternalAPI(this.domain, options);

    this.api.addEventListeners({
      readyToClose: this.handleClose,
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      audioMuteStatusChanged: this.handleMuteStatus,
      videoMuteStatusChanged: this.handleVideoStatus,
    });
  };

  handleClose = () => {
    console.log("handleClose");
  };

  handleParticipantLeft = async (participant) => {
    console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant) => {
    console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    console.log("handleVideoConferenceLeft");
    return 
  };

  handleMuteStatus = (audio) => {
    console.log("handleMuteStatus", audio); // { muted: true }
  };

  handleVideoStatus = (video) => {
    console.log("handleVideoStatus", video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  // custom events
  executeCommand(command) {
    this.api.executeCommand(command);
    if (command == "hangup") {
      return this.props.history.push("/thank-you");
    }

    if (command == "toggleAudio") {
      this.setState({ isAudioMuted: !this.state.isAudioMuted });
    }

    if (command == "toggleVideo") {
      this.setState({ isVideoMuted: !this.state.isVideoMuted });
    }
  }

  render() {
    if (this.state.dataloaded) {
      return (
        <React.Fragment>
          <div className="page-content" style={{ minHeight: "100vh" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
                alignItems: "center",
              }}
            >
              <div style={{ width: "80%", padding: "20px 20px", }}>
                <h5>{this.state.meetingInfo.mname}</h5>
                <span className="font-size-11 text-secondary">Organized by</span> <span> {this.state.meetingInfo.organized_by}</span>
                <div className="font-size-11 text-primary">{moment(this.state.meetingInfo.scheduled_on).format("LLLL")}</div>
              </div>
              <div
                style={{
                  width: "20%",
                  padding: "0 20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Link to="/sdlmtng">
                  <Button color="primary">
                    Back <i className="mdi mdi-arrow-left"></i>
                  </Button>
                </Link>
              </div>
            </div>
            <Container fluid className="p-0">
              <Row className="g-0">
                <Row className="w-100">
                  <div className="d-flex flex-row h-100 align-items-center justify-content-center">
                    <Col className="col-4">
                      <div
                        id="jitsi-iframe"
                        className="border rounded-3 mb-2"
                      ></div>
                      
                      <div className="d-md-flex w-100">
                        <ul class="list-group list-group-horizontal-md py-1"                          >
                          <li
                            onClick={() => {this.executeCommand('toggleVideo')}}
                            class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter" style={{width:40}}
                          >
                            <i
                              className={
                                this.state.isVideoMuted
                                  ? "mdi mdi-video-off text-white"
                                  : "mdi mdi-video text-white"
                              }
                              style={{ fontSize: 25 }}
                            ></i>
                          </li>
                          <li
                            onClick={() => {this.executeCommand('toggleAudio')}}
                            class="list-group-item border-0 p-0 bg-dark d-flex flex-row justify-content-center align-items-ceenter" style={{width:40}}
                          >
                            <i
                              className={
                                this.state.isAudioMuted
                                  ? "mdi mdi-microphone-off text-white"
                                  : "mdi mdi-microphone text-white"
                              }
                              style={{ fontSize: 25 }}
                            ></i>
                          </li>
                          
                        </ul>
                      
                      </div>
                    </Col>

                    <Col xl={3} className="p-4">
                      <div className="my-auto">
                        <div className="mt-4 p-2">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={this.handleValidSubmit}
                          >
                            {this.state.login_status ? (
                              <Alert color="danger">
                                {this.state.login_msg}
                              </Alert>
                            ) : null}
                            {/* Check the Username and Password */}

                            <div className="form-group">
                              <AvField
                                name="displayname"
                                label="Display Name"
                                className="form-control"
                                placeholder="Enter Your Name"
                                value={this.state.userInfo.first_name}
                                required
                              />
                            </div>

                            <div className="mt-4 d-block">
                              <Button
                                className="w-100 waves-effect waves-light"
                                color="info"
                                type="submit"
                              >
                                {" "}
                                {this.state.loading
                                  ? "Loading"
                                  : "Start Meeting"}{" "}
                              </Button>
                            </div>
                          </AvForm>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Row>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Container fluid className="p-0">
            <div className="auth-full-page-content ">
              <div className="w-100">
                <div className="d-flex flex-row h-100 align-items-center justify-content-center">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Start Hosting</CardTitle>
                      <p className="card-title-desc">
                        Your meeting going to start shortly, please wait ...
                      </p>
                      <div>
                        <div
                          className="spinner-grow text-primary m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow text-secondary m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow text-success m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow text-info m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow text-warning m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow text-danger m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow text-dark m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </Container>
        </React.Fragment>
      );
    }
  }
}

export default preJoinMeeting;
