import React, { Component, createRef, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Table,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import moment from "moment";
import LobbyImage from "../../assets/images/meetingEnd.jpg";
import logoDark from "../../assets/images/360_L.png";

class MeetingEndSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataloaded: false,
    };
    //

  }

  componentDidMount() {
    var meetingInfo = this.props.meetingInfo;
    console.log("meetingInfo", meetingInfo);
    let start = moment(meetingInfo.mstarted_on, "YYYY-MM-DD hh:mm:ss"); // some random moment in time (in ms)
    let end = moment(meetingInfo.mends_on, "YYYY-MM-DD hh:mm:ss"); // some random moment after start (in ms)
    let diff = end.diff(start);

    // execution
    let f = moment.utc(diff).format("HH:mm:ss");

    this.setState({
      meetingInfo,
      meetingduration: f,
      dataloaded: true,
    });

    sessionStorage.removeItem("usermeetingInfo");
    sessionStorage.removeItem("partInfo");
    sessionStorage.removeItem("confPlayerControl");
  }

  redirectTo() {
    var meetingURL = JSON.parse(sessionStorage.getItem("meetingURL"));
    window.location.href = meetingURL;
  }

  render() {
    if (this.state.dataloaded) {
      return (
        <React.Fragment>
          <div
            className="page-content"
            style={{ height: "100vh", padding: 10 }}
          >
            <Container fluid className="p-0 h-100">
              <div className="w-100 h-100">
                <div className="d-flex flex-col h-100 align-items-center justify-content-center">
                  <img src={LobbyImage} style={{ width: "35%" }} />
                  <Col lg={4}>
                    <Card style={{ boxShadow: "none" }}>
                      <CardHeader className="h5 bg-transparent border-bottom text-uppercase">
                        <div className="my-3 font-size-20">
                          {this.state.meetingInfo.mname}
                        </div>
                      </CardHeader>
                      <CardBody>
                        <CardTitle className="h4 mt-0 text-primary">
                          <span className="font-size-15 text-dark fw-light">
                            Organized by
                          </span>{" "}
                          <span className="font-size-17 text-dark fw-light">
                            {" "}
                            {this.state.meetingInfo.organized_by}
                          </span>
                          <div className="font-size-12 text-primary mt-1 fw-light">
                            Scheduled on -{" "}
                            {moment(this.state.meetingInfo.scheduled_on).format(
                              "LLLL"
                            )}
                          </div>
                          <div className="font-size-12 my-3 text-primary">
                            Total duration {this.state.meetingduration}
                          </div>
                        </CardTitle>
                        <CardText className="my-3 font-size-18">
                          Thanks for participating in this meeting <br />
                          <Button
                            className="waves-effect waves-light mt-2"
                            style={{ backgroundColor: "#1a73e8", border: 0 }}
                            type="submit"
                            onClick={() => this.redirectTo()}
                          >
                            Re join
                          </Button>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </div>
              </div>
            </Container>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default MeetingEndSession;
