import React, { Component, createRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
  Tooltip,
} from "reactstrap";

class LeftPanelControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuExpanded: false,
    };
  }
  render() {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-end"
        style={{
          height: this.props.panelHeight,
        }}
      >
        <ul class="list-group" style={{ paddingLeft: 12 }}>
        {this.props.syncEnabled ? (
          <li
            class="list-group-item border-0 p-0  flex-row justify-content-center align-items-ceenter"
            id="sync"
            style={{
              width: 40,
              display:  "flex",
              backgroundColor:
                this.props.flatPlayerSyncing === "start"
                  ? "rgba(255,3,3,0.6)"
                  : "rgba(52,58,64,0.5)",
            }}
            onClick={() => this.props.flatPlayerSyncStartStop()}
          >
            <i
              className={
                this.props.flatPlayerSyncing === "start"
                  ? "mdi mdi-sync-off text-white"
                  : "mdi mdi-sync text-white"
              }
              style={{ fontSize: 25 }}
            ></i>
          </li>
        ):null}
        {this.props.screenShotEnabled && 
          <li
            class="list-group-item border-0 p-0  flex-row justify-content-center align-items-ceenter"
            id="zoomout"
            style={{
              width: 40,
              display:  "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
            onClick={() => this.props.takeFPScreenShot()}
          >
            <i
                className="mdi mdi-camera text-white"
                style={{ fontSize: 25 }}
              ></i>
          </li>
        }
        </ul>
      </div>
    );
  }
}

export default LeftPanelControls;
