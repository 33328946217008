import React, { Component, createRef, useState, useRef } from "react";
import { useScreenshot } from "use-react-screenshot";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Media,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown, 
} from "reactstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import moment from "moment";
import classNames from "classnames";
import useDemoKrpano from "./Components/useKrpano";
import useKrpano from "react-krpano-hooks";

import socketIOClient from "socket.io-client";

import Hls from "hls.js";
import uuid from "react-uuid";

import Imageproessing from "./imageprocess";
import OBRImageEditor from "./obrImageEditor";
import OBRList from "./observationList";

import KRPanoControls from "./Components/KRPanoControls";
import ConfControls from "./Components/ConfControls";
import LeftPanelControls from "./Components/leftPanel"

import Report from "../report/reportComponent";
import logoDark from "../../assets/images/360_L.png";

import WebRTCStream from "./Components/webrtc";
import UnMuteMessage from "./Components/UnmuteMessage"
import OBRNotification from "./Components/OBRNotification";

import "./videoPlayer.css"


//import MaterialIcon from 'material-design-icons';

interface IProps {}
interface IState {
  emails: string[];
}

const _ = require("lodash");
const $ = require("jquery");
var urlSocket = require("../../helpers/urlSocket");

var hls = new Hls({
  debug: false,
  enableWorker: false,
  lowLatencyMode: true,
  backBufferLength: 90,
  capLevelToPlayerSize: false,
  startLevel: -1,
});

const LoadingPage = ({ isFadingout = false }) => {
  const wrapperClasses = classNames('loading-page', {
    'loading-page--fade-out': isFadingout,
  })
  return (
    <div  style={{position:"absolute", display:"flex", width:"100%", height:"100vh", justifyContent:"center", alignItems:"center", zIndex: 2000,}}>
      <div className="hint">Loading...</div>
      <div className="loading-spin" />
    </div>
  )
}

const Krpano360 = () => {
  const {
    showLoadingPage,
    isLoaded,
    containerRef,
  } = useDemoKrpano({height:window.innerHeight-135})

  return (
    <>
      {showLoadingPage && <LoadingPage isFadingout={isLoaded} />}

      <div>
      <div
        ref={containerRef}
        style={{ position: "relative" }}
        className="custom-styles"
      />
    </div>
    </>
  )
}

let peerConnection;
const config = {
  iceServers: [

    { 
      "urls": "stun:stun.l.google.com:19302",
    },
    { 
      "urls": "stun:stun1.l.google.com:19302",
    },
    { 
      "urls": "stun:stun2.l.google.com:19302",
    },
    { 
      "urls": "stun:stun3.l.google.com:19302",
    },
    { 
      "urls": "stun:stun4.l.google.com:19302",
    },
    { urls: 'stun:meet-jit-si-turnrelay.jitsi.net:443' },
    
    {
      "urls": "turn:15.207.100.160:3478?transport=tcp",
      "username": "eprmadmin",
      "credential": "Eprm123"
    }
  ],
};

var mySKTConnection = "";
var sndmsgInterval;

class HOSTPlayer extends Component {
  domain = "jitsi.autolearn.in";
  api = {};
  iframe;

  editorRef = React.createRef();
  obreditor = React.createRef();
  scrollbox = React.createRef();
  streambox = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      dataloaded: false,
      websocket: "",
      drawOpen: false,
      height: window.innerHeight,
      footerHeight: 135,
      messages: [],
      chatMessages: [],
      unread: false,
      ptList: [],
      particsipantsIDs: [],
      emails: [],
      obInfo: {},
      isMicMuteAll: false,
      inviteOver: true,
      jitsipointer: "none",
      sharedData: {},
      streamingStarted: false,
      jitsiloaded: false,
      KRPanoLoaded: false,
      fpVideoPlaying: false,
    };
    this._scrollBarRef = null;
    this.tog_fullscreen = this.tog_fullscreen.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.tog_meetingClose = this.tog_meetingClose.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.tog_addprtPanel = this.tog_addprtPanel.bind(this);
    this.tog_report = this.tog_report.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeContainer);
    this.setState(
      {
        websocket: this.props.ws,
        meetingInfo: this.props.meetingInfo,
        isAudioMuted: this.props.confPlayerControl.isAudioMuted,
        isVideoMuted: this.props.confPlayerControl.isVideoMuted,
        display_name: this.props.confPlayerControl.displayInfo.displayname,
        imagePreviewUrl: this.props.loggedUserInfo.img_url,
        imageToken: this.props.loggedUserInfo.img_url,
        HostPermission: _.filter(this.props.participants, {
          userSocketId: this.props.user_code,
        })[0].enableHostPermssion,
        dataloaded: true,
      },
      () => {
        this.loadJitsi();
      }
    );
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  resizeContainer = () => {
    this.setState({
      height: window.innerHeight,
    });
  };

  tog_fullscreen() {
    this.setState((prevState) => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));
    this.removeBodyCss();
  }

  tog_center() {
    this.setState((prevState) => ({
      modal_center: !prevState.modal_center,
    }));
    this.removeBodyCss();
  }

  tog_meetingClose() {
    this.setState((prevState) => ({
      meetingClose: !prevState.meetingClose,
    }));
    this.removeBodyCss();
  }

  tog_addprtPanel() {
    this.setState((prevState) => ({
      addprtPanel: !prevState.addprtPanel,
    }));
    this.removeBodyCss();
  }

  tog_report() {
    this.setState((prevState) => ({
      reportModal: !prevState.reportModal,
    }));
    this.removeBodyCss();
  }

  onDrawerClose() {
    this.setState({ drawOpen: false, obrlistEnabled: false, unread: false });
  }

  scrollToBottom = () => {
    const curr = this.scrollbox.current;
    if (curr) {
      setTimeout(() => {
        curr.scrollTop = this.scrollbox.current.scrollHeight;
      }, 100);
    }
  };

  loadJitsi = () => {
    const options = {
      roomName: this.state.meetingInfo.code,
      width: "100%",
      height: "100%", //this.state.height - this.state.footerHeight,
      configOverwrite: {
        prejoinPageEnabled: false,
        disableSelfView: false,
        startWithVideoMuted: this.state.isVideoMuted,
        startWithAudioMuted: this.state.isAudioMuted,
        setTileView: false,
        notifications: [],
      },
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        DISABLE_VIDEO_BACKGROUND: true,
        SHOW_BRAND_WATERMARK: false,
        SHOW_JITSI_WATERMARK: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: true,
        TOOLBAR_BUTTONS: [],
      },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName: this.state.display_name,
      },
    };
    this.api = new window.JitsiMeetExternalAPI(this.domain, options);

    this.api.addListener("videoConferenceJoined", () => {
      this.executeCommand("setTileView", true);
      this.setState({ jitsiloaded: true }, () => {
        setTimeout(() => {
          this.updateUserStatus();
          this.checkStreaming();
        }, 50);
      });
    });

    //this.executeCommand("setTileView", true);
    // this.api.addListener("incomingMessage", this.incomingMessageFunction);
    // this.api.addListener("outgoingMessage", this.outgoingMessageFunction);

    // this.api.addEventListeners({
    //   readyToClose: this.handleClose,
    //   participantLeft: this.handleParticipantLeft,
    //   participantJoined: this.handleParticipantJoined,
    //   videoConferenceJoined: this.handleVideoConferenceJoined,
    //   videoConferenceLeft: this.handleVideoConferenceLeft,
    //   audioMuteStatusChanged: this.handleMuteStatus,
    //   videoMuteStatusChanged: this.handleVideoStatus,
    //   screenSharingStatusChanged: this.ssscFunction,
    // });
  };

  muteMe = () => {
    this.api.isAudioMuted().then((muted) => {
      console.log("_.mute", this.props.socketUser.MuteMicAll, muted);
      if (!muted) {
        this.api.executeCommand("toggleAudio");
        console.log("_. am in");
        this.setState({
          isAudioMuted: this.props.socketUser.MuteMicAll,
          isMicMuteAll: false,
        });
      }
    });
  };

  unMuteMe = () => {
    this.api.isAudioMuted().then((muted) => {
      console.log("_.unnmute", this.props.socketUser.MuteMicAll);
      if (muted) {
        this.setState({ isAudioMuted: false, isMicMuteAll: true });
        this.api.executeCommand("toggleAudio");
      }
    });
  };

  tgless(command) {
    this.api.executeCommand(command);
  }

  // custom events
  executeCommand = (command, booleanValue) => {
    console.log("command", command);
    this.api.executeCommand(command, booleanValue);
    if (command === "hangup") {
      return;
    }

    if (command === "toggleAudio") {
      this.setState(
        { isAudioMuted: this.state.isAudioMuted ? false : true },
        () => {
          setTimeout(() => {
            this.updateUserStatus();
          }, 10);
        }
      );
      return;
    }

    if (command === "toggleVideo") {
      this.setState(
        { isVideoMuted: this.state.isVideoMuted ? false : true },
        () => {
          this.updateUserStatus();
        }
      );
      return;
    }

    if (command === "toggleFilmStrip") {
      this.setState({ tglFilmStrip: this.state.tglFilmStrip ? false : true });
      return;
    }

    if (command === "setTileView") {
      this.setState({ tileViewMode: true });
      return;
    }

    if (command === "toggleTileView") {
      this.setState({ tileViewMode: this.state.tileViewMode ? false : true });
      return;
    }

    if (command === "toggleShareScreen") {
      this.setState({
        jitsiloaded: this.state.jitsiloaded ? false : true,
      });
      return;
    }
    if (command === "muteEveryone") {
      this.setState({ meone: this.state.meone ? false : true });
      return;
    }
  };

  getParticipantList() {
    this.setState({
      drawOpen:
        !this.state.drawOpen && !this.state.ptListOpen
          ? true
          : this.state.drawOpen && this.state.chatOpen
          ? true
          : this.state.drawOpen && this.state.obrlistEnabled
          ? true
          : this.state.drawOpen && this.state.liveDrawOpen
          ? true
          : false,
      ptListOpen: this.state.ptListOpen ? false : true,
      unread: this.state.unread,
      obrlistEnabled: false,
      chatOpen: false,
      liveDrawOpen: false,
    });
  }

  openChatBox() {
    this.setState({
      unread: false,
      drawOpen:
        !this.state.drawOpen && !this.state.chatOpen
          ? true
          : this.state.drawOpen && this.state.obrlistEnabled
          ? true
          : this.state.drawOpen && this.state.ptListOpen
          ? true
          : this.state.drawOpen && this.state.liveDrawOpen
          ? true
          : false,
      chatOpen: this.state.chatOpen ? false : true,
      obrlistEnabled: false,
      ptListOpen: false,
      liveDrawOpen: false,
    });
  }

  openOBRBox() {
    this.setState({
      drawOpen:
        !this.state.drawOpen && !this.state.obrlistEnabled
          ? true
          : this.state.drawOpen && this.state.chatOpen
          ? true
          : this.state.drawOpen && this.state.ptListOpen
          ? true
          : this.state.drawOpen && this.state.liveDrawOpen
          ? true
          : false,
      obrlistEnabled: this.state.obrlistEnabled ? false : true,
      unread: this.state.unread,
      chatOpen: false,
      ptListOpen: false,
      liveDrawOpen: false,
    });
  }

  openWebRTCPanel() {
    this.setState({
      drawOpen:
        !this.state.drawOpen && !this.state.liveDrawOpen
          ? true
          : this.state.drawOpen && this.state.chatOpen
          ? true
          : this.state.drawOpen && this.state.ptListOpen
          ? true
          : this.state.drawOpen && this.state.obrlistEnabled
          ? true
          : false,
      liveDrawOpen: this.state.liveDrawOpen ? false : true,
      obrlistEnabled: false,
      chatOpen: false,
      ptListOpen: false,
    });
  }

  handleKeyDown(e) {
    console.log("key", e);
    if (e.key === "Enter") {
      if (String(this.state.chatText).length > 0) {
        this.sendChatMessage(this.state.chatText);

        this.setState({ chatText: "" });
      }
    }
  }

  setPermission = (user, data, el, index) => {
    console.log("_. set permission", data);

    if (el === 0) {
      var muteStatus = user.mute ? false : true;
      console.log("_.mute status", muteStatus);
      this.muteSpecific(user, muteStatus);
    }
    if (el === 3) {
      var KRPanoSyncStatus = user.enableKRPanoSync ? false : true;
      if (KRPanoSyncStatus === true) {
        this.allowKRPanoSync(user, KRPanoSyncStatus);
      } else {
        this.revertKRPanoSync(user);
      }
    }

    if (el === 1 || el === 2 || el === 4) {
      var permissionStatus = data.status ? false : true;
      this.props.participants[index]["permission"][el]["status"] =
        permissionStatus;
      this.state.websocket.send(
        JSON.stringify({
          setPermission: {
            roomId: this.props.room,
            user_code: this.props.participants[index].userSocketId,
            permissions: this.props.participants[index]["permission"],
          },
        })
      );
    }
  };

  muteSpecific = (user, status) => {
    console.log("_. muteSpecific", status);
    this.state.websocket.send(
      JSON.stringify({
        muteSpecific: {
          roomId: this.props.room,
          user_code: user.userSocketId,
          muteSpecifiStatus: status,
        },
      })
    );
  };

  muteUnMuteAll = (muteStatus) => {
    console.log("_.muteUnMute", muteStatus);
    this.state.websocket.send(
      JSON.stringify({
        muteUnMuteAll: {
          roomId: this.props.room,
          user_code: this.props.user_code,
          MuteMicAll: this.state.isMicMuteAll ? null : muteStatus,
        },
      })
    );
  };

  sendOBRNotification = (values) => {
    this.state.websocket.send(
      JSON.stringify({
        obrNotification: {
          roomId: this.props.room,
          senderName: this.state.display_name,
          notification: values,
        },
      })
    );
  };

  getObrNotification = (data) => {
    this.setState({ obrNotification: true, notification: data });
  };

  askToUnmute = () => {
    this.state.websocket.send(
      JSON.stringify({
        askToUnmute: {
          roomId: this.props.room,
          user_code: this.props.user_code,
          displayName: this.state.display_name,
        },
      })
    );
  };

  asktoUnmuteMe() {
    this.setState({ asktoUnmuteMe: this.props.asktoUnmuteMe });
  }

  unMuteRequestUser = () => {
    this.setState({ asktoUnmuteMe: false });
    this.state.websocket.send(
      JSON.stringify({
        unMuteRequestUser: {
          roomId: this.props.room,
          user_code: this.props.unnmuteuser.userSocketId,
        },
      })
    );
  };

  updateUserStatus = () => {
    console.log("_. this.state.isAudioMuted", this.state.isAudioMuted);
    this.state.websocket.send(
      JSON.stringify({
        updateUserStatus: {
          roomId: this.props.room,
          user_code: this.props.user_code,
          mute: this.state.isAudioMuted,
          videoMute: this.state.isVideoMuted,
          MuteMicAll: null,
        },
      })
    );
  };

  sendChatMessage = (message) => {
    this.state.websocket.send(
      JSON.stringify({
        chatMessageToAll: {
          roomId: this.props.room,
          sender_code: this.props.user_code,
          sender_name: this.state.display_name,
          time: new Date(),
          message: message,
        },
      })
    );
  };

  getChatMessage = () => {
    this.setState(
      {
        chatMessages: this.state.chatMessages.concat([
          this.props.incomingChatMessage,
        ]),
        unread: true,
      },
      () => {
        this.scrollToBottom();
      }
    );
  };

  startSharing = (data) => {
    this.state.websocket.send(
      JSON.stringify({
        startSharing: {
          roomId: this.props.room,
          data: data,
          streamingStarted: true,
        },
      })
    );
  };

  stopSharing = (data) => {
    if (this.state.krpanoSyncing === "start") {
      this.krpanoSyncStartStop("stop");
      this.setState({ krpanoSyncing: "stop" });
    }
    if (this.state.flatPlayerSyncing === "start") {
      this.flatPlayerSyncStartStop("stop");
      this.setState({ flatPlayerSyncing: "stop" });
    }
    var msg = {
      elementToHide: "largeVideoContainer",
      status: "show",
    };
    const frame = document.getElementsByTagName("iframe")[0].contentWindow;
    frame.postMessage(msg, "https://jitsi.autolearn.in");
    this.state.websocket.send(
      JSON.stringify({
        stopSharing: {
          roomId: this.props.room,
          streamingStarted: false,
        },
      })
    );
  };

  KRPanoPlayPause = (status, currentTime) => {
    this.state.websocket.send(
      JSON.stringify({
        streamPlayPause: {
          roomId: this.props.room,
          playPauseStatus: status,
          currentTime: currentTime,
        },
      })
    );
  };

  krpanoSyncStartStop = (status) => {
    this.state.websocket.send(
      JSON.stringify({
        KRPanoSyncing: {
          roomId: this.props.room,
          KRPanoSyncingStatus: status,
        },
      })
    );
    if (status === "stop") {
      clearInterval(sndmsgInterval);
    } else if (status === "start") {
      this.startViewPointDataSyncing();
      if (
        this.state.videoPlaying &&
        this.state.krpanoSyncing === "start" &&
        this.state.KRPanoLoaded
      ) {
        var krpano = document.getElementById("krpanoSWFObject");
        var video = krpano.get("plugin[video]").videoDOM;
        var currentTime = video.currentTime;
        this.KRPanoPlayPause("play", currentTime);
      }
      if (
        this.state.videoPlaying === false &&
        this.state.krpanoSyncing === "start" &&
        this.state.KRPanoLoaded
      ) {
        var krpano = document.getElementById("krpanoSWFObject");
        var video = krpano.get("plugin[video]").videoDOM;
        var currentTime = video.currentTime;
        this.KRPanoPlayPause("paused", currentTime);
      }
    }
  };

  flatPlayerSyncStartStop = (status) => {
    this.state.websocket.send(
      JSON.stringify({
        flatPlayerSyncing: {
          roomId: this.props.room,
          flatPlayerSyncingStatus: status,
        },
      })
    );

    var status = this.state.fpVideoPlaying ? "play" : "paused";
    this.flatPlayerPlayPauseListener(status);
  };

  hideFlatPlayerControls = () => {
    if (this.state.flatPlayerLoaded) {
      var video = document.getElementById("flattypeVideo");
      video.controls = true;
      video.classList.add("hide-playbtn");
      video.classList.add("hide-progressbar");
      //$('.video:first-child').css("display","none")
    }
  };

  showFlatPlayerControls = () => {
    if (this.state.flatPlayerLoaded) {
      var video = document.getElementById("flattypeVideo");
      video.controls = true;
      video.classList.remove("hide-playbtn");
      video.classList.remove("hide-progressbar");
      video.pause();
    }
  };

  flatPlayerPlayPauseListener = (status) => {
    console.log("_.fp status", status, "--", this.state.flatPlayerSyncing);
    var video = document.getElementById("flattypeVideo");
    var videoCurrentTime = video.currentTime;
    if (
      this.state.flatPlayerSyncing === "start" &&
      this.props.socketUser.enableKRPanoSync
    ) {
      this.state.websocket.send(
        JSON.stringify({
          flatPlayerPlayOnSync: {
            roomId: this.props.room,
            fpPlayPauseStatus: status,
            currentTime: videoCurrentTime,
          },
        })
      );
    }
  };

  syncFPVolume = (volumeLevel, muteStatus) => {
    if (
      this.state.flatPlayerSyncing === "start" &&
      this.props.socketUser.enableKRPanoSync
    ) {
      this.state.websocket.send(
        JSON.stringify({
          syncFPVolume: {
            roomId: this.props.room,
            muteStatus: muteStatus,
            volumeLevel: volumeLevel,
          },
        })
      );
    }
  }

  allowKRPanoSync = (user, status) => {
    this.state.websocket.send(
      JSON.stringify({
        allowKRPanoSync: {
          roomId: this.props.room,
          user_code: user.userSocketId,
          enableKRPanoSync: status,
        },
      })
    );
  };

  revertKRPanoSync = (user) => {
    this.state.websocket.send(
      JSON.stringify({
        revertKRPanoSync: {
          roomId: this.props.room,
        },
      })
    );
  };

  enableKRPanoSyncButton = () => {
    if (this.props.socketUser.enableKRPanoSync) {
      this.setState({
        enableKRPanoSync: this.props.socketUser.enableKRPanoSync,
      });
    }
  };

  stopKRPanoMouseAction = () => {
    if (this.state.KRPanoLoaded) {
      var krpano = document.getElementById("krpanoSWFObject");
      krpano.call("set(control.usercontrol,off)");
    }
  };

  startKRPanoMouseAction = () => {
    if (this.state.KRPanoLoaded) {
      clearInterval(sndmsgInterval);

      if (this.state.KRPanoLoaded) {
        this.setState({ krpanoSyncing: "stop" });
        var krpano = document.getElementById("krpanoSWFObject");
        krpano.call("set(control.usercontrol,all)");
      }
    }
  };

  startViewPointDataSyncing = () => {
    var krpano = document.getElementById("krpanoSWFObject");
    sndmsgInterval = setInterval(() => {
      //console.log("_. interval started")

      if (krpano && krpano.get) {
        var hlookat = krpano.get("view.hlookat");
        var vlookat = krpano.get("view.vlookat");
        var fov = krpano.get("view.fov");
        var KRViewPoints = {
          hlookat: hlookat,
          vlookat: vlookat,
          fov: fov,
        };
        this.state.websocket.send(
          JSON.stringify({
            KRPanoViewPoints: {
              roomId: this.props.room,
              viewPoints: KRViewPoints,
            },
          })
        );
      }
    }, 50);
  };

  dataSyncingToKRPano = (viewPoints) => {
    // console.log("_. viewPoints", viewPoints.hlookat)
    if (this.state.KRPanoLoaded && this.props.KRPanoSyncingStarted) {
      var krpano = document.getElementById("krpanoSWFObject");
      if (krpano && krpano.get) {
        krpano.set("view.hlookat", viewPoints.hlookat);
        krpano.set("view.vlookat", viewPoints.vlookat);
        krpano.set("view.fov", viewPoints.fov);
        krpano.set("view.continuousupdates", "true");
        krpano.set("view.devices", "html5");
      }
    }
  };

  checkStreaming = () => {
    if (this.props.streamingData !== undefined) {
      if (this.props.streamingData.streamingStarted) {
        this.getSharingData("from jitsi loaded");
      }
    }
  };

  checkVideoPlaying = () => {
    if (this.props.streamingData !== undefined) {
      if (this.props.streamingData.streamingStarted) {
        this.getSharingData("from jitsi loaded");
      }
    }
  };

  getSharingData = (loading) => {
    console.log("_. get sharing data");
    if (this.state.jitsiloaded && this.props.streamingData.streamingStarted) {
      console.log("_. this.props.streamingData", this.props.streamingData);
      var streamingData = this.props.streamingData;
      this.executeCommand("setTileView", streamingData.jitsiTileViewMode);
      this.setState(
        {
          streamingStarted: streamingData.streamingStarted,
          sharedData: streamingData.sharedData,
        },
        () => {
          var msg = {
            elementToHide: "largeVideoContainer",
            status: "hide",
          };
          const frame =
            document.getElementsByTagName("iframe")[0].contentWindow;
          frame.postMessage(msg, "https://jitsi.autolearn.in");

          setTimeout(() => {
            this.loadContent(streamingData.sharedData);
          }, 50);
        }
      );
    }
  };

  streamingStopped = () => {
    var streamingData = this.props.streamingData;
    this.executeCommand("setTileView", streamingData.jitsiTileViewMode);
    this.setState({
      streamingStarted: streamingData.streamingStarted,
      videoPlaying: false,
      audioMute: false,
    });
    var msg = {
      elementToHide: "largeVideoContainer",
      status: "show",
    };
    const frame = document.getElementsByTagName("iframe")[0].contentWindow;
    frame.postMessage(msg, "https://jitsi.autolearn.in");
    this.setState({ krpanoSyncing: "stop" });
    if (mySKTConnection !== "") mySKTConnection.close();
  };

  loadContent = (data) => {
    if (data.videotype === "360" && data.streamtype === "url") {
      console.log("_. am in load content ----");
      var setKRpanoloaded = setInterval(() => {
        var krpano = document.getElementById("krpanoSWFObject");

        if (krpano && krpano.get) {
          console.log("_. am in 1 ----");
          if (krpano.get("plugin[video]") !== null) {
            console.log("_. am in 2 ----");
            var krpanoLoaded = krpano.get("plugin[video]").loaded;
            var krpanoVDOMLoaded = krpano.get("plugin[video]").videoDOM;
            if (krpanoLoaded && krpanoVDOMLoaded) {
              clearInterval(setKRpanoloaded);
              if (hls) hls.destroy();
              console.log("_. am in 3 ----");
              if (Hls.isSupported()) {
                hls = new Hls({
                  debug: false,
                  enableWorker: false,
                  lowLatencyMode: true,
                  backBufferLength: 90,
                  capLevelToPlayerSize: false,
                  startLevel: -1,
                });
                hls.attachMedia(krpanoVDOMLoaded);
                hls.on(Hls.Events.MEDIA_ATTACHING, function () {
                  console.log("_. media attaching");
                  //hls.loadSource(data.url);
                });
                hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                  console.log("_. data url", data.url);
                  hls.loadSource(data.url);
                });
              }
              krpanoVDOMLoaded.volume = 0;
              this.setState(
                { KRPanoLoaded: true, flatPlayerLoaded: false },
                () => {
                  // console.log("_.KRPanoSyncingStarted", this.props.KRPanoSyncingStarted)
                  if (this.props.KRPanoSyncingStarted) {
                    this.stopKRPanoMouseAction();
                  }
                  console.log("_.this.props.roomInfo", this.props.roomInfo);
                  if (this.props.roomInfo.streamPlaying === "play") {
                    this.playPauseSyncVideo(
                      this.props.roomInfo.streamPlaying,
                      this.props.roomInfo.videoCurrentTime
                    );
                  }
                }
              );
            }
          }
        }
      }, 50);
    } else if (data.videotype === "flat" && data.streamtype === "url") {
      var video = document.getElementById("flattypeVideo");
      video.controls = true;
      video.classList.add("hideOtherControls");
      hls.destroy();
      if (Hls.isSupported()) {
        hls = new Hls({
          debug: false,
          enableWorker: false,
          lowLatencyMode: true,
          backBufferLength: 90,
          capLevelToPlayerSize: false,
          startLevel: -1,
        });
        hls.attachMedia(video);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          hls.loadSource(data.url);
        });
      }

      this.setState({ KRPanoLoaded: false, flatPlayerLoaded: true }, () => {
        if (this.props.flatPlayerSyncingStarted) {
          this.hideFlatPlayerControls();
        } else {
          this.showFlatPlayerControls();
        }
      });
    } else if (data.videotype === "360" && data.streamtype === "webrtc") {
      if (mySKTConnection !== "") mySKTConnection.close();

      var setKRpanoloaded = setInterval(() => {
        var krpano = document.getElementById("krpanoSWFObject");
        if (krpano.get("plugin[video]") !== null) {
          var krpanoLoaded = krpano.get("plugin[video]").loaded;
          var video = krpano.get("plugin[video]").videoDOM;
          if (krpanoLoaded && video) {
            clearInterval(setKRpanoloaded);
            const socketConnection = socketIOClient.connect(
              "https://360api.autolearn.in/"
            );
            if (socketConnection)
              socketConnection.emit("join", this.state.meetingInfo.code);
            this.setState({ SKTConnectivity: true });
            mySKTConnection = socketConnection;
            socketConnection.on("offer", (id, description) => {
              console.log("peer 2", description);
              peerConnection = new RTCPeerConnection(config);
              peerConnection
                .setRemoteDescription(description)
                .then(() => peerConnection.createAnswer())
                .then((sdp) => peerConnection.setLocalDescription(sdp))
                .then(() => {
                  socketConnection.emit(
                    "answer",
                    id,
                    peerConnection.localDescription
                  );
                });
              peerConnection.ontrack = (event) => {
                console.log("event.streams", event.streams);
                video.srcObject = event.streams[0];
                video.onloadedmetadata = () => {
                  video.play();
                  //this.togglePlay()
                };
              };
              peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                  socketConnection.emit("candidate", id, event.candidate);
                }
              };
            });

            socketConnection.on("candidate", (id, candidate) => {
              peerConnection
                .addIceCandidate(new RTCIceCandidate(candidate))
                .catch((e) => console.error(e));
            });

            socketConnection.on("connect", () => {
              socketConnection.emit("watcher");
            });

            socketConnection.on("broadcaster", () => {
              socketConnection.emit("watcher");
            });

            window.onunload = window.onbeforeunload = () => {
              socketConnection.close();
              peerConnection.close();
            };
            video.volume = 0;
            this.setState(
              { KRPanoLoaded: true, flatPlayerLoaded: false },
              () => {
                if (this.props.KRPanoSyncingStarted) {
                  this.stopKRPanoMouseAction();
                }
                if (this.props.roomInfo.streamPlaying === "play") {
                  this.playPauseSyncVideo(
                    this.props.roomInfo.streamPlaying,
                    this.props.roomInfo.videoCurrentTime
                  );
                }
              }
            );
          }
        }
      }, 50);
    } else if (data.videotype === "flat" && data.streamtype === "webrtc") {
      if (mySKTConnection !== "") mySKTConnection.close();
      var video = document.getElementById("flattypeVideo");
      video.controls = true;
      video.classList.add("hideOtherControls");
      const socketConnection = socketIOClient.connect(
        "https://360api.autolearn.in/"
      );
      if (socketConnection)
        socketConnection.emit("join", this.state.meetingInfo.code);
      this.setState({ SKTConnectivity: true });
      mySKTConnection = socketConnection;
      socketConnection.on("offer", (id, description) => {
        console.log("peer 2", description);
        peerConnection = new RTCPeerConnection(config);
        peerConnection
          .setRemoteDescription(description)
          .then(() => peerConnection.createAnswer())
          .then((sdp) => peerConnection.setLocalDescription(sdp))
          .then(() => {
            socketConnection.emit(
              "answer",
              id,
              peerConnection.localDescription
            );
          });
        peerConnection.ontrack = (event) => {
          console.log("event.streams", event.streams);
          video.srcObject = event.streams[0];
          video.onloadedmetadata = () => {
            video.play();
            //this.togglePlay()
          };
        };
        peerConnection.onicecandidate = (event) => {
          if (event.candidate) {
            socketConnection.emit("candidate", id, event.candidate);
          }
        };
      });

      socketConnection.on("candidate", (id, candidate) => {
        peerConnection
          .addIceCandidate(new RTCIceCandidate(candidate))
          .catch((e) => console.error(e));
      });

      socketConnection.on("connect", () => {
        socketConnection.emit("watcher");
      });

      socketConnection.on("broadcaster", () => {
        socketConnection.emit("watcher");
      });

      window.onunload = window.onbeforeunload = () => {
        socketConnection.close();
        peerConnection.close();
      };
      this.setState({ KRPanoLoaded: false, flatPlayerLoaded: true }, () => {
        if (this.props.flatPlayerSyncingStarted) {
          this.hideFlatPlayerControls();
        } else {
          this.showFlatPlayerControls();
        }
      });
    }
  };

  playPauseSyncVideo = (videoStatus, currentTime) => {
    console.log("_.currentTime", currentTime);
    var krpano = document.getElementById("krpanoSWFObject");
    if (krpano && krpano.get) {
      var video = krpano.get("plugin[video]").videoDOM;
      console.log("_.video status", videoStatus);
      if (videoStatus === "paused") {
        this.setState({ videoPlaying: false });
        video.currentTime = currentTime;
        video.pause();
      } else {
        this.setState({ videoPlaying: true });
        video.currentTime = currentTime;
        video.play();
      }

      krpano.set("view.continuousupdates", "true");
    }
  };

  playPauseFlatPlayer = (videoStatus, currentTime) => {
    console.log("_.currentTime", currentTime);
    var video = document.getElementById("flattypeVideo");
    if (this.state.flatPlayerLoaded) {
      if (videoStatus === "paused") {
        this.setState({ fpvideoPlaying: false });
        video.currentTime = currentTime;
        video.pause();
      }
      if (videoStatus === "seeking") {
        video.currentTime = currentTime;
        if (this.state.fpvideoPlaying) {
          video.play();
        } else {
          video.pause();
        }
      }
      if (videoStatus === "play") {
        this.setState({ fpvideoPlaying: true });
        video.currentTime = currentTime;
        video.play();
      }
    }
  };

  muteUnmuteFlatPlayer = (muteStatus, volumeLevel) => {
    var video = document.getElementById("flattypeVideo");
    video.muted = muteStatus
   //video.volume = volumeLevel
  }



  togglePlay() {
    var krpano = document.getElementById("krpanoSWFObject");
    var video = krpano.get("plugin[video]").videoDOM;
    if (video.paused === false) {
      this.setState({ videoPlaying: false });
      video.pause();
      var currentTime = video.currentTime;
      console.log(
        "_.this.props.KRPanoSyncingStarted",
        this.props.KRPanoSyncingStarted
      );
      if (this.state.krpanoSyncing === "start") {
        this.KRPanoPlayPause("paused", currentTime);
      }
    } else {
      this.setState({ videoPlaying: true });
      video.play();
      var currentTime = video.currentTime;
      console.log("_.currentTime --", currentTime);
      console.log(
        "_.this.props.KRPanoSyncingStarted",
        this.props.KRPanoSyncingStarted
      );
      if (this.state.krpanoSyncing === "start") {
        this.KRPanoPlayPause("play", currentTime);
      }
    }
    krpano.set("view.continuousupdates", "true");
  }

  toggleMute() {
    var krpano = document.getElementById("krpanoSWFObject");
    var video = krpano.get("plugin[video]").videoDOM;
    console.log("_. volume", video.volume);
    if (video.volume == 1) {
      this.setState({ audioMute: false });
      video.volume = 0;
    } else {
      this.setState({ audioMute: true });
      video.volume = 1;
    }

    krpano.set("view.continuousupdates", "true");
  }

  vrmode() {
    var krpano = document.getElementById("krpanoSWFObject");
    if (krpano && krpano.get) {
      krpano.call("webvr.enterVR();");
    }
  }

  takeFPScreenShot = () => {
    console.log("test");
    var video = document.getElementById("flattypeVideo");
    var scaleFactor = 1;
    if (scaleFactor == null) {
      scaleFactor = 1;
    }
    var w = video.videoWidth * scaleFactor;
    var h = video.videoHeight * scaleFactor;
    var canvas = document.createElement("canvas");
    canvas.width = w;
    canvas.height = h;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, w, h);
    this.setState({ snshot: canvas.toDataURL("image/jpg") });
    this.setState({ obContentLoaded: true });
    this.tog_center();
  };

  takeScreenShot = () => {
    var krpano = document.getElementById("krpanoSWFObject");
    krpano.call("makescreenshot()");
    var img_src = krpano.get("layer[ssimageURL]");
    this.setState({ snshot: img_src.url });
    this.setState({ obContentLoaded: true });
    this.tog_center();
    krpano.call("removelayer(ssimageURL,true)");
  };

  handleValidSubmit(event, values) {
    var image_info = Imageproessing.convertBaseToURL(this.state.snshot);

    const img = new FormData();
    let u_id = uuid();

    img.append("image", image_info, u_id + "_obr.png");
    console.log(u_id + "_obr.png");

    urlSocket.post("storeImage/awswebupload", img).then((res) => {
      if (res.status == 200) {
        console.log(res);
        let splitString = res.data.data[0].key.split("/");
        let getFileName = splitString[splitString.length - 1];
        console.log("getFileName", getFileName);
        //this.setState({ up_sign_url: String(u_id) + 'sign.png' })
        // console.log("uploaded _sign", res)
        this.createObservation(u_id, getFileName, values);
      }
    });

    this.setState({ imageLoading: true });
  }

  createObservation(u_id, getFileName, values) {
    this.setState({ obrTitle: values.title });
    try {
      urlSocket
        .post("mngobvrs/createobservations", {
          meetingInfo: this.state.meetingInfo,
          observationInfo: values,
          userInfo: this.props.loggedUserInfo,
          imageInfo: {
            u_id: u_id,
            filename: getFileName,
          },
        })
        .then((response) => {
          console.log("_.response", response);
          if (response.data.response_code === 500) {
            console.log("_.error", response.data.data);
            this.setState({
              obContentLoaded: false,
              imageLoading: false,
              mandc: null,
              obInfo: response.data.data,
            });
            this.tog_center();
            this.tog_fullscreen();
            this.sendOBRNotification(response.data.data);
          }
        })
        .catch((error) => {
          console.log("_.catch error", error);
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  loadImageEditor(obInfo, mandc) {
    console.log("_.image editor");
    this.setState({
      obInfo: obInfo,
      mandc,
      commentText: mandc === undefined ? "" : mandc.muser_comment,
      //drawOpen: false,
      obrTitle: obInfo.title,
    });
    this.tog_fullscreen();
  }

  convertAsImage() {
    this.setState({ savingImage: true });
    var base64Data = this.obreditor.current.getImageBase64();
    var image_info = Imageproessing.convertBaseToURL(base64Data);
    const img = new FormData();
    let u_id;

    if (this.state.mandc === null || this.state.mandc === undefined) {
      u_id = uuid();
    } else {
      u_id = uuid();
      //u_id = this.state.mandc.u_id;
    }

    img.append("image", image_info, u_id + "_obr.png");
    console.log(u_id + "_.obr.png");

    urlSocket.post("storeImage/awswebupload", img).then((res) => {
      if (res.status == 200) {
        console.log("_.", res);
        let splitString = res.data.data[0].key.split("/");
        let getFileName = splitString[splitString.length - 1];
        console.log("getFileName", getFileName);
        this.saveOBRComments(u_id, getFileName);
      }
    });
  }

  saveOBRComments(u_id, getFileName) {
    var getAllShapes = this.obreditor.current.onSaveObservation();
    console.log("_.saving", getAllShapes);
    if (this.state.mandc !== null && this.state.mandc !== undefined) {
      this.state.mandc["objects"] = getAllShapes;
      this.state.mandc["u_id"] = u_id;
      this.state.mandc["file_name"] = getFileName;
      this.state.mandc["muser_comment"] = this.state.commentText;
    }
    try {
      //this.state.userInfo["display_name"] = this.state.display_name;
      urlSocket
        .post("mngobvrs/createobrmarkscmnts", {
          meetingInfo: this.state.meetingInfo,
          observationInfo: this.state.obInfo,
          userInfo: this.props.loggedUserInfo,
          userRole: this.props.loggedUserInfo.role,
          marksAndComments:
            this.state.mandc === null || this.state.mandc === undefined
              ? {
                  muser_comment: this.state.commentText,
                  objects: getAllShapes,
                  u_id: u_id,
                  file_name: getFileName,
                }
              : this.state.mandc,
        })
        .then((response) => {
          console.log("_. obr response", response.data);
          if (response.data.response_code === 500) {
            this.setState({ savingImage: false });
            //this.tog_fullscreen();
          }
        })
        .catch((error) => {
          console.log("_. saveobr", error);
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  krpanoControls(control) {
    var krpano = document.getElementById("krpanoSWFObject");
    if (krpano && krpano.get) {
      //zoom in zoom out
      if (control === "zoomInDown") {
        krpano.set("fov_moveforce", -1);
      }
      if (control === "zoomOutDown") {
        krpano.set("fov_moveforce", +1);
      }
      if (control === "zoomInOutUp") {
        krpano.set("fov_moveforce", 0);
      }

      //zoom in left right pan
      if (control === "leftkeyDown") {
        krpano.set("hlookat_moveforce", -1);
      }
      if (control === "rightkeyDown") {
        krpano.set("hlookat_moveforce", +1);
      }
      if (control === "leftRightkeyUp") {
        krpano.set("hlookat_moveforce", 0);
      }

      //zoom in top bottom pan
      if (control === "topkeyDown") {
        krpano.set("vlookat_moveforce", -1);
      }
      if (control === "bottomkeyDown") {
        krpano.set("vlookat_moveforce", +1);
      }
      if (control === "topBottomkeyUp") {
        krpano.set("vlookat_moveforce", 0);
      }

      if (control === "normalview") {
        krpano.call("skin_view_normal()");
      }
      if (control === "flatview") {
        krpano.call("skin_view_flat()");
      }
      if (control === "fisheyeview") {
        krpano.call("skin_view_fisheye()");
      }
      if (control === "stereoview") {
        krpano.call("skin_view_stereographic()");
      }
      if (control === "archview") {
        krpano.call("skin_view_architectural()");
      }
      if (control === "panniniview") {
        krpano.call("skin_view_pannini()");
      }
      if (control === "ltplanetview") {
        krpano.call("skin_view_littleplanet()");
      }
    }
  }

  invitePeople = () => {
    var url = window.location.protocol + "//" + window.location.hostname;
    //var url =  window.location.protocol+'//'+window.location.hostname+":"+window.location.port

    var isemailEmpty = this.state.emails.length > 0 ? false : true;
    var meetingParticipants = _.map(
      this.state.meetingInfo.participants,
      "email_id"
    );
    var filteredMails = this.state.emails.filter(
      (val) => !meetingParticipants.includes(val)
    );

    console.log("meetingParticipants", this.state.userInfo, filteredMails);
    if (!isemailEmpty) {
      if (filteredMails.length > 0) {
        this.setState({ inviteOver: false });

        try {
          urlSocket
            .post("mngmtg/addpeople", {
              meetingInfo: this.state.meetingInfo,
              emails: filteredMails,
              userInfo: this.props.loggedUserInfo,
              config_data: this.state.configData,
              meetingURL: url + "/meeting",
            })
            .then((response) => {
              console.log("responde", response.data.data);
              sessionStorage.removeItem("usermeetingInfo");
              sessionStorage.setItem(
                "usermeetingInfo",
                JSON.stringify(response.data.data)
              );
              var meetingInfo = response.data.data;
              this.setState({
                meetingInfo,
                meeting_id: meetingInfo._id,
                emails: [],
                inviteOver: true,
              });
              this.tog_addprtPanel();
            });
        } catch (error) {
          console.log("catch error", error);
        }
      } else {
        this.setState({
          isemailError: true,
          inviteError: "Mail already sent for those people",
        });
      }
    } else {
      this.setState({
        isemailEmpty,
        isemailError: true,
        inviteError: "Minimum one participant should be added",
      });
    }
  };

  leaveRoom = () => {
    this.state.websocket.close();
  };

  endThisCallForEveryOne = () => {
    this.state.websocket.send(
      JSON.stringify({
        endThisCallForEveryOne: {
          roomId: this.props.room,
        },
      })
    );
  };

  endMeeting = (status) => {
    console.log("_.endmeeting status");
    if (status === "2")
      try {
        urlSocket
          .post("mngmtg/meetingend", {
            roomName: this.props.meetingInfo.code,
            participantCode: this.props.user_code,
            //status: status,
            status: "1",
            meetingStatus: "1",
          })
          .then((response) => {
            if (response.data.response_code === 500) {
              console.log("_.response", response.data);
              this.endThisCallForEveryOne();
              //this.state.websocket.close();
              this.executeCommand("hangup");
            }
          });
      } catch (error) {
        console.log("_.catch error", error);
      }
  };

  render() {
    if (this.state.dataloaded) {
      const buttonStyle = {
        width: 50,
        height: 50,
        borderRadius: 25,
        padding: 5,
        display: "flex",
        justifyContent: "center",
        marginBottom: 10,
      };
      const { emails } = this.state;
      return (
        <Row>
          <div
            className="page-content "
            style={{
              overflow: "hidden !important",
              backgroundColor: "#1a1a1a",
              padding: 0,
              height: this.state.height - this.state.footerHeight,
              position: "relative",
            }}
          >
            {/* -------- Top ------------- */}

            {/* ---------- Left Container --------------- */}
            <Col
              style={{
                width: this.state.drawOpen ? "calc(100% - 380px)" : "100%",
                transition: "width .35s ease-in-out",
                position: "relative",
                float: "left",
              }}
            >
              {this.state.streamingStarted &&
                this.state.sharedData.videotype === "flat" && (
                  <div
                    style={{
                      width: 60,
                      //backgroundColor: "white",
                      height: this.state.height - this.state.footerHeight,
                      float: "left",
                      position: "absolute",
                    }}
                  >
                    <LeftPanelControls
                      panelHeight={this.state.height - this.state.footerHeight}
                      flatPlayerSyncStartStop={() => {
                        this.setState(
                          {
                            flatPlayerSyncing:
                              this.state.flatPlayerSyncing === "start"
                                ? "stop"
                                : "start",
                          },
                          () => {
                            this.flatPlayerSyncStartStop(
                              this.state.flatPlayerSyncing
                            );
                          }
                        );
                      }}
                      flatPlayerSyncing={this.state.flatPlayerSyncing}
                      syncEnabled={this.props.socketUser.enableKRPanoSync}
                      screenShotEnabled={this.props.socketUser.enableScreenshot}
                      takeFPScreenShot={() => this.takeFPScreenShot()}
                    />
                  </div>
                )}

              {/* --------- Jitsi Frame -------------- */}

              <div
                style={{
                  width:
                    this.state.streamingStarted &&
                    this.state.sharedData.videotype === "flat"
                      ? "95%"
                      : "100%",
                  left:
                    this.state.streamingStarted &&
                    this.state.sharedData.videotype === "flat"
                      ? 50
                      : 0,
                  overflow: "none",
                  position: "absolute",
                  zIndex: 1000,
                  pointerEvents: this.state.jitsipointer,
                }}
              >
                <div
                  id="jitsi-iframe"
                  ref={"jit"}
                  style={{
                    height: this.state.height - this.state.footerHeight,
                    width: "100%",
                    position: "relative",
                    padding: "0 20px 0 20px",
                  }}
                ></div>
              </div>

              {/* ============ Jitsi Frame End ========================= */}
              {
                //console.log("_.test", this.state.streamingStarted , this.state.jitsiloaded, this.state.sharedData)
              }

              {this.state.streamingStarted && this.state.jitsiloaded && (
                <div
                  style={{
                    width:
                      this.state.streamingStarted &&
                      this.state.sharedData.videotype === "flat"
                        ? "95%"
                        : "100%",
                    left:
                      this.state.streamingStarted &&
                      this.state.sharedData.videotype === "flat"
                        ? 50
                        : 0,
                    overflow: "none",
                    position: "absolute",
                    zIndex: 999,
                    padding: "0 20px 0 20px",
                    pointerEvents: "auto",
                  }}
                >
                  {this.state.sharedData.videotype === "360" ? (
                    <>
                      {/* --------- KRPano -------------- */}
                      <div
                        style={{
                          position: "absolute",
                          zIndex: 1030,
                          bottom: 20,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <KRPanoControls
                          togglePlay={() => this.togglePlay()}
                          toggleMute={() => this.toggleMute()}
                          krpanoControls={(data) => this.krpanoControls(data)}
                          krpanoSyncStartStop={() => {
                            this.setState(
                              {
                                krpanoSyncing:
                                  this.state.krpanoSyncing === "start"
                                    ? "stop"
                                    : "start",
                              },
                              () => {
                                this.krpanoSyncStartStop(
                                  this.state.krpanoSyncing
                                );
                              }
                            );
                          }}
                          vrmode={() => this.vrmode()}
                          takeScreenShot={() => this.takeScreenShot()}
                          videoPlaying={this.state.videoPlaying}
                          audioMute={this.state.audioMute}
                          krpanoSyncing={this.state.krpanoSyncing}
                          screenShotEnabled={
                            this.props.socketUser.enableScreenshot
                          }
                          syncEnabled={this.props.socketUser.enableKRPanoSync}
                          participantSync={this.props.KRPanoSyncingStarted}
                        />
                      </div>
                      <Krpano360 />
                      {/* ===========KRPano End================================== */}
                    </>
                  ) : (
                    <>
                      {/* --------- Flat Video -------------- */}
                      <video
                        playsInline
                        //autoPlay
                        muted
                        controls
                        controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
                        id="flattypeVideo"
                        onPlay={(event) => {
                          console.log("_.play");
                          this.setState({ fpVideoPlaying: true });
                          this.flatPlayerPlayPauseListener("play");
                        }}
                        onPause={(event) => {
                          console.log("_.pause");
                          this.setState({ fpVideoPlaying: false });
                          this.flatPlayerPlayPauseListener("paused");
                        }}
                        onSeeked={() => {
                          console.log("_. onseeking");
                          this.flatPlayerPlayPauseListener("seeking");
                        }}
                        onVolumeChange={(event)=>{
                          console.log("_. volume", event.target.muted);
                          this.syncFPVolume(event.target.voume, event.target.muted)
                        }}
                        style={{
                          overflow: "hidden",
                          textAlign: "center",
                          // objectFit: "cover",
                          position: "relative",
                          left: 0,
                          top: 0,
                          zIndex: 998,
                          width: "100%",
                          height: this.state.height - this.state.footerHeight,
                        }}
                      ></video>
                      {/* ===========Flat Video End================================== */}
                    </>
                  )}
                </div>
              )}

              {this.state.asktoUnmuteMe && (
                <div
                  style={{
                    width: 320,
                    height: 150,
                    borderRadius: 10,
                    backgroundColor: "#fff",
                    overflow: "none",
                    position: "fixed",
                    zIndex: 1027,
                    bottom: 150,
                    left: "30px",
                    transition: "left .3s ease-in-out, right .3s ease-in-out",
                  }}
                >
                  <UnMuteMessage
                    unnmuteuser={this.props.unnmuteuser}
                    closeMe={() => {
                      this.setState({ asktoUnmuteMe: false });
                    }}
                    unMuteRequestUser={() => {
                      this.unMuteRequestUser();
                    }}
                  />
                </div>
              )}
              {this.state.obrNotification && (
                <div
                  style={{
                    width: 320,
                    borderRadius: 10,
                    backgroundColor: "#fff",
                    overflow: "none",
                    position: "fixed",
                    zIndex: 1027,
                    bottom: 150,
                    left: "30px",
                    transition: "left .3s ease-in-out, right .3s ease-in-out",
                  }}
                >
                  <OBRNotification
                    notification={this.state.notification}
                    userInfo={this.props.loggedUserInfo}
                    closeMe={() => {
                      this.setState({ obrNotification: false });
                    }}
                    loadImageEditor={(data, mc) => {
                      this.loadImageEditor(data, mc);
                    }}
                    // unMuteRequestUser={() => {
                    //   this.unMuteRequestUser();
                    // }}
                  />
                </div>
              )}
            </Col>
            {/* --------- Right Containr ----------- */}
            {/* ----------- Right tab --------------- */}
            <Col
              style={{
                width: 360,
                right: this.state.drawOpen ? 25 : -360,
                position: "absolute",
                borderLeft: "1px #eff2f7 solid",
                backgroundColor: "#fff",
                padding: "0 10px",
                top: "0px !important",
                height: this.state.height - this.state.footerHeight,
                transition: "right .35s ease-in-out",
                borderRadius: 10,
              }}
            >
              {this.state.chatOpen ? (
                <div style={{ padding: "20px 0 10px 0" }}>
                  <div
                    style={{
                      // borderBottom: "1px solid #dadada",
                      padding: "0 10px 10px 10px",
                      marginBottom: 10,
                    }}
                  >
                    <span className="card-title mt-0 font-size-15 fw-light">
                      Chat
                    </span>
                    <button
                      onClick={() =>
                        this.setState({
                          drawOpen: false,
                          chatOpen: false,
                          ptListOpen: false,
                          obrlistEnabled: false,
                          liveDrawOpen: false,
                          unread: false,
                        })
                      }
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{
                        fontSize: 15,
                        top: 19,
                        padding: "0.78rem 1.29rem",
                        right: 10,
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div>
                    <div className="chat-conversation p-3 mb-0">
                      <ul className="list-unstyled mb-0">
                        <PerfectScrollbar
                          id="chatMsgContainer"
                          containerRef={(el) => (this.scrollbox.current = el)}
                          style={{
                            height: this.state.height - 300,
                            minHeight: this.state.height - 300,
                          }}
                        >
                          {this.state.chatMessages.map((item) => {
                            return (
                              <li
                                className={
                                  item.user_code === this.props.user_code
                                    ? " "
                                    : "right"
                                }
                              >
                                <div className="conversation-list mb-1">
                                  <div
                                    className="ctext-wrap mb-0"
                                    style={{
                                      padding: "8px 12px",
                                    }}
                                  >
                                    <div className="conversation-name text-dark font-size-12 mb-0">
                                      {item.name}{" "}
                                      {moment(item.time).format("hh:mm")}
                                    </div>
                                    <span className="mb-1 font-size-13">
                                      {item.message}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </PerfectScrollbar>
                      </ul>
                    </div>
                    <div className="py-3 px-1 chat-input-section">
                      <Row>
                        <Col>
                          <div className="position-relative">
                            <input
                              id="chatMsgInput"
                              type="text"
                              className="form-control chat-input"
                              placeholder="Enter Message..."
                              onKeyDown={this.handleKeyDown}
                              onChange={(event) => {
                                this.setState({
                                  chatText: event.target.value,
                                });
                              }}
                              value={this.state.chatText}
                              style={{
                                paddingRight: 70,
                              }}
                            />
                            <div
                              className="chat-input-links"
                              style={{ right: 3 }}
                            >
                              <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn-sm btn-rounded chat-send "
                                    onClick={() => {
                                      if (
                                        String(this.state.chatText).length > 0
                                      ) {
                                        this.sendChatMessage(
                                          this.state.chatText
                                        );
                                        this.setState({ chatText: "" });
                                      }
                                    }}
                                  >
                                    <i className="mdi mdi-send"></i>
                                  </Button>
                                </li>{" "}
                              </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              ) : this.state.obrlistEnabled ? (
                <div style={{ padding: "20px 0 10px 0" }}>
                  <div
                    style={{
                      // borderBottom: "1px solid #dadada",
                      padding: "0 10px 10px 10px",
                      marginBottom: 10,
                    }}
                  >
                    <span className="card-title mt-0 font-size-15 fw-light">
                      Observations
                    </span>
                    <button
                      onClick={() =>
                        this.setState({
                          drawOpen: false,
                          chatOpen: false,
                          ptListOpen: false,
                          obrlistEnabled: false,
                          liveDrawOpen: false,
                        })
                      }
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{
                        fontSize: 15,
                        top: 19,
                        padding: "0.78rem 1.29rem",
                        right: 10,
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="w-100 user-chat">
                    {this.state.obrlistEnabled ? (
                      <OBRList
                        meetingInfo={this.state.meetingInfo}
                        userInfo={this.props.loggedUserInfo}
                        loadImageEditor={(data, mc) => {
                          this.loadImageEditor(data, mc);
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              ) : this.state.ptListOpen ? (
                <div style={{ padding: "20px 0 10px 0" }}>
                  <div
                    style={{
                      // borderBottom: "1px solid #dadada",
                      padding: "0 10px 10px 10px",
                      marginBottom: 10,
                    }}
                  >
                    <span className="card-title mt-0 font-size-15 fw-light">
                      People Joined
                    </span>
                    <button
                      onClick={() =>
                        this.setState({
                          drawOpen: false,
                          chatOpen: false,
                          ptListOpen: false,
                          obrlistEnabled: false,
                          liveDrawOpen: false,
                        })
                      }
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{
                        fontSize: 15,
                        top: 19,
                        padding: "0.78rem 1.29rem",
                        right: 10,
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="w-100 user-chat">
                    <div
                      className="d-flex flex-column pb-2"
                      style={{ borderBottom: "1px solid #e6e9ea" }}
                    >
                      {this.props.socketUser.role === "Organizer" && (
                        <div className="form-check form-switch mt-3" dir="ltr">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="muteAllSwitch"
                            checked={this.state.mute}
                            //disabled={this.props.streamingStarted}
                            onChange={() => {
                              this.setState(
                                { mute: this.state.mute ? false : true },
                                () => {
                                  this.muteUnMuteAll(this.state.mute);
                                }
                              );
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="muteAllSwitch"
                          >
                            Mute all
                          </label>
                        </div>
                      )}
                    </div>
                    {this.props.participants.map((item, idx) => {
                      return (
                        <div
                          className="d-flex flex-column pb-2"
                          style={{ borderBottom: "1px solid #e6e9ea" }}
                        >
                          <div className="d-flex flex-row align-items-center p-2">
                            <div
                              style={{ width: "95%" }}
                              className="d-flex flex-row align-items-center"
                            >
                              {item.status === "active" && (
                                <div style={{ marginRight: 10 }}>
                                  <i
                                    className={
                                      item.mute || item.MuteMicAll
                                        ? "mdi mdi-microphone-off text-dark"
                                        : "mdi mdi-microphone text-dark"
                                    }
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </div>
                              )}

                              {item.enableKRPanoSync && (
                                <div style={{ marginRight: 10 }}>
                                  <i
                                    className={"mdi mdi-sync text-danger"}
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </div>
                              )}

                              {
                                <i
                                  className={
                                    item.status === "active"
                                      ? "mdi mdi-circle text-success align-middle me-1"
                                      : "mdi mdi-circle text-danger align-middle me-1"
                                  }
                                />
                              }
                              <div className="avatar-xs me-2">
                                <span className="avatar-title rounded-circle text-uppercase text-size-16">
                                  {item.username.charAt(0)}
                                </span>
                              </div>
                              <div>
                                <span className="font-size-13">
                                  {item.username}
                                </span>{" "}
                                <br />
                                {item.userSocketId === this.props.user_code && (
                                  <span className="font-size-10">(You)</span>
                                )}
                              </div>
                            </div>
                            {this.state.HostPermission &&
                              item.userSocketId !== this.props.user_code &&
                              item.status === "active" && (
                                <div style={{ width: "5%" }}>
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      href="#"
                                      className="card-drop"
                                      tag="a"
                                    >
                                      <i className="mdi mdi-dots-horizontal font-size-18" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                      {item.permission.map((elem, el) => {
                                        if (elem.id === "4") {
                                          if (
                                            this.state.streamingStarted &&
                                            this.state.sharedData.videotype ===
                                              "360"
                                          ) {
                                            return (
                                              <DropdownItem
                                                href="#"
                                                onClick={() =>
                                                  this.setPermission(
                                                    item,
                                                    elem,
                                                    el,
                                                    idx
                                                  )
                                                }
                                              >
                                                {elem.status && (
                                                  <i className="mdi mdi-check font-size-16 text-success me-1" />
                                                )}{" "}
                                                {item.enableKRPanoSync === true
                                                  ? "Revert Sync"
                                                  : "Allow Sync"}
                                              </DropdownItem>
                                            );
                                          }
                                        } else {
                                          return (
                                            <DropdownItem
                                              href="#"
                                              onClick={() =>
                                                this.setPermission(
                                                  item,
                                                  elem,
                                                  el,
                                                  idx
                                                )
                                              }
                                            >
                                              {elem.status && (
                                                <i className="mdi mdi-check font-size-16 text-success me-1" />
                                              )}{" "}
                                              {el !== 0
                                                ? elem.controlName
                                                : item.mute === true
                                                ? "Unmute"
                                                : "Mute"}
                                            </DropdownItem>
                                          );
                                        }
                                      })}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                              )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : this.state.liveDrawOpen ? (
                <div style={{ padding: "20px 0 10px 0" }}>
                  <div
                    style={{
                      padding: "0 10px 10px 10px",
                      marginBottom: 10,
                    }}
                  >
                    <span className="card-title mt-0 font-size-15 fw-light">
                      Share
                    </span>
                    <button
                      onClick={() => {
                        this.setState({
                          drawOpen: false,
                          liveDrawOpen: false,
                          chatOpen: false,
                          ptListOpen: false,
                          obrlistEnabled: false,
                        });
                        this.streambox.current.stopWebrtcRunning();
                      }}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{
                        fontSize: 15,
                        top: 19,
                        padding: "0.78rem 1.29rem",
                        right: 10,
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="w-100 user-chat">
                    {this.state.liveDrawOpen ? (
                      <WebRTCStream
                        startSharing={(data) => {
                          this.startSharing(data);
                        }}
                        stopSharing={() => {
                          this.stopSharing();
                        }}
                        webrtcSocketConnection={(webrtcSocketConnection) => {
                          this.setState({ webrtcSocketConnection });
                        }}
                        webrtcStreamStarted={(webrtcStreamStarted) => {
                          this.setState({ webrtcStreamStarted });
                        }}
                        wbcConnection={this.state.webrtcSocketConnection}
                        wrtcStreamStarted={this.state.webrtcStreamStarted}
                        meetingId={this.state.meetingInfo.code}
                        broadcasting={this.state.broadcasting}
                        streamingStarted={this.state.streamingStarted}
                        sharedData={this.state.sharedData}
                        liveDrawOpen={this.state.liveDrawOpen}
                        ref={this.streambox}
                        pageHeight={
                          this.state.height - this.state.footerHeight - 200
                        }
                      />
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Col>
          </div>
          {/* -------- Meeting Controllers --------- */}
          <div style={{ paddingVertical: 3, position: "relative" }}>
            {this.state.jitsiloaded && (
              <ConfControls
                socketUser={this.props.socketUser}
                jitsiloaded={this.state.jitsiloaded}
                isVideoMuted={this.state.isVideoMuted}
                isAudioMuted={this.state.isAudioMuted}
                tileViewMode={this.state.tileViewMode}
                tglFilmStrip={this.state.tglFilmStrip}
                chatOpen={this.state.chatOpen}
                ptListOpen={this.state.ptListOpen}
                displayName={this.state.display_name}
                reportShareEnabled={this.state.reportShareEnabled}
                participantCount={this.props.participants.length}
                muteUnMuteAll={(muteStatus) => this.muteUnMuteAll(muteStatus)}
                askToUnmute={() => this.askToUnmute()}
                streamingStarted={this.state.streamingStarted}
                unread={this.state.unread}
                userRole={this.props.socketUser.role}
                addprtPanel={() => {
                  this.setState({ addprtPanel: true });
                }}
                reportModal={() => {
                  this.setState({ reportModal: true });
                }}
                meetingClose={() => {
                  this.setState({ meetingClose: true });
                }}
                leaveRoom={() => {
                  this.leaveRoom();
                }}
                execCommand={(cmd) => {
                  this.executeCommand(cmd);
                }}
                openChatBox={() => {
                  this.openChatBox();
                }}
                getParticipantList={() => {
                  this.getParticipantList();
                }}
                openOBRBox={() => {
                  this.openOBRBox();
                }}
                openWebRTC={() => {
                  this.openWebRTCPanel();
                }}
                started360={this.state.started360}
                granted={this.state.granted}
                tgless={(cmd) => {
                  this.tgless(cmd);
                }}
              />
            )}
          </div>
          <Modal
            size="xl"
            isOpen={this.state.modal_center}
            toggle={this.tog_center}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
                Observation
              </h5>
              <button
                onClick={() => this.setState({ modal_center: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.obContentLoaded ? (
                <>
                  <Row>
                    <Col
                      xl="12"
                      lg="12"
                      sm="12"
                      className="mb-3 d-flex justify-content-center"
                    >
                      <img
                        src={this.state.snshot}
                        style={{
                          width: "60%",
                          height: "auto",
                          backgroundColor: "blue",
                        }}
                      ></img>
                    </Col>
                    <Col xl="12" lg="12" sm="12">
                      <div>
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          {this.state.login_status ? (
                            <Alert color="danger">{this.state.login_msg}</Alert>
                          ) : null}
                          {/* Check the Username and Password */}

                          <div className="form-group">
                            <AvField
                              name="title"
                              label="Title"
                              className="form-control"
                              placeholder="Enter Title for observation"
                              required
                              disabled={this.state.imageLoading}
                            />
                          </div>

                          <div className="form-group mt-3">
                            <AvField
                              name="description"
                              label="Description"
                              className="form-control"
                              placeholder="Enter Description"
                              type="textarea"
                              disabled={this.state.imageLoading}
                            />
                          </div>

                          <div className="mt-4 row">
                            <div className="col-auto ">
                              <Button
                                className="waves-effect waves-light "
                                color="danger"
                                disabled={this.state.imageLoading}
                                onClick={() =>
                                  this.setState({ modal_center: false })
                                }
                              >
                                Cancel{" "}
                              </Button>
                            </div>
                            <div className="col-auto ">
                              <Button
                                className=" waves-effect waves-light"
                                color="success"
                                type="submit"
                                disabled={this.state.imageLoading}
                              >
                                Confirm{" "}
                              </Button>
                            </div>
                          </div>
                          {this.state.imageLoading ? (
                            <div className="mt-4 row">
                              <div
                                className="spinner-border text-secondary m-1 col-2 "
                                role="status"
                              ></div>
                              <div className="col-10 d-flex align-item-center">
                                <span className="text-secondary">
                                  Please wait, image editior is loading...
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </AvForm>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}
            </div>
          </Modal>
          <Modal
            size="xl"
            isOpen={this.state.modal_fullscreen}
            toggle={this.tog_fullscreen}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
                Observation -{" "}
                <span className="text-primary">{this.state.obrTitle}</span>
              </h5>
              <button
                onClick={() => this.setState({ modal_fullscreen: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="d-flex flex-row h-100">
                <div style={{ width: "calc(100% - 360px)" }}>
                  <OBRImageEditor
                    imagePreviewUrl={this.state.imagePreviewUrl}
                    img_name={this.state.obInfo.img_name}
                    mandc={this.state.mandc}
                    ref={this.obreditor}
                  />
                </div>
                <div
                  className="ms-2"
                  style={{
                    width: 360,
                    borderLeft: "1px #eff2f7 solid",
                    backgroundColor: "#e9f2f8",
                    padding: "0 10px",
                  }}
                >
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={this.handleValidSubmit}
                  >
                    <div className="form-group mt-3">
                      <div
                        className="pb-2"
                        style={{ borderBottom: "1px #eff2f7 solid" }}
                      >
                        <span className="font-size-18">Observations</span>
                      </div>
                      <AvField
                        name="comment"
                        label=""
                        className="form-control mt-3"
                        placeholder="Enter your observation"
                        type="textarea"
                        style={{
                          minHeight: this.state.height - 200,
                          width: "100%",
                        }}
                        value={
                          this.state.mandc !== null &&
                          this.state.mandc !== undefined
                            ? this.state.mandc.muser_comment
                            : this.state.commentText
                        }
                        onChange={(event) => {
                          this.setState({ commentText: event.target.value });
                        }}
                        disabled={this.state.imageLoading}
                      />
                    </div>
                  </AvForm>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={this.tog_fullscreen}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      disabled={this.state.savingImage}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.convertAsImage();
                      }}
                      disabled={this.state.savingImage}
                    >
                      {this.state.savingImage ? (
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      ) : null}
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            size="md"
            isOpen={this.state.addprtPanel}
            toggle={this.tog_addprtPanel}
            centered={true}
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="exampleModalFullscreenLabel2"
              >
                Add People
              </h5>
              <button
                onClick={() => this.setState({ addprtPanel: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {this.state.addprtPanel ? (
              <div className="modal-body">
                <Row>
                  <Col className="col-12 mb-3">
                    <div
                      className="mb-1 pb-3"
                      style={{ borderBottom: "1px #e8eaef solid" }}
                    >
                      <label
                        className={
                          this.state.isemailError ? "text-danger" : null
                        }
                      >
                        Invite
                      </label>
                      <ReactMultiEmail
                        placeholder="Enter mail id"
                        emails={emails}
                        className={
                          !this.state.isemailError
                            ? "border border-dark"
                            : "border border-danger"
                        }
                        onChange={(_emails: string[]) => {
                          this.setState({
                            isemailError: false,
                            emails: _emails,
                            isemailEmpty: _emails.length > 0 ? false : true,
                          });
                        }}
                        validateEmail={(email) => {
                          return isEmail(email); // return boolean
                        }}
                        getLabel={(
                          email: string,
                          index: number,
                          removeEmail: (index: number) => void
                        ) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span
                                data-tag-handle
                                onClick={() => {
                                  this.setState({ isemailError: false });
                                  removeEmail(index);
                                }}
                              >
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                      {this.state.isemailError ? (
                        <label className="text-danger font-size-10">
                          {this.state.inviteError}
                        </label>
                      ) : null}
                    </div>

                    <div
                      className="w-100 mt-3"
                      style={{ height: 200, overflowY: "auto" }}
                    >
                      <label className="text-dark"> People Joined </label>
                      {this.state.meetingInfo.participants.map((item, idx) => {
                        return (
                          <div
                            className="d-flex flex-row align-items-center p-2"
                            style={{ borderBottom: "1px #e8eaef solid" }}
                          >
                            <div className="avatar-xs me-2">
                              <span className="avatar-title rounded-circle bg-info bg-soft">
                                <i className="mdi mdi-account font-size-15 text-white"></i>
                              </span>
                            </div>
                            <div>
                              <div>{item.displayName}</div>{" "}
                              <div>{item.email_id}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Button
                        disabled={!this.state.inviteOver}
                        className="me-2"
                        color="danger"
                        onClick={() => {
                          this.setState({
                            isemailError: false,
                            addprtPanel: false,
                            emails: [],
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!this.state.inviteOver}
                        color="primary"
                        onClick={() => this.invitePeople()}
                      >
                        Invite
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}
          </Modal>
          <Modal
            size="xl"
            isOpen={this.state.reportModal}
            toggle={this.tog_report}
            centered={true}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="exampleModalFullscreenLabel2"
              >
                Report
              </h5>
              <button
                onClick={() => this.setState({ reportModal: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {this.state.reportModal ? (
              <div className="modal-body" style={{ padding: "0 1rem" }}>
                <Row>
                  <Report meetingInfo={this.state.meetingInfo} />
                </Row>
                {this.state.granted ? (
                  <Row className="my-2">
                    <div className="d-flex flex-column align-items-end me-2">
                      <Button
                        color={this.state.isReportShared ? "danger" : "primary"}
                        className="d-inline-block"
                        style={{ width: 200 }}
                        onClick={() => {
                          this.setState(
                            {
                              isReportShared: this.state.isReportShared
                                ? false
                                : true,
                            },
                            () => {
                              this.openReport();
                            }
                          );
                        }}
                      >
                        <i
                          className={
                            this.state.isReportShared
                              ? "mdi mdi-share-off text-white me-2"
                              : "mdi mdi-share text-white me-2"
                          }
                        />
                        {this.state.isReportShared
                          ? "Stop Sharing"
                          : "Share Report"}
                      </Button>
                    </div>
                  </Row>
                ) : null}
              </div>
            ) : null}
          </Modal>
          <Modal
            isOpen={this.state.meetingClose}
            toggle={this.tog_meetingClose}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {this.state.meetingInfo.mname}
              </h5>
              <button
                type="button"
                onClick={() => this.setState({ meetingClose: false })}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="my-2 fw-bold">End this meeting</div>
              <div className="mb-3">
                <p>
                  Cras mattis consectetur purus sit amet fermentum. Cras justo
                  odio, dapibus ac facilisis in, egestas eget quam. Morbi leo
                  risus, porta ac consectetur ac, vestibulum at eros.
                </p>
              </div>

              <div>
                <Button
                  color="danger"
                  outline
                  className="me-2"
                  onClick={() => {
                    this.setState({ meetingClose: false });
                  }}
                >
                  Cancel
                </Button>
                {/* <Button
                  color="primary"
                  outline
                  className="me-2"
                  onClick={() => {
                    this.endMeeting("1");
                  }}
                >
                  Extend this meeting another time
                </Button> */}
                {this.props.socketUser.role === "Organizer" && (
                  <Button
                    color="primary"
                    outline
                    className="me-2"
                    onClick={() => {
                      this.endMeeting("2");
                    }}
                  >
                    End this call for everyone
                  </Button>
                )}

                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    this.leaveRoom();
                  }}
                >
                  Leave meeting
                </Button>
              </div>
            </div>
          </Modal>
        </Row>
      );
    } else {
      return null;
    }
  }
}

export default HOSTPlayer;
