import React, { Component, createRef, useState } from "react";

import PropTypes from "prop-types";
import { connect, shallowEqual } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment";

import "./tui-image-editor.css";
import 'tui-image-editor/dist/svg/icon-a.svg';
import 'tui-image-editor/dist/svg/icon-b.svg';
import 'tui-image-editor/dist/svg/icon-c.svg';
import 'tui-image-editor/dist/svg/icon-d.svg';
import "tui-color-picker/dist/tui-color-picker.css";
//import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from "@toast-ui/react-image-editor";
import WhiteTheme from './tui-theme'
import TuiImageEditor from "tui-image-editor";

const $ = require('jquery')

const _ = require("lodash");


class OBRImageEditor extends Component {
  editorRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      shapes: [],
      zoom: 1,
      maxZoom: 2,
      minZoom: 0.5,
      height: 0,
      width: 0,
      loaded: false,
    };
  }



  componentDidMount() {
    console.log("this.props.mandc", this.props.mandc)
    if (this.props.mandc !== undefined && this.props.mandc !== null) {
      var mandc = this.props.mandc;
      this.setState(
        {
          mandc,
          shapes:[]
        },
        () => {
          setTimeout(() => {
            this.loadShapes(0);
          }, 500);
        }
      );
    }
    
    $('.tie-btn-zoomIn').click(()=> {
     this.loadZoom()
    });
    $('.tie-btn-zoomOut').click(()=> {
      this.loadZoomOut()
    });

   
  }

  loadZoom(){
    
    let updated = this.state.zoom;
    updated = updated + 0.25;
    this.setState({updated})
    const editorInstance = this.editorRef.current?.getInstance();
    editorInstance._graphics.zoom({x:0, y:0}, updated)
    
    this.setState({zoom:updated})
  }

  loadZoomOut(){
    let updated = this.state.zoom;
    updated = updated - 0.25;
    const editorInstance = this.editorRef.current?.getInstance();
    editorInstance._graphics.zoom({x:0, y:0}, updated)
    this.setState({zoom:updated})
  }


  

  loadShapes = (count) => {
    if (count < this.state.mandc.objects.length) {
      const editorInstance = this.editorRef.current?.getInstance();
      var shape = this.state.mandc.objects[count];

      if(shape.type === "i-text")
      {
        editorInstance.addText(shape.text, {
          styles: {
            fill: shape.fill,
            fontSize: shape.fontSize,
            fontWeight: shape.fontWeight
        },
        position: {
            x: shape.left,
            y: shape.top
        }
        }).then((objectProps) => {
          this.objectHandling(objectProps)
          count++;
          this.loadShapes(count);
        });
      }
      else{
        editorInstance.addShape(shape.type, shape).then((objectProps) => {
          this.objectHandling(objectProps)
          count++;
          this.loadShapes(count);
        });
      }
     // editorInstance.discardSelection();
       
    }
    else{
      //const editorInstance = this.editorRef.current?.getInstance();
      //editorInstance.discardSelection();
    }
  };

  textHandling = (props) => {
    console.log("text", props);
  //  return
    const editorInstance = this.editorRef.current?.getInstance();
    console.log("editorInstance", editorInstance._graphics._canvas._objects)
    var props ={
      id:editorInstance.activeObjectId
    }

    var getIndex = _.findIndex(this.state.shapes, { id: props.id });
    if (getIndex === -1) {
      this.setState({ shapes: this.state.shapes.concat([props]) });
      return
    }
  };

  objectMoveHandling = (props) => {
    const editorInstance = this.editorRef.current?.getInstance();
    console.log("editorInstance", editorInstance._graphics._canvas._objects)
  }
  
  
  objectHandling = (props) => {
    const editorInstance = this.editorRef.current?.getInstance();
    console.log("editorInstance", editorInstance)
    //editorInstance._graphics.startZoomInMode()
    var getIndex = _.findIndex(this.state.shapes, { id: props.id });
    if (getIndex === -1) {
      this.setState({ shapes: this.state.shapes.concat([props]) });
      return
    }
    
  };

  mouseHandling =(props)=>{
    //this.editorRef.current.getRootElement().classList.remove('tie-btn-zoomIn');
    const editorInstance = this.editorRef.current?.getInstance();
    editorInstance._graphics.endZoomInMode()
    
    console.log("editorInstance", editorInstance, props)

  }

  onSaveObservation = () => {
    const editorInstance = this.editorRef.current?.getInstance();
    var allShapes = [];
    
    _.each(this.state.shapes, (item) => {
      console.log("item", item)

     // imageEditor._graphics.getObject(id);

      var props = editorInstance.getObjectProperties(item.id, {
        left: null,
        top: null,
        width: null,
        height: null,
        opacity: null,
        angle: null,
        id: null,
        fill: null,
        stroke: null,
        strokeWidth: null,
        type: null,
        text: null,
        fontFamily: null,
        fontSize: null,
        fontStyle: null,
        fontWeight: null,
        textAlign: null,
        textDecoration: null,
        
      });

      if (props !== null) {
        console.log("props", props);

        props["id"] = item.id;

        if (props.type === "circle") {
          props["rx"] = props.width/2;
          props["ry"] = props.height/2;
        }
        props["isRegular"] = false;

        allShapes.push(props);
      }
    });

    //console.log("props", allShapes)

    return allShapes;
  };

  getImageBase64(){
    const editorInstance = this.editorRef.current?.getInstance();
    var base64Data = editorInstance.toDataURL()
    return base64Data
  }

  render() {
    return (
      <ImageEditor
        ref={this.editorRef}
        includeUI={{
          loadImage: {
            path: this.props.imagePreviewUrl + this.props.img_name,
            name: "SampleImage",
          },
          theme: WhiteTheme,
          menu: ["shape",  "text"],
          initMenu: "",
          uiSize: {
            width: "100%",
            height: "100%",
          },
          menuBarPosition: "bottom",
        }}
        cssMaxHeight={768}
        cssMaxWidth={1024}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70,
        }}
        usageStatistics={false}
        onObjectAdded={this.objectHandling}
        onObjectMoved={this.objectMoveHandling}
        // onObjectRotated={this.objectHandling}
        // onObjectScaled={this.objectHandling}

        onAddText={this.textHandling}
        onCreatedPath={this.objectMoveHandling}
       onMousedown={this.mouseHandling}
       onResize={(event)=>{console.log("resizing", event)}}
      />
    );
  }
}

export default OBRImageEditor;
