import React, { Component } from 'react';

import { Container, Row, Col, CardBody, Card, Alert, Button } from "reactstrap";
import { Link } from "react-router-dom"

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../store/actions';

// import images
import profile from "../../assets/images/logo_blue.png";

import Logo from "../../assets/images/eaudit.jpg";
import logoDark from "../../assets/images/360_L.png";


var urlSocket = require('../../helpers/urlSocket');


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            login_status: false,
            loading: false,
            login_msg: '',
        }

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // Login Submit
    handleValidSubmit(event, values) {

        // this.props.loginUser(values, this.props.history);
        let deviceInfo = { ipAddress: "", macAddress: "" }
        try {
            urlSocket.post('/authentication/login', {
                username: values.username,
                password: values.password,
                mac_address: "",
                device_info: [deviceInfo],
                //   

            })
                .then((response) => {
                   // console.log("login", response.data)

                    if (response.data.response_code == 500) {
                        this.setState({ loading: true, login_status: true, login_msg: response.data.message })
                        const loginSession = response.data
                        sessionStorage.setItem("authUser", JSON.stringify(loginSession));
                        if (response.data.user_data.usertype == "99") {
                            this.props.history.push("/sdlmtng");
                        }
                        else {
                            this.props.history.push("/sdlmtng");
                        }
                    }
                    else {
                        this.setState({ login_status: true, login_msg: response.data.message })

                    }
                })
        }
        catch (error) {
            console.log(error.toString())
        }

        this.remove_sessions();

    }
    remove_sessions() {
        sessionStorage.removeItem("tm_list");
        sessionStorage.removeItem("entities_list_page");
        sessionStorage.removeItem("audit_parameters");
    }
    componentDidMount() {
        sessionStorage.clear();
        this.props.apiError("");
    }

    render() {
        return (
            <React.Fragment>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <div className="auth-full-page-content ">
                            <div className="w-100">
                                <div className="d-flex flex-row h-100 align-items-center justify-content-center">
                                    <Col  className="col-auto justify-content-center pe-5">
                                        <div className='my-3' style={{textAlign:'end'}}>
                                            <img src={logoDark} alt="" height="300" />
                                        </div>
                                    </Col>

                                    <Col xl={3} className="p-4" >


                                                    <div className="my-auto">
                                                       
                                                        <div>
                                                            <h5 className="text-primary">Welcome Back !</h5>
                                                            <p className="text-muted">Sign in to continue</p>
                                                        </div>

                                                        <div className="mt-4 p-2">

                                                            <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                                {this.state.login_status ? <Alert color="danger">{this.state.login_msg}</Alert> : null}
                                                                {/* Check the Username and Password */}


                                                                <div className="form-group">
                                                                    <AvField name="username" label="Username" className="form-control" placeholder="Enter Username" required />
                                                                </div>

                                                                <div className="form-group mt-3">
                                                                    <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                                                </div>

                                                                <div className="mt-4 d-block">
                                                                    <Button className="w-100 waves-effect waves-light" color="info" type="submit"> {this.state.loading ? "Loading" : "Log In"} </Button>
                                                                </div>
                                                            </AvForm>
                                                            {/* <div className="mt-5 text-center">
                                                                <p>Don't have an account ? <a href="register" className="fw-medium text-primary"> Signup now </a> </p>
                                                            </div> */}
                                                        </div>
                                                    </div>


                                                    <div className="mt-4 mt-md-5">
                                                        <p className="mb-0">
                                                            © {new Date().getFullYear()} eParampara Technologies.
                                                        </p>
                                                    </div>
                                     
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                {/* </div> */}
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser, apiError })(Login));

