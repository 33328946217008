import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component
import Test from "../pages/Test/Test";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Login2 from "../pages/Authentication/Login2";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//Landing Page


//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";




//Schedule Meeting
import ScheduleMeeting from "../pages/ScheduleMeeting/index"
import CRUDMeeting from "../pages/ScheduleMeeting/crudMeeting"

//Live Meeting
import PreJoinMeeting from "../pages/LiveMeeting/index"
import StartHosting from "../pages/LiveMeeting/meeting"
import ParticipantPreJoinedScreen from "../pages/Participants/index"
import ParticipantMeeting from "../pages/ParticipantsCon/index"
import MeetingInfo from "../pages/report/meetingInfo"
//import EndMeeting from "../pages/ParticipantsCon/meetingend"


//report
import report from "../pages/report/report";

// settings
import settings from "../pages/settings/settings";

//Reset
import reset from "../pages/reset/reset";



const authProtectedRoutes = [

	//Schedule Meeting
	{ path: "/sdlmtng", component: ScheduleMeeting }, 
	{ path: "/crmtng", component: CRUDMeeting }, 

	//Live Meeting
	{ path: "/lvmtng", component: PreJoinMeeting },
	{ path: "/hostng", component: StartHosting },
	{ path: "/mtnginfo", component: MeetingInfo },



	{ path: "/mtgrpt", component: report },

	{ path: "/setting", component: settings },

	{ path: "/reset", component: reset },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/pages-404" /> }
];

const authProtectedRoutesLink = [

	
];

const publicRoutes = [
	{ path: "/landing", component: Login2 },
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login2 },
	{ path: "/login2", component: Login2 },
	{ path: "/forget-password", component: ForgetPwd },
	{ path: "/pages-maintenance", component: PagesMaintenance },
	{ path: "/pages-comingsoon", component: PagesComingsoon },
	{ path: "/pages-404", component: Pages404 },
	{ path: "/pages-500", component: Pages500 },
	{ path: "/meeting", component: ParticipantPreJoinedScreen },
	{ path: "/prtmtng", component: ParticipantMeeting },
	//{ path: "/endssn", component: EndMeeting },
	//{ path: "/mtnglby", component: Lobby },

	{ path: "/Test", component: Test },

];

export { authProtectedRoutes, authProtectedRoutesLink, publicRoutes };
