import React, { Component, createRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";

class UNMUTEMESSAGE extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          padding: "20px 25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%" }}>
            <div style={{ fontSize: "1.125rem"}}>Unmute me</div>
            <div>{this.props.unnmuteuser.username} wants to unmute</div>
          </div>
          <div style={{ width: "5%" }}>
            <buttons
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{
                fontSize: 15,
                top: 19,
                padding: "0.78rem 1.29rem",
              }}
              onClick={()=>{this.props.closeMe()}}
            >
              <span aria-hidden="true">&times;</span>
            </buttons>
          </div>
        </div>
        <div style={{padding:"20px 0"}}>
              <Button onClick={()=>{this.props.unMuteRequestUser()}} color={"primary"}>Unmute</Button>
        </div>
      </div>
    );
  }
}

export default UNMUTEMESSAGE;
