import React, { Component, createRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown, Tooltip

} from "reactstrap";

class KRPanoControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isMenuExpanded:false,
    };
  }

  render() {
    return (
      <>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop}
          target="playpause"
          toggle={() => this.setState({ tttop: !this.state.tttop })}
        >
          {" "}
          Play / Pause
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_1}
          target="muteunmute"
          toggle={() => this.setState({ tttop_1: !this.state.tttop_1 })}
        >
          {" "}
          Mute / Unmute
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_2}
          target="virtualreality"
          toggle={() => this.setState({ tttop_2: !this.state.tttop_2 })}
        >
          {" "}
          Virtual Reality
        </Tooltip>
        {this.props.screenShotEnabled &&
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_3}
          target="observation"
          toggle={() => this.setState({ tttop_3: !this.state.tttop_3 })}
        >
          {" "}
          Observation
        </Tooltip>
        }
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_4}
          target="moveleft"
          toggle={() => this.setState({ tttop_4: !this.state.tttop_4 })}
        >
          {" "}
          Move Left
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_5}
          target="movetop"
          toggle={() => this.setState({ tttop_5: !this.state.tttop_5 })}
        >
          {" "}
          Move Top
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_6}
          target="movebottom"
          toggle={() => this.setState({ tttop_6: !this.state.tttop_6 })}
        >
          {" "}
          Move Bottom
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_7}
          target="moveright"
          toggle={() => this.setState({ tttop_7: !this.state.tttop_7 })}
        >
          {" "}
          Move Right
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_8}
          target="zoomin"
          toggle={() => this.setState({ tttop_8: !this.state.tttop_8 })}
        >
          {" "}
          Zoom In
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_9}
          target="zoomout"
          toggle={() => this.setState({ tttop_9: !this.state.tttop_9 })}
        >
          {" "}
          Zoom Out
        </Tooltip>
        {this.props.syncEnabled && <Tooltip
          placement="top"
          isOpen={this.state.tttop_10}
          target="sync"
          toggle={() => this.setState({ tttop_10: !this.state.tttop_10 })}
        >
          {" "}
          Sync
        </Tooltip>}
        <Tooltip
          placement="top"
          isOpen={this.state.tttop_11}
          target="hidecontrol"
          toggle={() => this.setState({ tttop_11: !this.state.tttop_11 })}
        >
          {" "}
          Hide / Show Controls
        </Tooltip>
        <ul class="list-group list-group-horizontal-md p-1">
          <li
            class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
            id="playpause"
            style={{
              width: 40,
              display: this.state.isMenuExpanded ? "none" : "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
          >
            <i
              className={
                this.props.videoPlaying && this.props.participantSync
                  ? "mdi mdi-pause text-secondary "
                  : !this.props.videoPlaying && this.props.participantSync
                  ? "mdi mdi-play text-secondary"
                  : this.props.videoPlaying && !this.props.participantSync
                  ? "mdi mdi-pause text-white "
                  : "mdi mdi-play text-white "
              }
              style={{ fontSize: 25 }}
              onClick={() =>
                this.props.participantSync ? null : this.props.togglePlay()
              }
            ></i>
          </li>
          <li
            class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
            id="muteunmute"
            style={{
              width: 40,
              display: this.state.isMenuExpanded ? "none" : "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
          >
            <i
              className={
                this.props.audioMute
                  ? "mdi mdi-volume-high text-white"
                  : "mdi mdi-volume-off text-white"
              }
              style={{ fontSize: 25 }}
              onClick={() => this.props.toggleMute()}
            ></i>
          </li>
          <li
            class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
            id="virtualreality"
            style={{
              width: 40,
              display: this.state.isMenuExpanded ? "none" : "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
          >
            <i
              className="mdi mdi-virtual-reality text-white"
              style={{ fontSize: 25 }}
              onClick={() => this.props.vrmode()}
            ></i>
          </li>
          {this.props.screenShotEnabled ? (
            <li
              class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
              id="observation"
              style={{
                width: 40,
                display: this.state.isMenuExpanded ? "none" : "flex",
                backgroundColor: "rgba(52,58,64,0.5)",
              }}
              onClick={() => this.props.takeScreenShot()}
            >
              <i
                className="mdi mdi-camera text-white"
                style={{ fontSize: 25 }}
              ></i>
            </li>
          ) : null}
          <li
            class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
            id="moveleft"
            style={{
              width: 40,
              display: this.state.isMenuExpanded ? "none" : "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
            onMouseDown={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("leftkeyDown")
            }
            onMouseUp={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("leftRightkeyUp")
            }
          >
            <i
              className={
                this.props.participantSync
                  ? "mdi mdi-arrow-left-thick text-soft text-secondary"
                  : "mdi mdi-arrow-left-thick text-white"
              }
              style={{ fontSize: 25 }}
            ></i>
          </li>
          <li
            class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
            id="movetop"
            style={{
              width: 40,
              display: this.state.isMenuExpanded ? "none" : "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
            onMouseDown={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("topkeyDown")
            }
            onMouseUp={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("topBottomkeyUp")
            }
          >
            <i
              className={
                this.props.participantSync
                  ? "mdi mdi-arrow-up-thick text-soft text-secondary"
                  : "mdi mdi-arrow-up-thick text-white"
              }
              style={{ fontSize: 25 }}
            ></i>
          </li>
          <li
            class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
            id="movebottom"
            style={{
              width: 40,
              display: this.state.isMenuExpanded ? "none" : "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
            onMouseDown={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("bottomkeyDown")
            }
            onMouseUp={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("topBottomkeyUp")
            }
          >
            <i
              className={
                this.props.participantSync
                  ? "mdi mdi-arrow-down-thick text-soft text-secondary"
                  : "mdi mdi-arrow-down-thick text-white"
              }
              style={{ fontSize: 25 }}
            ></i>
          </li>
          <li
            class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
            id="moveright"
            style={{
              width: 40,
              display: this.state.isMenuExpanded ? "none" : "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
            onMouseDown={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("rightkeyDown")
            }
            onMouseUp={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("leftRightkeyUp")
            }
          >
            <i
              className={
                this.props.participantSync
                  ? "mdi mdi-arrow-right-thick text-soft text-secondary"
                  : "mdi mdi-arrow-right-thick text-white"
              }
              style={{ fontSize: 25 }}
            ></i>
          </li>
          <li
            class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
            id="zoomin"
            style={{
              width: 40,
              display: this.state.isMenuExpanded ? "none" : "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
            onMouseDown={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("zoomInDown")
            }
            onMouseUp={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("zoomInOutUp")
            }
          >
            <i
              className={
                this.props.participantSync
                  ? "mdi mdi-magnify-plus text-soft text-secondary"
                  : "mdi mdi-magnify-plus text-white"
              }
              style={{ fontSize: 25 }}
            ></i>
          </li>
          <li
            class="list-group-item border-0 p-0  flex-row justify-content-center align-items-ceenter"
            id="zoomout"
            style={{
              width: 40,
              display: this.state.isMenuExpanded ? "none" : "flex",
              backgroundColor: "rgba(52,58,64,0.5)",
            }}
            onMouseDown={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("zoomOutDown")
            }
            onMouseUp={() =>
              this.props.participantSync
                ? null
                : this.props.krpanoControls("zoomInOutUp")
            }
          >
            <i
              className={
                this.props.participantSync
                  ? "mdi mdi-magnify-minus text-soft text-secondary"
                  : "mdi mdi-magnify-minus text-white"
              }
              style={{ fontSize: 25 }}
            ></i>
          </li>
          {this.props.syncEnabled ? (
            <li
              class="list-group-item border-0 p-0  flex-row justify-content-center align-items-ceenter"
              id="sync"
              style={{
                width: 40,
                display: this.state.isMenuExpanded ? "none" : "flex",
                backgroundColor:
                  this.props.krpanoSyncing === "start"
                    ? "rgba(255,3,3,0.6)"
                    : "rgba(52,58,64,0.5)",
              }}
              onClick={() => this.props.krpanoSyncStartStop()}
            >
              <i
                className={
                  this.props.krpanoSyncing === "start"
                    ? "mdi mdi-sync-off text-white"
                    : "mdi mdi-sync text-white"
                }
                style={{ fontSize: 25 }}
              ></i>
            </li>
          ) : null}

          <li
            class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
            id="hidecontrol"
            style={{ width: 40 }}
            onClick={() => {
              this.setState({
                isMenuExpanded: this.state.isMenuExpanded ? false : true,
              });
            }}
          >
            <i
              className={
                this.state.isMenuExpanded
                  ? "fas fa-chevron-right text-white"
                  : "fas fa-chevron-left text-white"
              }
              style={{
                fontSize: 20,
                lineHeight: "1.8",
              }}
            ></i>
          </li>
        </ul>
      </>
    );
  }
}

export default KRPanoControls
