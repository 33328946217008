import React, { Component, createRef, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Table,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import LobbyImage from "../../assets/images/lobby.jpg";
import logoDark from "../../assets/images/360_L.png";
import moment from "moment";


var ws;
var WebSocketsExist;
var krpano;
var WebSocketsConnected = false;
var sndmsgInterval;

class Lobby extends Component {
  
    constructor(props) {
      super(props);
      this.state = {
        dataloaded: false,
      };
    }

    componentDidMount() {

        var meetingInfo = JSON.parse(sessionStorage.getItem("usermeetingInfo"));


        if(meetingInfo === undefined || meetingInfo === null || meetingInfo === {})
        {
          var meetingURL = JSON.parse(sessionStorage.getItem("meetingURL"))
          window.location.href = meetingURL
          return
        }
        else
        {
          var partitionInfo = JSON.parse(sessionStorage.getItem("partInfo"));
          var data = JSON.parse(sessionStorage.getItem("authUser"));
          var confPlayerControl = JSON.parse(
            sessionStorage.getItem("confPlayerControl")
          );
      
          console.log("confPlayerControl", confPlayerControl);
      
          this.setState(
            {
              //userInfo: data.user_data,
              meetingInfo,
              meeting_id: meetingInfo._id,
              isAudioMuted: confPlayerControl.isAudioMuted,
              isVideoMuted: confPlayerControl.isVideoMuted,
              partitionInfo,
              display_name: confPlayerControl.displayInfo.displayname,
              dataloaded: true,
            }
          );
        }
        
      }


render(){
  if(this.state.dataloaded){
    return (
      <React.Fragment>
        <div className="page-content" style={{ height: "100vh", padding: 10 }}>
          <Container fluid className="p-0 h-100">
            <div className="w-100 h-100">
              <div className="d-flex flex-col h-100 align-items-center justify-content-center">
                <img src={LobbyImage} style={{ width: "40%" }} />
                <Col lg={4}>
                  <Card style={{boxShadow: "none"}}>
                    <CardHeader className="h5 bg-transparent border-bottom text-uppercase">
                      <div className="my-3 font-size-20">
                        {this.state.meetingInfo.mname}
                      </div>
                      
                    </CardHeader>
                    <CardBody>
                      <CardTitle className="h4 mt-0 text-primary">
                        <span className="font-size-15 text-dark fw-light">
                          Organized by
                        </span>{" "}
                        <span className="font-size-17 text-dark fw-light"> {this.state.meetingInfo.organized_by}</span>
                        <div className="font-size-12 text-primary mt-1 fw-light">
                          Scheduled on - {moment(this.state.meetingInfo.scheduled_on).format(
                            "LLLL"
                          )}
                        </div>
                      </CardTitle>
                      <CardText className="my-3 font-size-18">
                        Please wait. Meeting is not started, you will connect
                        once host start the meeting
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
  else
  {
    return null
  }
}
}


export default Lobby;