import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Input,
  Badge,
  Progress,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
} from "reactstrap";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";



import _ from "lodash";
import { isEmpty, size, map } from "lodash";
import moment from "moment";

var urlSocket = require("../../helpers/urlSocket");
var randomColor = require("randomcolor");



class ScheduleMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      usertype: "",
      dataloaded: false,
      auditStatusData: [
        {
          status: "Active",
          count: 0,
          color: "#fbae17",
          id: "1",
          badgeClass: "success",
        },
        {
          status: "In active",
          count: 0,
          color: "#303335",
          id: "0",
          badgeClass: "danger",
        },
      ],
      userHLevel: "All",
      resultData: [],
      ddchange: false,
      open: false,
      labelData: [],
      selectedLabel: false,
      selectedEOPT: [],
      height: window.innerHeight,
      custom_tbx: [],
      emails: [],
      isemailEmpty: false,
      scheduleMeeting: false,
      datalist: false,
      datetime: moment(new Date()).format("YYYY-MM-DDTHH:MM"),
      enddatetime: moment(new Date()).format("YYYY-MM-DDTHH:MM"),
    };

  }

  //Toggle Folder Menus
  toggleFolder() {
    this.setState((prevState) => ({
      folder_Menu: !prevState.folder_Menu,
    }));
  }

  componentWillMount() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var auditData = JSON.parse(sessionStorage.getItem("auditData"));

    this.setState(
      {
        img_max_mb: data.img_max_mb,
        userInfo: data.user_data,
        usercode: data.user_data.user_code,
        mappinginfo: data.user_data.mappinginfo,
        designation: data.user_data.designation,
        configData: data.config_data,
        auditData: auditData,
        dataloaded: false,
      },
      function () {
        this.loadMeetings();
      }
    );

  }

  loadMeetings = () => {
    try {
      urlSocket
        .post("mngmtg/listmeetings", {
          userInfo: {
            company_id: this.state.userInfo.company_id,
            user_id: this.state.userInfo._id,
          },
        })
        .then((response) => {
          console.log("response.data.data", response.data.data);
          this.setState({
            statusBasedFilteredData: response.data.data,
            tableDataloaded: true,
            allowTableRowToSelect: true,
            dataloaded: true,
            datalist: true,
            scheduleMeeting: false,
          });
        });
    } catch (error) {}
  };

  navigateToReport(meetingInfo)
  {
    sessionStorage.removeItem("usermeetingInfo");
    sessionStorage.setItem("usermeetingInfo", JSON.stringify(meetingInfo));
    this.props.history.push("/mtnginfo");
  }


  navigateToMeeting(meetingInfo) {
    //var url =  window.location.protocol+'//'+window.location.hostname+":"+window.location.port
    var url = window.location.protocol + "//" + window.location.hostname;
    console.log("get url ", url);
    var user = _.find(meetingInfo.participants, {email_id:this.state.userInfo.email_id} )
    console.log("user", user)
    //window.location.href = user.meeting_url;
    window.open(
      user.meeting_url,
      '_blank' // <- This is what makes it open in a new window.
    );
    // sessionStorage.removeItem("usermeetingInfo");
    // sessionStorage.setItem("usermeetingInfo", JSON.stringify(meetingInfo));
    // this.props.history.push("/lvmtng");
  }

  nvaigateToCRUDMeeting(meetingInfo) {
    var url = window.location.protocol + "//" + window.location.hostname;
    sessionStorage.removeItem("getMeetingInfo");
    sessionStorage.setItem("getMeetingInfo", JSON.stringify(meetingInfo));
    this.props.history.push("/crmtng");
  }

 

  getSelectedEOPT = (row, isSelect, rowIndex, e) => {
    console.log("row -- ", row);
  };

  setMeetingStatus(status)
  {
    if(status === "-1") return 
    if(status === "0") return "Scheduled"
    if(status === "1") return "Extended"
    if(status === "2") return "Finished"
    if(status === "3") return "Rescheduled"
    if(status === "4") return "Postponed"
    if(status === "5") return "Cancelled"
  }

  

  render() {
    if (this.state.dataloaded) {
      const { emails } = this.state;
      const { SearchBar } = Search;
      // const { users } = this.state
      const options = {
        // pageStartIndex: 0,

        sizePerPage: 10,
        totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
        custom: true,
      };
      const columns = [
        {
          text: "id",
          dataField: "_id",
          sort: true,
          hidden: true,
          formatter: (cellContent, item) => <>{item._id}</>,
        },
        {
          dataField: "mname",
          text: "Name",
          sort: false,
          formatter: (cellContent, item) => {
           // var isBefore = moment(item.scheduled_on).isBefore(new Date());
            return (
              <>
              <div className="d-flex flex-row justify-content-conter align-items-center">
                <div className={item.status === "5" ? "px-3 text-align-center text-danger" :"px-3 text-align-center text-primary"}
                 onClick={() => {
                  this.nvaigateToCRUDMeeting(item, "edit")
                }}
                >
                  <i className={item.status === "5" ? "mdi mdi-calendar-clock text-danger" :"mdi mdi-calendar-clock text-primary"} style={{fontSize:25}}></i><br/>
                  Edit
                </div>
                <div className="ms-2">
                <div
                  style={{
                    margin: "5px 0px",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                  className="fw-light"
                >
                  {item.mname}
                </div>
                <div className="font-size-11">
                  <span
                    className={item.status === "5" ? "font-size-12 text-danger" :"font-size-12 text-primary"}
                  >
                    Scheduled on - {moment(item.scheduled_on).format("LLLL")}
                  </span>
                </div>
                </div>
                </div>
              </>
            );
          },
        },

        {
          dataField: "created_on",
          isDummyField: true,
          hidden: true,
          text: "updated on",
        },

        {
          dataField: "status",
          text: "Status",
          formatter: (cellContent, item) => {
            return <label className={item.status === "5" ? 
            "badge bg-danger font-size-13" : item.status === "0" ?
            "badge bg-secondary font-size-13" : item.status === "1" ?
            "badge bg-warning font-size-13" : item.status === "2" ?
            "badge bg-success font-size-13" : item.status === "3" || item.status === "4"?
            "badge bg-primary font-size-13" : null}
            >{this.setMeetingStatus(item.status)}</label>
          }
        },

        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          headerStyle: { display:"flex", flexDirection:"column", alignItems:"flex-end" },
          formatter: (cellContent, item) => {
            var isBefore = moment(item.scheduled_on).isBefore(new Date());
            if(item.status !== "5")
            {
            return (
              <div
                className="d-flex flex-column"
                style={{ alignContent: "center", alignItems:"flex-end" }}
              >
                <div>
                <Button
                    type="button"
                    color="primary"
                    size="sm"
                    outline
                    className="btn waves-effect waves-light ps-3 pe-3 me-2"
                    disabled={item.status == "0" }
                    onClick={() => {
                     this.navigateToReport(item);
                    }}
                  >
                    Report
                  </Button>
                  {item.status !== "2" && item.meeting_status == "0" ?
                <Button
                  type="button"
                  //color={isBefore ? "secondary" : "success"}
                  color={"success"}
                  size="sm"
                  className="btn waves-effect waves-light ps-3 pe-3"
                  onClick={() => {
                    this.navigateToMeeting(item);
                  }}
                  //disabled={isBefore}
                >
                  Start Meeting
                </Button> : null }
                </div>
                
              </div>
            );
                }
                else
                {
                  return null
                }
          },
        },
      ];
      const defaultSorted = [
        {
          dataField: "created_on", // if dataField is not match to any column you defined, it will be ignored.
          order: "desc", // desc or asc
        },
      ];

      const selectRow = {
        mode: "checkbox",
        clickToSelect: true, // this.state.allowTableRowToSelect,
        onSelect: (row, isSelect, rowIndex, e) => {
          console.log("row selectedd", row, isSelect, rowIndex, e);
          this.getSelectedEOPT(row, isSelect, rowIndex, e);
        },
        // onSelectAll: (isSelect, rows, e) => {
        //     console.log("select all", rows,)
        // },
        // nonSelectable: this.state.label.endpoints
      };
      return (
        <React.Fragment>
          <div className="page-content" style={{ minHeight: "100vh" }}>
            <Container fluid>
              {/* <Breadcrumbs title="" breadcrumbItem="Dashboard" /> */}

                      <div
                        style={{
                          height: this.state.height - 150,
                          overflowY: "auto",
                          overflowX: "hidden"
              }}
                      >
                        <Row style={{ paddingBottom:5}}>
                          <div className="">
                            <Button
                              type="button"
                              color="primary"
                              className="waves-effect waves-light  mt-2"
                              onClick={() => {
                                this.nvaigateToCRUDMeeting("", "new");
                              }}
                            >
                              Create Meeting
                            </Button>
                          </div>
                        </Row>
                        <div className="my-3"></div>

                        <Row>
                          {this.state.datalist ? (
                            <PaginationProvider
                              keyField="id"
                              data={this.state.statusBasedFilteredData}
                              columns={columns}
                              pagination={paginationFactory(options)}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  data={this.state.statusBasedFilteredData}
                                  columns={columns}
                                  search
                                >
                                  {(toolkitprops) => (
                                    <React.Fragment>
                                      <Row
                                        className="mb-2"
                                        style={{ paddingTop: 10 }}
                                      >
                                        <Col lg="12">
                                          <div className="search-box d-inline-block">
                                            <div className="position-relative align-items-center">
                                              <SearchBar style={{border:"none", borderBottom:"1px solid #d7d7d7",
                                               fontSize:"1.5rem", borderRadius:0, width:"100vw", backgroundColor:"#f6faff"}}
                                                {...toolkitprops.searchProps}
                                                placeholder="My Meetings"
                                                className="fw-light"
                                              />
                                              <i className="bx bx-search-alt search-icon font-size-20 mt-2" />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              {...toolkitprops.baseProps}
                                              {...paginationTableProps}
                                              defaultSorted={defaultSorted}
                                              classes={
                                                "table align-middle table-nowrap table-hover"
                                              }
                                              bordered={false}
                                              striped={false}
                                              responsive
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-md-center mt-30">
                                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          ) : null}
                        </Row>
                      </div>
                 


              
            </Container>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default ScheduleMeeting;
