import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { } from "../../store/actions";

// MetisMenu
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

// import images
import eauditLogo from "../../assets/images/logo-light-1.png";

let screens = [];




class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screensDisplay: [],
            menuDataLoaded:false
        };
    }

    async componentDidMount() {
        await this.get_basicData();
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }
    async get_basicData() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        screens = data.user_data.facilities
        this.setState({
            screensDisplay: data.user_data.facilities,
            menuDataLoaded:true
        })
    }

    async initMenu() {
        // if (this.props.type !== "condensed" || this.props.isMobile) {
        await new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        if (ul != null) {
            var items = ul.getElementsByTagName("a");

            for (var i = 0; i < items.length; ++i) {
                let url = this.props.location.pathname.replace(/\d+/g, '')
                let pathname = items[i].pathname.replace(/\d+/g, '')
                if (url === pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                this.activateParentDropdown(matchingMenuItem);
            }
        }

        // }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };


    SidebarContent = (props) => {
        return (
            <>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">Menu</li>
                        {
                            screens.map((data, index) => (

                                <li key={index}>
                                    <Link to={"/" + data.url} className="waves-effect" style={{ color: this.isActive(data.child) }} >
                                        <i className={data.icon_name}></i>
                                        <span>{data.interfacename}</span>

                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </>
        )
    };


    isActive = (child) => {
        try {
            for (let index = 0; index < child.length; index++) {
                if (window.location.pathname.split("/").pop() === child[index]) {
                    return '#d9e021'
                }
                else {
                    return null
                }

            }

        } catch (error) {

        }
    }

    render() {
        if (this.state.menuDataLoaded) {
            return (
                <React.Fragment>
                    <div className="vertical-menu" style={{borderRight:"1px solid #dadada"}}>
                        <div className="navbar-brand-box" style={{ backgroundColor: '#ffffff', borderBottom:"1px solid #dadada" }}>
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="" height="35" />
                                </span>
                            </Link>

                            <Link to="/Dashboard" className="logo logo-light" >
                                <span className="logo-sm">
                                    <img src={logoLightSvg} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={eauditLogo} alt="" height="22" />
                                </span>
                            </Link>
                        </div>
                        <div data-simplebar className="h-100">
                            {this.props.type !== "condensed" ? (
                                <SimpleBar style={{ maxHeight: "100%" }}>
                                    {
                                        this.SidebarContent(this.state.screensDisplay)
                                    }
                                </SimpleBar>
                            ) :
                                this.SidebarContent(this.state.screensDisplay)
                            }
                        </div>

                    </div>
                </React.Fragment>
            );
        }
        else {
            return null
        }
    }
}

const mapStatetoProps = state => {
    return {
        layout: state.Layout
    };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
