import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import DateTimePicker from 'react-datetime-picker';
//import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'

import './datetimepicker.css'

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import { ReactMultiEmail, isEmail } from "react-multi-email";
//import "react-multi-email/style.css";
import "./multiemailstyle.css";


import _ from "lodash";
import { isEmpty, size, map } from "lodash";
import moment from "moment";

var urlSocket = require("../../helpers/urlSocket");
var randomColor = require("randomcolor");

interface IProps {}
interface IState {
  emails: string[];
}

class ScheduleMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataloaded: false,
      height: window.innerHeight,
      custom_tbx: [],
      emails: [],
      isemailEmpty: false,
      scheduleMeeting: false,
      datalist: false,
      datetime: new Date(),
      enddatetime: new Date(),
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
  }

 

  componentWillMount() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var meetingInfo = JSON.parse(sessionStorage.getItem("getMeetingInfo"));

    if (meetingInfo !== "") {
      this.setState(
        {
          img_max_mb: data.img_max_mb,
          userInfo: data.user_data,
          configData: data.config_data,
          dataloaded: false,
        },
        function () {
          this.getMeeting(meetingInfo);
        }
      );
    } else {
      this.setState({
        img_max_mb: data.img_max_mb,
        userInfo: data.user_data,
        configData: data.config_data,
        participants: [{email_id:data.user_data.email_id, role:"Organizer"}],
        meetingStatus:"-1",
        dataloaded: true,
      });
    }
  }

   compare( a, b ) {
    if ( a.role < b.role ){
      return -1;
    }
    if ( a.role > b.role ){
      return 1;
    }
    return 0;
  }
  
  

  getMeeting(data) {
    try {
      urlSocket
        .post("mngmtg/getmeeting", {
          meetingInfo: data,
        })
        .then((response) => {
          var meetingInfo = response.data.data;
          var participants = meetingInfo.participants.sort((a,b) => (a.role > b.role) ? 1 : ((b.role > a.role) ? -1 : 0));
         // var emails = _.map(participants, "email_id");
          var getOrgIndex = _.findIndex(meetingInfo.participants,{"role":"Organizer"})
          console.log("meetingInfo", meetingInfo);
          console.log("new Date", new Date(), new Date(meetingInfo.scheduled_on))
          this.setState({
            meetingInfo,
            participants,
            scheduleMeeting: true,
            isEdit: true,
            isemailEmpty: false,
            meetingname: meetingInfo.mname,
            mdescription: meetingInfo.description,
            datetime:  new Date(meetingInfo.scheduled_on),
            enddatetime: new Date(meetingInfo.ends_on),
            mduration: meetingInfo.duration,
            //emails: emails,
            meeting_id: meetingInfo._id,
            getOrgIndex,
            meetingStatus:meetingInfo.status,
            dataloaded: true,
          });
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  clearData() {
    //console.log("new ---", moment(new Date()).format("YYYY-MM-DDTHH:mm"))
    this.setState({
      scheduleMeeting: true,
      meetingname: "",
      mdescription: "",
      datetime: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      enddatetime: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      mduration: "",
      emails: [],
    });
  }

  handleInvalidSubmit(event, values) {
    var isemailEmpty = this.state.emails.length > 0 ? false : true;
    this.setState({
      isemailEmpty,
      datalist: true,
    });
  }

  setMeetingStatus(status)
  {
    if(status === "-1") return 
    if(status === "0") return "Scheduled"
    if(status === "1") return "Extended"
    if(status === "2") return "Finished"
    if(status === "3") return "Rescheduled"
    if(status === "4") return "Postponed"
    if(status === "5") return "Cancelled"
  }

  tagthisMeeting(status){
    this.setState({
      meetingStatus:status,
    })
  }

  handleValidSubmit(event, values) {
    var url = window.location.protocol + "//" + window.location.hostname;
    //var url =  window.location.protocol+'//'+window.location.hostname+":"+window.location.port
    console.log("handleValidSubmit", this.state.datetime.toISOString())
    this.setState({
      savingData:true
    });

      let start = moment(this.state.datetime, "YYYY-MM-DD hh:mm:ss"); // some random moment in time (in ms)
      let end = moment(this.state.enddatetime, "YYYY-MM-DD hh:mm:ss"); // some random moment after start (in ms)
      let diff = end.diff(start);
      let f = moment.utc(diff).format("HH:mm:ss");
      values["duration"] = f;
      values["dateTime"] = this.state.datetime;
      values["enddatetime"] = this.state.enddatetime;
      if(this.state.emails.length >= 1)
      {
        if (this.state.isEdit) {
          var meetingParticipants = _.map(
            this.state.meetingInfo.participants,
            "email_id"
          );
          var filteredMails = this.state.emails.filter(
            (val) => !meetingParticipants.includes(val)
          );
          var mailObjects = []
          _.each(filteredMails, item =>{
            mailObjects.push({
              email_id:item,
              role:"Participants"
            })
          })
          try {
            urlSocket
              .post("mngmtg/updatemtng", {
                meetingValues: values,
                meetingInfo: this.state.meetingInfo,
                emails: this.state.meetingStatus === "5" ? this.state.meetingInfo.participants : mailObjects,
                _id: this.state.meeting_id,
                config_data: this.state.configData,
                meetingURL: url + "/meeting",
                status:this.state.meetingStatus === "-1" ? "0" : this.state.meetingStatus
              })
              .then((response) => {
                console.log("responde", response.data.data);
                this.setState({savingData:false})
                this.props.history.push("/sdlmtng");
                //this.loadMeetings();
              });
          } catch (error) {
            console.log("catch error", error);
          }
        } else {
          try {
            var mailObjects = []
            _.each(this.state.emails, item =>{
              console.log("_.this.state.participants", this.state.participants[0].email_id, item.email_id)
              if(item !== this.state.participants[0].email_id)
              {
              mailObjects.push({
                email_id:item,
                role:"Participants"
              })
            }
            })
            mailObjects = mailObjects.concat(this.state.participants)
            urlSocket
              .post("mngmtg/scdlemtg", {
                meetingInfo: values,
                emails: mailObjects,
                userInfo: {
                  _id: this.state.userInfo._id,
                  username: this.state.userInfo.first_name,
                  email_id: this.state.userInfo.general_info[0].email_id,
                  company_id: this.state.userInfo.company_id,
                },
                config_data: this.state.configData,
                meetingURL: url + "/meeting",
                status:"0"
              })
              .then((response) => {
                console.log("responde", response.data.data);
                this.setState({savingData:false})
                this.props.history.push("/sdlmtng");
                //this.loadMeetings();
              });
          } catch (error) {
            console.log("catch error", error);
          }
        }
      }
      else
      {
        console.log("_. No participants added")
        this.setState({isemailEmpty:true, savingData:false})
      }

      
  }

  render() {
    if (this.state.dataloaded) {
      const { emails } = this.state;
      return (
        <React.Fragment>
          <div
            className="page-content"
            style={{ minHeight: "100vh", paddingBottom: 0 }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
                alignItems: "center",
              }}
            >
              <div style={{ width: "80%", padding: "0 20px" }}>
                <span className="font-size-15 fw-bold text-primary">
                  Meeting Information
                </span>
              </div>
              <div
                style={{
                  width: "20%",
                  padding: "0 20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Link to="/sdlmtng">
                  <Button color="primary">
                    Back <i className="mdi mdi-arrow-left"></i>
                  </Button>
                </Link>
              </div>
            </div>

            <div className="w-100">
              <Row style={{}}>
                <Col lg="12">
                  <div className="p-4">
                    <div
                      style={{
                        height: this.state.height - 150,
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                        onInvalidSubmit={this.handleInvalidSubmit}
                      >
                        <Row className="">
                          <Col className="col-12">
                            <div className="mb-3">
                              <AvField
                                name="meetingname"
                                label="Meeting Name"
                                labelClass="font-size-12 fw-light text-secondary"
                                className="form-control"
                                placeholder="Enter Meeting Name"
                                type="text"
                                value={this.state.meetingname}
                                disabled={this.state.isEdit}
                                required
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid #adadad",
                                  fontSize: "1.2rem",
                                  borderRadius: 0,
                                }}
                              />
                            </div>
                          </Col>
                        </Row>


                        <Row>
                          <Col xl="6">
                            <Row className="mt-3">
                            <Col className="col-auto">
                              <label>Start date</label>
                              <DateTimePicker
                                amPmAriaLabel="AM/PM"
                                format="dd-MM-y hh:mm a"
                                className="form-control"
                                disableClock
                                minDate={new Date()}
                                value={this.state.datetime}
                                key={"dtpicker_1"}
                                onChange={(e) => {
                                  console.log("event", e);
                                  this.setState({datetime:e})
                                }}
                              />
                              </Col>
                              <Col className="col-auto">
                              <label>End date</label>
                              <DateTimePicker
                                amPmAriaLabel="AM/PM"
                                format="dd-MM-y hh:mm a"
                                className="form-control"
                                disableClock
                                minDate={new Date()}
                                key={"dtpicker_2"}
                                value={this.state.enddatetime}
                                onChange={(e) => {
                                  console.log("event", e);
                                  this.setState({enddatetime:e})
                                }}
                              />
                              </Col>
                            </Row>

                            

                            <Row className="mt-3">
                              <Col className="col-12">
                                <div className="mb-3">
                                  <AvField
                                    name="description"
                                    label="Description"
                                    labelClass="font-size-12 fw-light text-secondary"
                                    className="form-control"
                                    placeholder="Enter Meeting Description"
                                    value={this.state.mdescription}
                                    type="textarea"
                                    style={{
                                      border: "none",
                                      borderRadius: 0,
                                      height: 100,
                                      backgroundColor: "#eef3f8",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            {this.state.isEdit ? (
                              <Row className="mt-3">
                                <div className="my-2">Tag this meeting as</div>
                                <div
                                  className="button-items "
                                  style={{ marginLeft: 10 }}
                                >
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic radio toggle button group"
                                  >
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio6"
                                      autoComplete="off"
                                      checked={
                                        this.state.meetingStatus === "-1"
                                      }
                                      onClick={() => {
                                        this.tagthisMeeting("-1");
                                      }}
                                    />
                                    <label
                                      className="btn btn-outline-primary btn-sm"
                                      htmlFor="btnradio6"
                                    >
                                      None
                                    </label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio0"
                                      autoComplete="off"
                                      checked={this.state.meetingStatus === "3"}
                                      onClick={() => {
                                        this.tagthisMeeting("3");
                                      }}
                                    />
                                    <label
                                      className="btn btn-outline-primary btn-sm"
                                      htmlFor="btnradio0"
                                    >
                                      Rescheduled
                                    </label>

                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio1"
                                      autoComplete="off"
                                      checked={this.state.meetingStatus === "4"}
                                      onClick={() => {
                                        this.tagthisMeeting("4");
                                      }}
                                    />
                                    <label
                                      className="btn btn-outline-primary btn-sm"
                                      htmlFor="btnradio1"
                                    >
                                      Postponed
                                    </label>

                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio2"
                                      autoComplete="off"
                                      checked={this.state.meetingStatus === "5"}
                                      onClick={() => {
                                        this.tagthisMeeting("5");
                                      }}
                                    />
                                    <label
                                      className="btn btn-outline-danger btn-sm"
                                      htmlFor="btnradio2"
                                    >
                                      Cancelled
                                    </label>
                                  </div>
                                </div>
                              </Row>
                            ) : null}

                           
                          </Col>
                          <Col xl="6">
                            <Row className="mt-3">
                              <Col className="col-12 mb-3">
                                <div className="mb-1">
                                  <label
                                    className={
                                      "font-size-12 fw-light text-secondary"
                                    }
                                  >
                                    Add People
                                  </label>
                                  <ReactMultiEmail
                                    placeholder="Enter mail id"
                                    emails={emails}
                                  //  onKeyPress={()=>{
                                  //   this.style.width = ((this.value.length + 1) * 8) + 'px';
                                  //  }}
                                    style={{
                                      border: "none",
                                      borderRadius: 0,
                                      height: 100,
                                      // backgroundColor: "#eef3f8",
                                    }}
                                    className={
                                      "border border-soft border-secondary"
                                    }
                                    onChange={(_emails: string[]) => {
                                      
                                      this.setState({
                                        emails: _emails,
                                        isemailEmpty:
                                          _emails.length > 0 ? false : true,
                                      });
                                    }}
                                    validateEmail={(email) => {
                                      return isEmail(email); // return boolean
                                    }}
                                    getLabel={(
                                      email: string,
                                      index: number,
                                      removeEmail: (index: number) => void
                                    ) => {
                                      return (
                                        <div
                                          data-tag
                                          key={index}
                                          style={{ backgroundColor: "#eef3f8" }}
                                        >
                                          {email}
                                          <span
                                            data-tag-handle
                                            onClick={() => removeEmail(index)}
                                          >
                                            ×
                                          </span>
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                                {this.state.isemailEmpty ? (
                                  <label className="text-danger font-size-10">
                                    Minimum one participant should be added
                                  </label>
                                ) : null}
                                <div
                                  className="w-100 mt-3"
                                  style={{ overflowY: "auto" }}
                                >
                                  <label className="text-dark"> People </label>

                                  {this.state.participants.map((item, idx) => {
                                    return (
                                      <div
                                        className="d-flex flex-row align-items-center p-2"
                                        //style={{ borderBottom: "1px #e8eaef solid",}}
                                      >
                                        <div
                                          className="avatar-sm me-2"
                                          style={{
                                            height: "2rem",
                                            width: "2rem",
                                          }}
                                        >
                                          <span
                                            className={
                                              item.role !== "Organizer"
                                                ? "avatar-title rounded-circle bg-primary text-uppercase text-white font-size-16"
                                                : "avatar-title rounded-circle bg-success text-uppercase text-white font-size-16"
                                            }
                                          >
                                            {item.email_id.charAt(0)}
                                          </span>
                                        </div>
                                        <div>
                                          <div>{item.email_id}</div>
                                          <div
                                            className={
                                              item.role !== "Organizer"
                                                ? "font-size-12 text-soft text-primary"
                                                : "font-size-12 text-soft text-success"
                                            }
                                          >
                                            {item.role}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-5">
                              <Col className="col-12">
                                <div className="mb-3 me-2">
                                  <Button
                                    color="primary"
                                    className="me-2"
                                    disabled={this.state.savingData}
                                  >
                                    {this.state.savingData ? (
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    ) : null}
                                    Save
                                  </Button>
                                  <Button
                                    color="secondary"
                                    onClick={() => {
                                      this.setState({
                                        scheduleMeeting: false,
                                        emails: [],
                                      });
                                    }}
                                    disabled={this.state.savingData}
                                  >
                                    Close
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                      </AvForm>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default ScheduleMeeting;
