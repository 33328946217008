import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Row,
  Col,
  FormGroup,
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Container,
  Alert,
  Label,
  Form,
  Progress,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import Hls from "hls.js";
import socketIOClient from "socket.io-client";
import classnames from "classnames";

const _ = require("lodash");
var urlSocket = require("../../../helpers/urlSocket");

var hls = new Hls({
  debug: false,
  enableWorker: false,
  lowLatencyMode: true,
  backBufferLength: 90,
  capLevelToPlayerSize: false,
  startLevel: -1,
});

const peerConnections = {};
const config = {
  iceServers: [
    {
      urls: "stun:stun.l.google.com:19302",
    },
    {
      urls: "stun:stun1.l.google.com:19302",
    },
    {
      urls: "stun:stun2.l.google.com:19302",
    },
    {
      urls: "stun:stun3.l.google.com:19302",
    },
    {
      urls: "stun:stun4.l.google.com:19302",
    },
    { urls: 'stun:meet-jit-si-turnrelay.jitsi.net:443' },

    {
      urls: "turn:15.207.100.160:3478?transport=tcp",
      username: "eprmadmin",
      credential: "Eprm123",
    },
  ],
};

let socketConnection = "";
let videoStream = {};
var mediaStreamSource;
let videoConstraints = {};

export default class WEBRTC extends React.Component {
  videoElement;
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: true,
      toggleSwitch1: false,
      toggleSwitch: false,
      toggleSwitch3: false,
      socketConnected: this.props.wrtcStreamStarted,
      webrtcBroadcastStart: this.props.wrtcStreamStarted,
      webrtcTabOpened:false,
      customActiveTab: "1",
      dycURL: "",
      cameraloaded: false,
      // galleryContent: [
      //   {
      //     id: "1",
      //     name: "Machine Working",
      //     videotype: "360",
      //     url: "https://d16qxmwlgqc3es.cloudfront.net/360demo/tdk_360demo.m3u8",
      //     streamtype: "url",
      //   },
      //   {
      //     id: "2",
      //     name: "Industrial",
      //     videotype: "360",
      //     url: "https://d16qxmwlgqc3es.cloudfront.net/360demo/tvs-lucas-360-jun2022.m3u8",
      //     streamtype: "url",
      //   },
      //   {
      //     id: "3",
      //     name: "Sample 360 Video",
      //     videotype: "360",
      //     url: "https://d16qxmwlgqc3es.cloudfront.net/360demo/hls4k/terrace-vid1.m3u8",
      //     streamtype: "url",
      //   },
      // ],
      galleryContent: [
        {
          id: "1",
          name: "Loading and Unloading",
          topic: "Overview",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/station1-overview.m3u8",
          streamtype: "url",
        },
        {
          id: "2",
          name: "Loading and Unloading",
          topic: "Assessment 1",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/assessment1.m3u8",
          streamtype: "url",
        },
        {
          id: "3",
          name: "Loading and Unloading",
          topic: "Function",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/station1-function.m3u8",
          streamtype: "url",
        },
        {
          id: "6",
          name: "Loading and Unloading",
          topic: "Assessment 2",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/assessment2.m3u8",
          streamtype: "url",
        },
        {
          id: "7",
          name: "Loading and Unloading",
          topic: "Procedure",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/station1-proc.m3u8",
          streamtype: "url",
        },
        {
          id: "8",
          name: "Loading and Unloading",
          topic: "Assessment 3",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/assessment3.m3u8",
          streamtype: "url",
        },
        {
          id: "9",
          name: "VR 360",
          topic: "Loading and unloading",
          videotype: "360",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/tdk-vr-360.m3u8",
          streamtype: "url",
        },
        {
          id: "10",
          name: "Loading and unloading machine",
          topic: "Hindi",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/station1-hin.m3u8",
          streamtype: "url",
        },
        {
          id: "11",
          name: "Mylar sticking machine",
          topic: "Hindi",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/mylar-hin.m3u8",
          streamtype: "url",
        },
        {
          id: "12",
          name: "Mylar sticking machine",
          topic: "Roll changing procedure",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/roller-mac.m3u8",
          streamtype: "url",
        },
        {
          id: "13",
          name: "Mylar sticking machine",
          topic: "Continuous roll changing procedure",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/roller-cont.m3u8",
          streamtype: "url",
        },
        {
          id: "14",
          name: "LucasTVS",
          topic: "Station 1",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/station1.m3u8",
          streamtype: "url",
        },
        {
          id: "15",
          name: "LucasTVS",
          topic: "VR 360 - Station 1",
          videotype: "360",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/station1-vr-360.m3u8",
          streamtype: "url",
        },
        {
          id: "16",
          name: "LucasTVS",
          topic: "VR - Practice - Station 1",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/station1-vr-inter.m3u8",
          streamtype: "url",
        },
        {
          id: "17",
          name: "LucasTVS",
          topic: "Station 2  Working",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/station2.m3u8",
          streamtype: "url",
        },
        {
          id: "18",
          name: "LucasTVS",
          topic: "Station 3 Working",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/station3.m3u8",
          streamtype: "url",
        },
        {
          id: "19",
          name: "AuditVista",
          topic: "",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/auditvista.m3u8",
          streamtype: "url",
        },
        {
          id: "20",
          name: "EyenstAIn",
          topic: "",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/eyenstain.m3u8",
          streamtype: "url",
        },
        {
          id: "21",
          name: "Remote 360 Inspection",
          topic: "",
          videotype: "flat",
          url: "https://d16qxmwlgqc3es.cloudfront.net/29-july-2022/360-remote-inspection.m3u8",
          streamtype: "url",
        },
            {
          id: "22",
          name: "Machine Working",
          topic: "",
          videotype: "360",
          url: "https://d16qxmwlgqc3es.cloudfront.net/360demo/tdk_360demo.m3u8",
          streamtype: "url",
        },
        {
          id: "23",
          name: "Industrial",
          topic: "",
          videotype: "360",
          url: "https://d16qxmwlgqc3es.cloudfront.net/360demo/tvs-lucas-360-jun2022.m3u8",
          streamtype: "url",
        },
        {
          id: "24",
          name: "Sample 360 Video",
          topic: "",
          videotype: "360",
          url: "https://d16qxmwlgqc3es.cloudfront.net/360demo/hls4k/terrace-vid1.m3u8",
          streamtype: "url",
        },
        
      ],
      liveurl: {
        id: "4",
        name: "Live video",
        videotype: "flat",
        url: "https://f53112b70bc31005.mediapackage.ap-south-1.amazonaws.com/out/v1/6fe83d2ac87744aa851d043d6476e754/index.m3u8",
        streamtype: "url",
      },
      webrtc: {
        id: "5",
        name: "WebRTC",
        videotype: "flat",
        url: "",
        streamtype: "webrtc",
      },
    };
    this.toggleCustom = this.toggleCustom.bind(this);
  }

  componentDidMount() {
    this.setState({ dataloaded: true });
  }

  toggleCustom(tab) {
    console.log("_.webrtcBroadcastStart", this.state.webrtcBroadcastStart)
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
        streamDisplay: tab,
      });
      if (tab === "3") {
        
        if (!this.state.webrtcBroadcastStart) {
          this.loadPlayer();
          this.setState({webrtcTabOpened:true})
          return
        } else {
          if (!this.state.webrtcBroadcastStart) {
            this.stopWebrtcRunning();
            this.setState({
              toggleSwitch1: false,
              toggleSwitch: false,
            });
          } else {
            if (!this.state.webrtcTabOpened) {
              this.setState({webrtcTabOpened:true})
              this.loadPlayer();
              if (this.state.webrtcBroadcastStart) {
                this.props.wbcConnection.close();
                this.props.webrtcStreamStarted(true)
                this.setState({
                  webrtcBroadcastStart: false,
                  socketConnected: false,
                });
                setTimeout(() => {
                  this.broadcastAgain();
                  this.props.webrtcStreamStarted(true)
                  this.setState({ webrtcBroadcastStart: true });
                }, 1000);
              }
            }
          }
        }
      }
      if (tab === "2") {
        var URL =
          "https://f53112b70bc31005.mediapackage.ap-south-1.amazonaws.com/out/v1/6fe83d2ac87744aa851d043d6476e754/index.m3u8";
        var video = document.getElementById("hlsVideo");
        if (Hls.isSupported()) {
          hls = new Hls({
            debug: false,
            enableWorker: false,
            lowLatencyMode: true,
            backBufferLength: 90,
            capLevelToPlayerSize: false,
            startLevel: -1,
          });
          hls.attachMedia(video);
          hls.on(Hls.Events.MEDIA_ATTACHED, function () {
            hls.loadSource(URL);
            this.setState({ getRelay: true });
          });
        }
      }
    }
  }

  loadPlayer() {
    this.loadWebRTC();
  }

  loadWebRTC = () => {
    let meetingid = this.props.meetingId;
    this.videoElement = document.getElementById("webrtcVideo");
    this.audioSelect = document.querySelector("select#audioSource");
    this.videoSelect = document.querySelector("select#videoSource");

    this.audioSelect.onChange = this.getStream;
    this.videoSelect.onChange = this.getStream;
    try {
      this.getStream()
        .then(this.getDevices)
        .then(this.gotDevices)
        .catch((error) => {
          console.log("error ---", error);
        });
    } catch (error) {
      console.log("get device error", error);
    }

    this.setState({ socketConnected: false });
  };

  getDevices() {
    return navigator.mediaDevices.enumerateDevices();
  }

  gotDevices = (deviceInfos) => {
    var alength = this.audioSelect.options.length;
    for (var i = alength - 1; i >= 0; i--) {
      this.audioSelect.options[i] = null;
    }
    var length = this.videoSelect.options.length;
    for (var j = length - 1; j >= 0; j--) {
      this.videoSelect.options[j] = null;
    }
    window.deviceInfos = deviceInfos;
    console.log("deviceInfos", deviceInfos);
    for (const deviceInfo of deviceInfos) {
      const option = document.createElement("option");
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === "audioinput") {
        option.text =
          deviceInfo.label || `Microphone ${this.audioSelect.length + 1}`;
        this.audioSelect.appendChild(option);
      } else if (deviceInfo.kind === "videoinput") {
        option.text =
          deviceInfo.label || `Camera ${this.videoSelect.length + 1}`;
        this.videoSelect.appendChild(option);
      }
    }
    this.setState({ cameraloaded: true });
  };

  getStream = async () => {
    console.log("window.stream", window.stream);
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    const audioSource = this.audioSelect.value;
    const videoSource = this.videoSelect.value;
    const constraints = {
      //audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      video: { deviceId: videoSource ? { exact: videoSource } : undefined },
    };
    videoConstraints = constraints;
    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then(this.gotStream)
      .catch(this.handleError);
  };

  gotStream = (stream) => {
    window.stream = stream;
    this.mediaStreamSource = stream;
    this.videoSelect.selectedIndex = [...this.videoSelect.options].findIndex(
      (option) => option.text === stream.getVideoTracks()[0].label
    );
    console.log("am in gotstream", this.videoElement);
    this.videoElement.srcObject = stream;
    this.videoElement.onloadedmetadata = () => {
      this.videoElement.play();
    };
  };

  handleError(error) {
    console.error("Error: ", error);
  }

  broadcastAgain() {
    console.log("this.state.socketConnected", this.state.socketConnected);
    if (!this.state.socketConnected) {
      socketConnection = socketIOClient.connect(
        "https://360api.autolearn.in/",
        { forceNew: true }
      );

      if (socketConnection) socketConnection.emit("join", this.props.meetingId);
      socketConnection.on("answer", (id, description) => {
        console.log("peer 1 restart", description);
        if (description.type !== "offer") {
          peerConnections[id].setRemoteDescription(description);
        }
      });

      socketConnection.on("watcher", (id) => {
        const peerConnection = new RTCPeerConnection(config);
        peerConnections[id] = peerConnection;

        let stream = this.videoElement.srcObject;
        stream
          .getTracks()
          .forEach((track) => peerConnection.addTrack(track, stream));

        peerConnection.onicecandidate = (event) => {
          if (event.candidate) {
            socketConnection.emit("candidate", id, event.candidate);
          }
        };

        peerConnection
          .createOffer()
          .then((sdp) => peerConnection.setLocalDescription(sdp))
          .then(() => {
            socketConnection.emit("offer", id, peerConnection.localDescription);
          });
      });

      socketConnection.on("candidate", (id, candidate) => {
        peerConnections[id].addIceCandidate(new RTCIceCandidate(candidate));
      });

      socketConnection.on("disconnectPeer", (id) => {
        if (peerConnections[id] !== undefined) {
          peerConnections[id].close();
          delete peerConnections[id];
        }
      });
      socketConnection.emit("broadcaster");
      this.setState({ socketConnected: true });
    }
  }

  broadcasting = () => {
    setTimeout(() => {
      console.log(
        "this.props.webrtcBroadcastStart",
        this.props.webrtcBroadcastStart
      );
      if (this.state.socketConnected) {
        socketConnection.emit("broadcaster");
        return;
      }
      if (this.state.webrtcBroadcastStart) {
        this.broadcastAgain();
      } else {
      }
    }, 1500);
  };

  stopWebrtcRunning() {
    if (!this.state.webrtcBroadcastStart) {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    }
  }

  changeVideoType = () => {
    var previewType = this.state.toggleSwitch ? "360" : "flat";
    this.state.liveurl["videotype"] = previewType;
  };

  changeWebRTCVideoType = () => {
    var previewType = this.state.toggleSwitch1 ? "360" : "flat";
    this.state.webrtc["videotype"] = previewType;
  };

  startSharing = (data) => {
    if (data.streamtype === "webrtc") {
      this.broadcastAgain();
      this.props.webrtcStreamStarted(true)
      this.setState({ webrtcBroadcastStart: true });
      this.props.webrtcSocketConnection(socketConnection);
    }
    this.props.startSharing(data);
  };

  stopSharing = () => {
    this.props.stopSharing();
    if (this.state.webrtcBroadcastStart && socketConnection) {
      socketConnection.close();
      this.props.webrtcStreamStarted(false)
      this.setState({
        webrtcBroadcastStart: false,
        socketConnected: false,
      });
    }
  };

  render() {
    if (this.state.dataLoaded) {
      const { parameter_type_data } = this.state;
      return (
        <div style={{ padding: "0 20px" }}>
          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.customActiveTab === "1",
                })}
                onClick={() => {
                  this.toggleCustom("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Gallery</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.customActiveTab === "2",
                })}
                onClick={() => {
                  this.toggleCustom("2");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">Live</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.customActiveTab === "3",
                })}
                onClick={() => {
                  this.toggleCustom("3");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-envelope"></i>
                </span>
                <span className="d-none d-sm-block">WebRTC</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            activeTab={this.state.customActiveTab}
            className="p-3 text-muted mt-3"
          >
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <Row style={{height:this.props.pageHeight, overflowY:"auto"}}>
                    {this.state.galleryContent.map((item, idx) => {
                      return (
                        <Col key={idx + "glry"}>
                          <div className="card border shadow-none mb-2">
                            <div className="p-2">
                              <div className="d-flex">
                                <div className="overflow-hidden me-auto word-wrap">
                                <h5 className="font-size-13 text-truncate mb-1">
                                    {item.name}
                                  </h5>
                                  <h5 className="font-size-13 text-truncate mb-1">
                                    {item.topic}
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    {item.videotype}
                                  </p>
                                </div>
                                <Button
                                  disabled={
                                    this.props.streamingStarted &&
                                    item.id !== this.props.sharedData.id
                                  }
                                  className="avatar-xs align-self-center me-2"
                                  color={
                                    this.props.streamingStarted &&
                                    item.id === this.props.sharedData.id
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() => {
                                    this.props.streamingStarted
                                      ? this.stopSharing()
                                      : this.startSharing(item);
                                  }}
                                >
                                  <div className="avatar-title rounded bg-transparent text-white font-size-20">
                                    <i
                                      className={
                                        this.props.streamingStarted &&
                                        item.id === this.props.sharedData.id
                                          ? "mdi mdi-stop-circle-outline"
                                          : "mdi mdi-play-circle-outline"
                                      }
                                    ></i>
                                  </div>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <div className="mb-3 col">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-12 col-form-label"
                    >
                      Live stream URL
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="text"
                        disabled
                        defaultValue="https://f53112b70bc31005.mediapackage.ap-south-1.amazonaws.com/out/v1/6fe83d2ac87744aa851d043d6476e754/index.m3u8"
                      />
                    </div>
                    <label className="col-md-10 font-size-10 text-secondary mt-2 text-wrap">
                      https://f53112b70bc31005.mediapackage.ap-south-1.amazonaws.com/out/{" "}
                      <br />
                      v1/6fe83d2ac87744aa851d043d6476e754/index.m3u8
                    </label>

                    {!this.state.getRelay ? (
                      <div className="mt-3" style={{ width: 300 }}>
                        <video
                          playsInline
                          autoPlay
                          muted
                          id="hlsVideo"
                          style={{ position: "relative", width: 275 }}
                        ></video>

                        <div className="form-check form-switch mt-3" dir="ltr">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch1"
                            disabled={this.props.streamingStarted}
                            onChange={() => {
                              this.setState(
                                {
                                  toggleSwitch: !this.state.toggleSwitch
                                    ? true
                                    : false,
                                },
                                () => {
                                  this.changeVideoType();
                                }
                              );
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitch1"
                          >
                            Enable 360
                          </label>
                        </div>
                        <div className="mb-3">
                          <span className="font-size-11">
                            Enable / disable 360 before start streaming
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="mt-3 d-flex flex-column align-items-center justify-content-center col-12 "
                        style={{ height: 200, backgroundColor: "grey" }}
                      >
                        <span className="text-white">Loading ... </span>
                      </div>
                    )}

                    <div className="mt-3">
                      <Button
                        color={
                          this.props.sharedData.id === "4" &&
                          this.props.streamingStarted
                            ? "danger"
                            : "success"
                        }
                        disabled={
                          this.props.sharedData.id !== "4" &&
                          this.props.streamingStarted
                        }
                        onClick={() => {
                          this.props.streamingStarted
                            ? this.stopSharing()
                            : this.startSharing(this.state.liveurl);
                        }}
                      >
                        {this.props.sharedData.id === "4" &&
                        this.props.streamingStarted
                          ? "Stop stream"
                          : "Start stream"}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col
                  sm="12"
                  style={{
                    backgroundColor: "#ffffff",
                    position: "absolute",
                    width: 300,
                  }}
                >
                  <div className="col-md-12">
                    <section class="select">
                      <label for="audioSource">Audio source: </label>
                      <select id="audioSource"></select>
                    </section>
                  </div>

                  <div className="mt-3 col-md-12">
                    <section class="select">
                      <label for="videoSource">Video source: </label>
                      <select
                        id="videoSource"
                        style={{ width: 275 }}
                        onChange={() => {
                          this.getStream();
                          if (this.state.webrtcBroadcastStart) {
                            socketConnection.close();
                            this.setState({
                              webrtcBroadcastStart: false,
                              socketConnected: false,
                            });
                            setTimeout(() => {
                              this.broadcastAgain();
                              this.setState({ webrtcBroadcastStart: true });
                            }, 1000);
                          }
                        }}
                      ></select>
                    </section>
                  </div>

                  <div className="mt-3" style={{ width: 300 }}>
                    <video
                      playsInline
                      autoPlay
                      muted
                      id="webrtcVideo"
                      style={{ position: "relative", width: 275 }}
                    ></video>
                  </div>
                  <div className="form-check form-switch mt-3" dir="ltr">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitch2"
                      disabled={this.props.streamingStarted}
                      onChange={() => {
                        this.setState(
                          {
                            toggleSwitch1: !this.state.toggleSwitch1
                              ? true
                              : false,
                          },
                          () => {
                            this.changeWebRTCVideoType();
                          }
                        );
                      }}
                    />
                    <label className="form-check-label" htmlFor="customSwitch2">
                      Enable 360
                    </label>
                  </div>
                  <div className="mb-3">
                    <span className="font-size-11">
                      Enable / disable 360 before start streaming
                    </span>
                  </div>

                  <div className="mt-3">
                    <Button
                      color={
                        this.props.sharedData.id === "5" &&
                        this.props.streamingStarted
                          ? "danger"
                          : "success"
                      }
                      disabled={
                        this.props.sharedData.id !== "5" &&
                        this.props.streamingStarted
                      }
                      onClick={() => {
                        this.props.streamingStarted
                          ? this.stopSharing()
                          : this.startSharing(this.state.webrtc);
                      }}
                    >
                      {this.props.sharedData.id === "5" &&
                      this.props.streamingStarted
                        ? "Stop stream"
                        : "Start stream"}
                    </Button>
                  </div>
                </Col>
                <div
                  style={{
                    display: this.state.cameraloaded ? "none" : "flex",
                    height: "68vh",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    width: 300,
                    backgroundColor: "rgba(255,255,255,0.8)",
                  }}
                >
                  <div
                    className="spinner-border text-success m-1 font-size-15 "
                    role="status"
                  >
                    <span className="sr-only text-secondary">Loading...</span>
                  </div>
                </div>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      );
    } else {
      return null;
    }
  }
}
