import React, { Component, createRef, useState, useRef } from "react";
import { useScreenshot } from "use-react-screenshot";
import html2canvas from "html2canvas";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Media,
} from "reactstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";

import classnames from "classnames";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import ReactDrawer from "react-drawer";
import moment from "moment";
import useKrpano from "react-krpano-hooks";

import Hls from "hls.js";
import uuid from "react-uuid";

import Imageproessing from "./imageprocess";
import OBRImageEditor from "./obrImageEditor";
import OBRList from "./observationList";

import KRPanoControls from "./krpanoControls";

import Report from "../report/reportComponent"

//import MaterialIcon from 'material-design-icons';

interface IProps {}
interface IState {
  emails: string[];
}

const _ = require("lodash");
const $ = require("jquery");

const FileSaver = require("file-saver"); //to download edited image to local. Use after npm install file-saver
//const blackTheme = require('./js/theme/black-theme.js');

const Theme = {};

const imageEditorOptions = {
  includeUI: {
    loadImage: {
      path: "img/sampleImage.jpg",
      name: "SampleImage",
    },
    theme: {},
    menu: ["shape", "draw", "text"],
    initMenu: "shape",
    uiSize: {
      width: "75%",
      height: "100%",
    },
    menuBarPosition: "left",
  },
  cssMaxHeight: 500,
  cssMaxWidth: 700,
  selectionStyle: {
    cornerSize: 20,
    rotatingPointOffset: 70,
  },
  usageStatistics: false,
};

const krtour = "../../../public/krpano/tour.xml";
var getImage;

var urlSocket = require("../../helpers/urlSocket");

var hls = new Hls({
  debug: false,
  enableWorker: false,
  lowLatencyMode: true,
  backBufferLength: 90,
  capLevelToPlayerSize: false,
  startLevel: -1,
});

const KrpanoExample = () => {
  const { containerRef } = useKrpano({
    height: "77.5vh",
    width: "100%",
    pointerEvents: "auto",
  });

  return (
    <div>
      <div
        ref={containerRef}
        style={{ position: "relative" }}
        className="custom-styles"
      />
    </div>
  );
};

var ws;
var WebSocketsExist;
var krpano;
var WebSocketsConnected = false;
var sndmsgInterval;

class Hoster extends Component {
  domain = "jitsi.autolearn.in";
  api = {};
  iframe;

  editorRef = React.createRef();
  obreditor = React.createRef();
  scrollbox = React.createRef();
 

  constructor(props) {
    super(props);
    this.state = {
      department_id: null,
      department_Name: null,
      isSocketConnected: false,
      videoPlaying: false,
      audioMute: true,
      height: window.innerHeight,
      krpanoSyncStopped: true,
      started360: false,
      isAudioMuted: false,
      isVideoMuted: false,
      krpanoloaded: false,
      particsipantsIDs: [],
      partiId: "",
      jitsiloaded: false,
      obInfo: {},
      mandc: null,
      drawOpen: false,
      messages: [],
      chatText: "",
      ptList: [],
      tileViewMode: "filmstrip",
      emails: [],
      inviteOver: true,
    };

    this. _scrollBarRef = null
    this.intervalSendMessage = this.intervalSendMessage.bind(this);
    this.tog_fullscreen = this.tog_fullscreen.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.tog_addprtPanel = this.tog_addprtPanel.bind(this);
    this.tog_report = this.tog_report.bind(this);
    this.initialWebSocket();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_fullscreen() {
    this.setState((prevState) => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));
    this.removeBodyCss();
  }

  tog_center() {
    this.setState((prevState) => ({
      modal_center: !prevState.modal_center,
    }));
    this.removeBodyCss();
  }

  tog_addprtPanel() {
    this.setState((prevState) => ({
      addprtPanel: !prevState.addprtPanel,
    }));
    this.removeBodyCss();
  }

  tog_report() {
    this.setState((prevState) => ({
      reportModal: !prevState.reportModal,
    }));
    this.removeBodyCss();
  }

  onDrawerClose() {
    this.setState({ drawOpen: false, obrlistEnabled: false });
  }

  scrollToBottom = () => {
    const curr = this.scrollbox.current
    if (curr) {
      setTimeout(() => {
        curr.scrollTop = this.scrollbox.current.scrollHeight
      }, 100);
      
    }
}

  componentDidMount() {
    var meetingInfo = JSON.parse(sessionStorage.getItem("usermeetingInfo"));
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var confPlayerControl = JSON.parse(
      sessionStorage.getItem("confPlayerControl")
    );

    console.log("confPlayerControl", confPlayerControl);

    this.setState(
      {
        userdata: data,
        userInfo: data.user_data,
        configData: data.config_data,
        meetingInfo,
        meeting_id: meetingInfo._id,
        confPlayerControl,
        isAudioMuted: confPlayerControl.isAudioMuted,
        isVideoMuted: confPlayerControl.isVideoMuted,
        display_name: confPlayerControl.displayInfo.displayname,
        dataloaded: true,
        imagePreviewUrl: data.config_data.img_url,
        imageToken: data.config_data.img_url,
      },
      () => {
        if (window.JitsiMeetExternalAPI) {
          this.startMeet();
          setTimeout(() => {
            this.setMeetingStart();
          }, 1000);
        } else {
          alert("JitsiMeetExternalAPI not loaded");
        }
      }
    );

    window.addEventListener("cancel", (event) => {
      console.log("dialog was canceled");
    });
  }

  setMeetingStart() {
    var meetingStatus = {
      status: "started",
    };

    console.log("message sending");

    ws.send(JSON.stringify(meetingStatus));
  }

  krpanoSyncStartStop() {
    var meeting = {};
    if (this.state.krpanoSyncStopped) {
      this.setState({ krpanoSyncStopped: false });
      this.syncPlayer();
    } else {
      this.setState({ krpanoSyncStopped: true });
      meeting = {
        krpanoSyncing: "stop",
      };
      clearInterval(sndmsgInterval);
      ws.send(JSON.stringify(meeting));
    }
  }

  async syncPlayer() {
    sndmsgInterval = setInterval(() => {
      if (WebSocketsConnected) {
        this.intervalSendMessage();
      }
    }, 50);
  }

  intervalSendMessage = () => {
    krpano = document.getElementById("krpanoSWFObject");

    if (krpano && krpano.get) {
      var hlookat = krpano.get("view.hlookat");
      var vlookat = krpano.get("view.vlookat");
      var fov = krpano.get("view.fov");
      var meeting = {
        krpanoSyncing: "start",
        krObj: {
          hlookat: hlookat,
          vlookat: vlookat,
          fov: fov,
        },
      };

      ws.send(JSON.stringify(meeting));
    }
  };

  onParticipantJoined(info) {
    var particsipantsIDs = this.state.particsipantsIDs;
    var getIndex = particsipantsIDs.indexOf(info.participantId);
    if (getIndex === -1) {
      this.setState({
        particsipantsIDs: particsipantsIDs.concat([info.participantId]),
        partiId: info.participantId,
      });
    }

    if (this.state.krpanoloaded) {
      krpano = document.getElementById("krpanoSWFObject");
      if (krpano && krpano.get) {
        var hlookat = krpano.get("view.hlookat");
        var vlookat = krpano.get("view.vlookat");
        var fov = krpano.get("view.fov");
        var krloaded = {
          participant: "joining",
          participantId: getIndex === -1 ? info.participantId : null,
          krpano: this.state.krpanoloaded ? "loaded" : "unloaded",
          krpanoSyncing: this.state.krpanoSyncStopped ? "stop" : "start",
          krpanning: this.state.krpanning,
          krObj: {
            hlookat: hlookat,
            vlookat: vlookat,
            fov: fov,
          },
        };
        ws.send(JSON.stringify(krloaded));
      } else {
        var krnotloaded = {
          participant: "joining",
          participantId: getIndex === -1 ? info.participantId : null,
        };
        ws.send(JSON.stringify(krnotloaded));
      }
    }
  }

  startStopKrpano = () => {
    if (this.state.krpanoloaded) {
      this.setState({ krpanoloaded: false, krpanoSyncStopped: true });
      var krObj2 = {
        krpano: "unloaded",
        participant: "joined",
        participantId: this.state.partiId,
      };
      clearInterval(sndmsgInterval);
      ws.send(JSON.stringify(krObj2));
    } else {
      console.log("this.state.participantId", this.state.partiId);
      this.setState({ krpanoloaded: true });
      var krObj = {
        krpano: "loaded",
        participant: "joined",
        participantId: this.state.partiId,
      };
      ws.send(JSON.stringify(krObj));
    }
  };

  vrmode() {
    krpano = document.getElementById("krpanoSWFObject");
    if (krpano && krpano.get) {
      krpano.call("webvr.enterVR();");
    }
  }

  async initialWebSocket() {
    console.log("am reloaded");
    if (!WebSocketsConnected) {
      WebSocketsExist = true;
      try {
        ws = new WebSocket("wss://autolearn.in/demo360ws/");
        //ws = new WebSocket("ws://localhost:7071/");
      } catch (ex) {
        try {
          //  ws = new MozWebSocket("ws://localhost:7071/");
        } catch (ex) {
          WebSocketsExist = false;
        }
      }
      if (WebSocketsExist) {
        console.log("The current browser support websocket!!!");
      } else {
        console.log("The current browser not support websocket!");
        return;
      }
      ws.onopen = this.WSonOpen;
      ws.onmessage = this.WSonMessage;
      ws.onclose = this.WSonClose;
      ws.onerror = this.WSonError;
    }
  }

  WSonOpen() {
    console.log("websocket opened success!");
    WebSocketsConnected = true;
  }

  WSonMessage = (event) => {
    var krObj = JSON.parse(event.data);
    var hlookat = krObj.hlookat;
    var vlookat = krObj.vlookat;
    var fov = krObj.fov;

    krpano = document.getElementById("krpanoSWFObject");

    var krSyncButton = document.getElementById("krSync");

    if (krObj.sync === "stop") {
      if (krpano && krpano.get) {
        krpano.set("view.hlookat", hlookat);
        krpano.set("view.vlookat", vlookat);
        krpano.set("view.fov", fov);
        krpano.set("view.continuousupdates", "true");
        krpano.set("view.devices", "html5");
        krpano.call("set(control.usercontrol,all)");
        this.setState({
          krpanoSyncStopped: true,
        });
        krSyncButton.disabled = false;
      }
    } else {
      if (krpano && krpano.get) {
        krpano.set("view.hlookat", hlookat);
        krpano.set("view.vlookat", vlookat);
        krpano.set("view.fov", fov);
        krpano.set("view.continuousupdates", "true");
        krpano.set("view.devices", "html5");
        krpano.call("set(control.usercontrol,off)");
        krSyncButton.disabled = true;
      }
    }
  };

  WSonClose() {
    console.log("Websocket closed.");
  }

  WSonError() {
    console.log("Websocket error occur.");
  }

  //Krpano interfacee controls

  krpanoControls(control) {
    krpano = document.getElementById("krpanoSWFObject");
    if (krpano && krpano.get) {
      //zoom in zoom out
      if (control === "zoomInDown") {
        krpano.set("fov_moveforce", -1);
      }
      if (control === "zoomOutDown") {
        krpano.set("fov_moveforce", +1);
      }
      if (control === "zoomInOutUp") {
        krpano.set("fov_moveforce", 0);
      }

      //zoom in left right pan
      if (control === "leftkeyDown") {
        krpano.set("hlookat_moveforce", -1);
      }
      if (control === "rightkeyDown") {
        krpano.set("hlookat_moveforce", +1);
      }
      if (control === "leftRightkeyUp") {
        krpano.set("hlookat_moveforce", 0);
      }

      //zoom in top bottom pan
      if (control === "topkeyDown") {
        krpano.set("vlookat_moveforce", -1);
      }
      if (control === "bottomkeyDown") {
        krpano.set("vlookat_moveforce", +1);
      }
      if (control === "topBottomkeyUp") {
        krpano.set("vlookat_moveforce", 0);
      }

      if(control === "normalview") {
        krpano.call('skin_view_normal()')
      }
      if(control === "flatview") {
        krpano.call('skin_view_flat()')
      }
      if(control === "fisheyeview") {
        krpano.call('skin_view_fisheye()')
      }
      if(control === "stereoview") {
        krpano.call('skin_view_stereographic()')
      }
      if(control === "archview") {
        krpano.call('skin_view_architectural()')
      }
      if(control === "panniniview") {
        krpano.call('skin_view_pannini()')
      }
      if(control === "ltplanetview") {
        krpano.call('skin_view_littleplanet()')
      }
    }
  }

  //Krpano interfacee controls
  togglePlay() {
    krpano = document.getElementById("krpanoSWFObject");
    var video = krpano.get("plugin[video]").videoDOM;
    if (video.paused == false) {
      this.setState({ videoPlaying: false });
      video.pause();
    } else {
      this.setState({ videoPlaying: true });
      video.play();
    }
    krpano.set("view.continuousupdates", "true");
  }

  toggleMute() {
    krpano = document.getElementById("krpanoSWFObject");
    var video = krpano.get("plugin[video]").videoDOM;

    if (video.volume == 1) {
      this.setState({ audioMute: false });
      video.volume = 0;
    } else {
      this.setState({ audioMute: true });
      video.volume = 1;
    }

    krpano.set("view.continuousupdates", "true");
  }

  startMeet = () => {
    const options = {
      roomName: this.state.meetingInfo.code,
      width: "100%",
      height: this.state.height - 230,
      configOverwrite: {
        prejoinPageEnabled: false,
        disableSelfView: false,
        startWithVideoMuted: this.state.isVideoMuted,
        startWithAudioMuted: this.state.isAudioMuted,
      },
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        DISABLE_VIDEO_BACKGROUND: true,
        SHOW_BRAND_WATERMARK: false,
        SHOW_JITSI_WATERMARK: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: true,
        TOOLBAR_BUTTONS: [],
      },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName: this.state.display_name,
      },
    };
    this.api = new window.JitsiMeetExternalAPI(this.domain, options);
    this.api.addListener("videoConferenceJoined", () => {
      if (this.state.tileViewMode !== "tileview") {
        this.api.executeCommand("toggleTileView");
      }
      this.setState({ jitsiloaded: true });
    });

    this.api.addListener("incomingMessage", this.incomingMessageFunction);
    this.api.addListener("outgoingMessage", this.outgoingMessageFunction);

    this.api.contentSharingParticipantsChanged = (participant) => {
      console.log("res ====-===", participant);
      var contentSharingInfo = participant[0]
      var getUser = _.find(this.state.ptList, {participantId:contentSharingInfo.id})
      console.log("getUser", getUser)
      this.setState({screenShareUserInfo:getUser})
      //res.sharingParticipantIds = [particId1, particId2, ...]
    };

    this.api.addEventListeners({
      readyToClose: this.handleClose,
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      audioMuteStatusChanged: this.handleMuteStatus,
      videoMuteStatusChanged: this.handleVideoStatus,
      screenSharingStatusChanged: this.ssscFunction,
    });
  };

  handleClose = () => {
    console.log("handleClose");
  };

  handleParticipantLeft = async (participant) => {
    console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    const data = await this.getParticipants();
    var info = data[data.length - 1];
    this.setState({ ptList: data });
    this.onParticipantJoined(info);
  };

  handleVideoConferenceJoined = async (participant) => {
    console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    const data = await this.getParticipants();
    this.setState({
      partiId: participant.id,
      particsipantsIDs: this.state.particsipantsIDs.concat([participant.id]),
    });
  };

  handleVideoConferenceLeft = () => {
    console.log("handleVideoConferenceLeft");
    return;
  };

  handleMuteStatus = (audio) => {
    console.log("handleMuteStatus", audio); // { muted: true }
  };

  handleVideoStatus = (video) => {
    console.log("handleVideoStatus", video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  getParticipantList() {
    console.log("ptList", this.state.ptList);
    this.setState({
      drawOpen:
        !this.state.drawOpen && !this.state.ptListOpen
          ? true
          : this.state.drawOpen && this.state.chatOpen
          ? true
          : this.state.drawOpen && this.state.obrlistEnabled
          ? true
          : false,
      ptListOpen: this.state.ptListOpen ? false : true,
      obrlistEnabled: false,
      chatOpen: false,
    });
  }

  openChatBox() {
    this.setState({
      drawOpen:
        !this.state.drawOpen && !this.state.chatOpen
          ? true
          : this.state.drawOpen && this.state.obrlistEnabled
          ? true
          : this.state.drawOpen && this.state.ptListOpen
          ? true
          : false,
      chatOpen: this.state.chatOpen ? false : true,
      obrlistEnabled: false,
      ptListOpen: false,
    });
  }

  openOBRBox() {
    this.setState({
      drawOpen:
        !this.state.drawOpen && !this.state.obrlistEnabled
          ? true
          : this.state.drawOpen && this.state.chatOpen
          ? true
          : this.state.drawOpen && this.state.ptListOpen
          ? true
          : false,
      obrlistEnabled: this.state.obrlistEnabled ? false : true,
      chatOpen: false,
      ptListOpen: false,
    });
  }

  outgoingMessageFunction = (e) => {
    console.log("The message sent");
    console.log(e);
    var CurrentDate = moment().format("hh:mm");
    this.setState({
      messages: this.state.messages.concat([
        {
          message: e.message,
          bounding: "outgoing",
          type: e.message.type,
        }
      ]),
    });
  };

  incomingMessageFunction = (e) => {
    console.log("incomingMessageReceived");
    console.log(e);
    var CurrentDate = moment().format("hh:mm");
    this.setState({
      messages: this.state.messages.concat([
        {
          message: e.message,
          name: e.nick,
          bounding: "incoming",
          //type:e.message.type
        }
      ]),
    }, ()=>{ this.scrollToBottom()});
  };

  handleKeyDown(e) {
    console.log("key", e);
    if (e.key === "Enter") {
      if (String(this.state.chatText).length > 0) {
        this.sendChatMessage(this.state.chatText);
        
        this.setState({ chatText: "" });
      }
    }
  }

  ssscFunction = (e) => {
    console.log("screen sharing status", e);
    //this.setState({jitsiloaded : this.state.jitsiloaded ? false : true})
  };

  sendOBRNotification(data) {
    var msg = "New Observation Added - " + data.title;
    var option = {
      message: msg,
      type: "1",
    };
    console.log(msg);
    this.api.executeCommand("sendChatMessage", msg);
    var obrInfo = {
      obrAdded: true,
    };
    ws.send(JSON.stringify(obrInfo));
  }

  sendChatMessage() {
    var msg = $("#chatMsgInput").val();
    var option = {
      message: msg,
      type: "0",
    };
    this.scrollToBottom()
    this.api.executeCommand("sendChatMessage", msg);
  }

  // custom events
  executeCommand(command) {
    this.api.executeCommand(command);
    if (command == "hangup") {
      this.closeMeeting();
      return;
    }

    if (command == "toggleAudio") {
      this.setState({ isAudioMuted: this.state.isAudioMuted ? false : true });
    }

    if (command == "toggleVideo") {
      this.setState({ isVideoMuted: this.state.isVideoMuted ? false : true });
    }

    if (command == "toggleFilmStrip") {
      this.setState({ tglFilmStrip: this.state.tglFilmStrip ? false : true });
    }

    if (command == "toggleTileView") {
      this.setState({
        tileViewMode:
          this.state.tileViewMode === "tileview" ? "filmstrip" : "tileview",
      });
    }

    if (command === "toggleShareScreen") {
      this.setState({
        jitsiloaded: this.state.jitsiloaded ? false : true,
      });
    }
    if (command === "muteEveryone") {
      this.setState({ meone: this.state.meone ? false : true });
    }
  }

  toggleLargeVideoContainer() {
    if (this.state.tileViewMode !== "tileview") {
      this.api.executeCommand("toggleTileView");
    }
    var msg = "largeVideoContainer";
    const frame = document.getElementsByTagName("iframe")[0].contentWindow;
    frame.postMessage(msg, "https://jitsi.autolearn.in");
    this.startStopKrpano();
  }

  closeMeeting() {
    try {
      urlSocket
        .post("mngmtg/meetingend", {
          meetingInfo: this.state.meetingInfo,
        })
        .then((response) => {
          if (response.data.response_code === 500) {
            this.setState({ krpanoloaded: false });
            var krObj = {
              status: "finished",
              mtInfo: response.data.data,
            };

            setTimeout(() => {
              ws.send(JSON.stringify(krObj));
              ws.close();
              WebSocketsConnected = false;
              this.props.history.push("/lvmtng");
            }, 500);
          }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  //======== change video source ==================

  changeVideo(URL) {
    hls.destroy();
    krpano = document.getElementById("krpanoSWFObject");
    if (krpano && krpano.get) {
      var video = krpano.get("plugin[video]").videoDOM;
      if (Hls.isSupported()) {
        hls = new Hls({
          debug: false,
          enableWorker: false,
          lowLatencyMode: true,
          backBufferLength: 90,
          capLevelToPlayerSize: false,
          startLevel: -1,
        });
        hls.attachMedia(video);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          hls.loadSource(URL);
        });
      }
      video.play();

      var krObj = {
        krpano: "loaded",
        videoURL: URL,
      };
      ws.send(JSON.stringify(krObj));
      // video.pause()
    }
  }

  //================================================

  takeScreenShot = () => {
    krpano = document.getElementById("krpanoSWFObject");
    krpano.call("makescreenshot()");
    var img_src = krpano.get("layer[ssimageURL]");
    this.setState({ snshot: img_src.url });
    this.setState({ obContentLoaded: true });
    this.tog_center();
    krpano.call("removelayer(ssimageURL,true)");
  };

  handleValidSubmit(event, values) {
    var image_info = Imageproessing.convertBaseToURL(this.state.snshot);

    const img = new FormData();
    let u_id = uuid();

    img.append("image", image_info, u_id + "_obr.png");
    console.log(u_id + "_obr.png");

    urlSocket.post("storeImage/awswebupload", img).then((res) => {
      if (res.status == 200) {
        console.log(res);
        let splitString = res.data.data[0].key.split("/");
        let getFileName = splitString[splitString.length - 1];
        console.log("getFileName", getFileName);
        //this.setState({ up_sign_url: String(u_id) + 'sign.png' })
        // console.log("uploaded _sign", res)
        this.createObservation(u_id, getFileName, values);
      }
    });

    this.setState({ imageLoading: true });
  }

  createObservation(u_id, getFileName, values) {
    this.setState({ obrTitle: values.title });
    try {
      urlSocket
        .post("mngobvrs/createobservations", {
          meetingInfo: this.state.meetingInfo,
          observationInfo: values,
          userInfo: this.state.userInfo,
          imageInfo: {
            u_id: u_id,
            filename: getFileName,
          },
        })
        .then((response) => {
          if (response.data.response_code === 500) {
            console.log("error", response.data.data);
            this.setState({
              obContentLoaded: false,
              imageLoading: false,
              mandc: null,
              obInfo: response.data.data,
            });
            this.tog_center();
            this.tog_fullscreen();
            this.sendOBRNotification(values);
          }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  loadImageEditor(obInfo, mandc) {
    console.log("image editor");
    this.setState({
      obInfo: obInfo,
      mandc,
      commentText: mandc === undefined ? "" : mandc.muser_comment,
      //drawOpen: false,
      obrTitle: obInfo.title,
    });
    this.tog_fullscreen();
  }

  convertAsImage() {
    this.setState({ savingImage: true });
    var base64Data = this.obreditor.current.getImageBase64();
    var image_info = Imageproessing.convertBaseToURL(base64Data);
    const img = new FormData();
    let u_id;

    if (this.state.mandc === null || this.state.mandc === undefined) {
      u_id = uuid();
    } else {
      u_id = this.state.mandc.u_id;
    }

    img.append("image", image_info, u_id + "_obr.png");
    console.log(u_id + "_obr.png");

    urlSocket.post("storeImage/awswebupload", img).then((res) => {
      if (res.status == 200) {
        console.log(res);
        let splitString = res.data.data[0].key.split("/");
        let getFileName = splitString[splitString.length - 1];
        console.log("getFileName", getFileName);
        this.saveOBRComments(u_id, getFileName);
      }
    });
  }

  saveOBRComments(u_id, getFileName) {
    var getAllShapes = this.obreditor.current.onSaveObservation();
    if (this.state.mandc !== null && this.state.mandc !== undefined) {
      this.state.mandc["objects"] = getAllShapes;
      this.state.mandc["muser_comment"] = this.state.commentText;
    }
    try {
      this.state.userInfo["display_name"] = this.state.display_name;
      urlSocket
        .post("mngobvrs/createobrmarkscmnts", {
          meetingInfo: this.state.meetingInfo,
          observationInfo: this.state.obInfo,
          userInfo: this.state.userInfo,
          userRole: "host",
          marksAndComments:
            this.state.mandc === null || this.state.mandc === undefined
              ? {
                  muser_comment: this.state.commentText,
                  objects: getAllShapes,
                  u_id: u_id,
                  file_name: getFileName,
                }
              : this.state.mandc,
        })
        .then((response) => {
          if (response.data.response_code === 500) {
            this.setState({ savingImage: false });
            //this.tog_fullscreen();
          }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  invitePeople = () => {
    var url = window.location.protocol + "//" + window.location.hostname;
    //var url =  window.location.protocol+'//'+window.location.hostname+":"+window.location.port

    var isemailEmpty = this.state.emails.length > 0 ? false : true;
    var meetingParticipants = _.map(
      this.state.meetingInfo.participants,
      "email_id"
    );
    var filteredMails = this.state.emails.filter(
      (val) => !meetingParticipants.includes(val)
    );

    console.log("meetingParticipants", this.state.meetingInfo, filteredMails);
    if (!isemailEmpty) {
      if (filteredMails.length > 0) {
        this.setState({ inviteOver: false });
        var values = {
          meetingname: this.state.meetingInfo.mname,
          description: this.state.meetingInfo.description,
          dateTime: this.state.meetingInfo.scheduled_on,
          enddatetime: this.state.meetingInfo.ends_on,
          duration: this.state.meetingInfo.duration,
          _id: this.state.meetingInfo._id,
        };

        try {
          urlSocket
            .post("mngmtg/addpeople", {
              meetingInfo: values,
              emails: filteredMails,
              userInfo: {
                _id: this.state.userInfo._id,
                username: this.state.userInfo.first_name,
                email_id: this.state.userInfo.general_info[0].email_id,
                company_id: this.state.userInfo.company_id,
              },
              config_data: this.state.configData,
              meetingURL: this.state.meetingInfo.meeting_url,
            })
            .then((response) => {
              console.log("responde", response.data.data);
              sessionStorage.removeItem("usermeetingInfo");
              sessionStorage.setItem(
                "usermeetingInfo",
                JSON.stringify(response.data.data)
              );
              var meetingInfo = response.data.data;
              this.setState({
                meetingInfo,
                meeting_id: meetingInfo._id,
                inviteOver: true,
              });
              this.tog_addprtPanel();
            });
        } catch (error) {
          console.log("catch error", error);
        }
      } else {
        this.setState({
          isemailError: true,
          inviteError: "Mail already sent for those people",
        });
      }
    } else {
      this.setState({
        isemailEmpty,
        isemailError: true,
        inviteError: "Minimum one participant should be added",
      });
    }
  };

  openReport = () => {
    var info
    if(this.state.isReportShared)
    {
        info={reportEnabled:true}
        ws.send(JSON.stringify(info));
    }
    else
    {
      info={reportEnabled:false}
      ws.send(JSON.stringify(info));
    }
  }
  

  render() {
    if (this.state.dataloaded) {
      const { emails } = this.state;
      const { isAudioMuted, isVideoMuted } = this.state;
      return (
        <React.Fragment>
          <div
            className="page-content"
            style={{ minHeight: "100vh", overflow: "hidden !important" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 5,
                alignItems: "center",
              }}
            >
              <div style={{ width: "80%", padding: "0 20px" }}>
                <h5>{this.state.meetingInfo.mname}</h5>
              </div>
            </div>
            <Container fluid style={{ overflow: "hidden !important" }}>
              {/* <Breadcrumbs title="" breadcrumbItem="Dashboard" /> */}

              <div className="w-100">
                <Row
                  style={{
                    position: "relative",
                    overflow: "hidden !important",
                  }}
                >
                  {/* ----------- Left tab --------------- */}
                  <div
                    style={{
                      width: this.state.drawOpen
                        ? "calc(100% - 360px)"
                        : "100%",
                      transition: "width .35s ease-in-out",
                    }}
                  >
                    <div style={{ backgroundColor: "#292929" }}>
                      <div
                        style={{
                          height: this.state.height - 220,
                          overflow: "auto",
                          position: "relative",
                        }}
                      >
                        {/* --------- Jitsi Frame -------------- */}
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            zIndex: 1000,
                            right: 0,
                            pointerEvents: "none",
                            overflow: "hidden",
                          }}
                        >
                          <div id="jitsi-iframe" ref={"jit"}></div>
                        </div>

                        {/* --------- KRPano -------------- */}
                        {this.state.started360 ? (
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                height: "fit-content",
                                position: "absolute",
                                zIndex: 1025,
                                left: 10,
                                top: 10,
                              }}
                            >
                              <span
                                className="badge d-inline-block me-2"
                                style={{ backgroundColor: "#ff0303" }}
                              >
                                <i className="mdi mdi-circle text-size-18 text-white me-1"></i>
                                Live Streaming
                              </span>
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 1030,
                                bottom: 20,
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <KRPanoControls
                                togglePlay={() => this.togglePlay()}
                                toggleMute={() => this.toggleMute()}
                                krpanoControls={(data) =>
                                  this.krpanoControls(data)
                                }
                                krpanoSyncStartStop={() =>
                                  this.krpanoSyncStartStop()
                                }
                                vrmode={() => this.vrmode()}
                                takeScreenShot={() => this.takeScreenShot()}
                                videoPlaying={this.state.videoPlaying}
                                audioMute={this.state.audioMute}
                                krpanoSyncStopped={this.state.krpanoSyncStopped}
                              />
                            </div>
                            <KrpanoExample />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* ----------- Right tab --------------- */}
                  <div
                    style={{
                      width: 360,
                      right: this.state.drawOpen ? 0 : -380,
                      position: "absolute",
                      borderLeft: "1px #eff2f7 solid",
                      backgroundColor: "#fff",
                      padding: "0 10px",
                      height: this.state.height - 200,
                      transition: "right .35s ease-in-out",
                    }}
                  >
                    {this.state.chatOpen ? (
                      <div className="w-100 user-chat">
                        <Card style={{ marginBottom: 0, padding: 0 }}>
                          <div
                            style={{
                              borderBottom: "1px solid #dadada",
                              paddingBottom: 10,
                            }}
                          >
                            <span className="card-title mt-0 font-size-17 fw-light">
                              Conversation
                            </span>
                            <button
                              onClick={() =>
                                this.setState({
                                  drawOpen: false,
                                  chatOpen: false,
                                  ptListOpen: false,
                                  obrlistEnabled: false,
                                })
                              }
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              style={{
                                fontSize: 15,
                                padding: "0.78rem 1.29rem",
                              }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div>
                            <div className="chat-conversation p-3 mb-0">
                              <ul className="list-unstyled mb-0">
                                <PerfectScrollbar
                                  id="chatMsgContainer"
                                  containerRef={el => (this.scrollbox.current = el)}
                                  style={{
                                    height: this.state.height - 350,
                                    minHeight: this.state.height - 350,
                                  }}
                                >
                                  {this.state.messages.map((item) => {
                                    return (
                                      <li
                                        className={
                                          item.bounding === "incoming"
                                            ? " "
                                            : "right"
                                        }
                                      >
                                        <div className="conversation-list mb-1">
                                          <div
                                            className="ctext-wrap mb-0"
                                            style={{
                                              padding: "8px 12px",
                                            }}
                                          >
                                            <div className="conversation-name text-dark font-size-10 mb-0">
                                              {item.name}{" "}
                                              {moment().format("hh:mm")}
                                            </div>
                                            <span className="mb-1 font-size-11">
                                              {item.message}
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </PerfectScrollbar>
                              </ul>
                            </div>
                            <div className="py-3 px-1 chat-input-section">
                              <Row>
                                <Col>
                                  <div className="position-relative">
                                    <input
                                      id="chatMsgInput"
                                      type="text"
                                      className="form-control chat-input"
                                      placeholder="Enter Message..."
                                      onKeyDown={this.handleKeyDown}
                                      onChange={(event) => {
                                        this.setState({
                                          chatText: event.target.value,
                                        });
                                      }}
                                      value={this.state.chatText}
                                      style={{
                                        paddingRight: 70,
                                      }}
                                    />
                                    <div
                                      className="chat-input-links"
                                      style={{ right: 3 }}
                                    >
                                      <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="btn-sm btn-rounded chat-send "
                                            onClick={() => {
                                              if (
                                                String(this.state.chatText)
                                                  .length > 0
                                              ) {
                                                this.sendChatMessage(
                                                  this.state.chatText
                                                );
                                                this.setState({ chatText: "" });
                                              }
                                            }}
                                          >
                                            <i className="mdi mdi-send"></i>
                                          </Button>
                                        </li>{" "}
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Card>
                      </div>
                    ) : this.state.obrlistEnabled ? (
                      <div
                        style={{
                          width: 360,
                          right: this.state.drawOpen ? 0 : -380,
                          position: "absolute",
                          borderLeft: "1px #eff2f7 solid",
                          backgroundColor: "#fff",
                          padding: "0 10px",
                          height: this.state.height - 220,
                          transition: "right .35s ease-in-out",
                        }}
                      >
                        <div
                          style={{
                            borderBottom: "1px solid #dadada",
                            paddingBottom: 10,
                            marginBottom: 10,
                          }}
                        >
                          <span className="card-title mt-0 font-size-17 fw-light">
                            Observations
                          </span>
                          <button
                            onClick={() =>
                              this.setState({
                                drawOpen: false,
                                chatOpen: false,
                                ptListOpen: false,
                                obrlistEnabled: false,
                              })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style={{ fontSize: 15, padding: "0.78rem 1.29rem" }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="w-100 user-chat">
                          {this.state.obrlistEnabled ? (
                            <OBRList
                              meetingInfo={this.state.meetingInfo}
                              userInfo={this.state.userInfo}
                              loadImageEditor={(data, mc) => {
                                this.loadImageEditor(data, mc);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    ) : this.state.ptListOpen ? (
                      <div
                        style={{
                          width: 360,
                          right: this.state.drawOpen ? 0 : -380,
                          position: "absolute",
                          borderLeft: "1px #eff2f7 solid",
                          backgroundColor: "#fff",
                          padding: "0 10px",
                          height: this.state.height - 200,
                          transition: "right .35s ease-in-out",
                        }}
                      >
                        <div
                          style={{
                            borderBottom: "1px solid #dadada",
                            paddingBottom: 10,
                            marginBottom: 10,
                          }}
                        >
                          <span className="card-title mt-0 font-size-17 fw-light">
                            People Joined
                          </span>
                          <button
                            onClick={() =>
                              this.setState({
                                drawOpen: false,
                                chatOpen: false,
                                ptListOpen: false,
                                obrlistEnabled: false,
                              })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style={{ fontSize: 15, padding: "0.78rem 1.29rem" }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="w-100 user-chat">
                          {this.state.ptList.map((item, idx) => {
                            return (
                              <div className="d-flex flex-row align-items-center p-2">
                                <div className="avatar-xs me-2">
                                  <span className="avatar-title rounded-circle bg-info bg-soft">
                                    <i className="mdi mdi-account font-size-15 text-white"></i>
                                  </span>
                                </div>
                                <div>
                                  <span>{item.displayName}</span>{" "}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Row>
              </div>

              {/* <div className="mt-2 col d-flex flex-row justify-content-center">
                <div className="d-flex flex-row">
                  {this.state.jitsiloaded ? (
                    <div className="d-flex flex-row border border-primary p-2 me-2">
                      <Button
                        color={this.state.started360 ? "secondary" : "success"}
                        className={this.state.started360 ? "me-3" : "   "}
                        onClick={() => {
                          this.setState(
                            {
                              started360: this.state.started360 ? false : true,
                            },
                            () => {
                              this.toggleLargeVideoContainer();
                            }
                          );
                        }}
                      >
                        {!this.state.started360 ? "Start 360" : "Stop 360"}
                      </Button>
                      {this.state.started360 ? (
                        <div>
                          <ul class="list-group list-group-horizontal-md p-1 justify-content-center align-item-center">
                            <li
                              class="list-group-item border-0 p-0 me-2 d-flex flex-column justify-content-center"
                              onClick={() =>
                                this.changeVideo(
                                  "https://f53112b70bc31005.mediapackage.ap-south-1.amazonaws.com/out/v1/6fe83d2ac87744aa851d043d6476e754/index.m3u8"
                                )
                              }
                            >
                              <span className="badge bg-danger text-white">
                                <i className="bx bx-broadcast text-white"></i>{" "}
                                Live{" "}
                              </span>
                            </li>
                            <li class="list-group-item border-0 p-0 me-2">
                              <i
                                className="mdi mdi-file-video text-secondary"
                                style={{ fontSize: 25 }}
                                onClick={() =>
                                  this.changeVideo(
                                    "https://d16qxmwlgqc3es.cloudfront.net/360demo/360demo.m3u8"
                                  )
                                }
                              ></i>{" "}
                              1
                            </li>

                            <li class="list-group-item border-0 p-0 me-2">
                              <i
                                className="mdi mdi-file-video text-secondary"
                                style={{ fontSize: 25 }}
                                onClick={() =>
                                  this.changeVideo(
                                    "https://d16qxmwlgqc3es.cloudfront.net/360demo/tdk_360demo.m3u8"
                                  )
                                }
                              ></i>{" "}
                              2
                            </li>

                           
                          </ul>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : null}                  
                  
                </div>
              </div> */}

              <div className="mt-2 col d-flex flex-row justify-content-center">
                <div className="d-flex flex-row p-2">
                  <ul class="list-group list-group-horizontal-md p-1">
                    <li
                      class="list-group-item border-0 p-0 flex-row justify-content-center"
                      style={{
                        width: 80,
                        display: "flex",
                        backgroundColor: !this.state.jitsiloaded
                          ? "#d3d3d3"
                          : this.state.jitsiloaded && this.state.started360
                          ? "#74788d"
                          : "#2ca67a",
                        cursor: "pointer",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if (this.state.jitsiloaded) {
                          this.setState(
                            {
                              started360: this.state.started360 ? false : true,
                            },
                            () =>
                              this.state.jitsiloaded
                                ? this.toggleLargeVideoContainer()
                                : null
                          );
                        }
                      }}
                    >
                      <div className="text-white font-size-14">
                        360 <i className="mdi mdi-rotate-orbit text-white"></i>
                      </div>
                    </li>
                    <li
                      onClick={() => this.executeCommand("toggleVideo")}
                      id="TooltipTop"
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                    >
                      <i
                        className={
                          this.state.isVideoMuted
                            ? "mdi mdi-video-off text-white"
                            : "mdi mdi-video text-white"
                        }
                        style={{ fontSize: 25 }}
                      ></i>
                    </li>
                    <li
                      onClick={() => this.executeCommand("toggleAudio")}
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                    >
                      <i
                        className={
                          this.state.isAudioMuted
                            ? "mdi mdi-microphone-off text-white"
                            : "mdi mdi-microphone text-white"
                        }
                        style={{ fontSize: 25 }}
                      ></i>
                    </li>
                    <li
                      onClick={() =>
                        this.state.started360
                          ? null
                          : this.executeCommand("toggleShareScreen")
                      }
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                    >
                      <i
                        className={
                          this.state.started360
                            ? "mdi mdi-monitor-share text-soft text-secondary"
                            : "mdi mdi-monitor-share text-white"
                        }
                        style={{ fontSize: 25 }}
                      ></i>
                    </li>
                    <li
                      onClick={() =>
                        this.state.started360
                          ? null
                          : this.executeCommand("toggleTileView")
                      }
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                    >
                      {/* mdi-view-sequential */}
                      <i
                        className={
                          this.state.started360
                            ? "mdi mdi-view-grid-outline text-soft text-secondary"
                            : "mdi mdi-view-grid-outline text-white"
                        }
                        style={{ fontSize: 25 }}
                      ></i>
                    </li>

                    <li
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                      onClick={() =>
                        this.state.tileViewMode !== "filmstrip"
                          ? this.executeCommand("toggleFilmStrip")
                          : null
                      }
                    >
                      <i
                        className={
                          this.state.tileViewMode !== "tileview"
                            ? "bx bxs-dock-right text-secondary"
                            : this.state.tglFilmStrip
                            ? "bx bx-rectangle text-white"
                            : "bx bxs-dock-right text-white"
                        }
                        style={{ fontSize: 25, lineHeight: "1.5" }}
                      ></i>
                    </li>
                    <li
                      class="list-group-item border-0 p-0  bg-white"
                      style={{ width: 1 }}
                    ></li>
                    <li
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                      onClick={() => this.openOBRBox()}
                    >
                      <i
                        className={
                          this.state.obrlistEnabled
                            ? "mdi mdi-format-list-bulleted text-info opacity-50"
                            : "mdi mdi-format-list-bulleted text-white"
                        }
                        style={{ fontSize: 25 }}
                      ></i>
                    </li>
                    <li
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                      onClick={() => this.setState({ reportModal: true })}
                    >
                      <i
                        className={
                          this.state.reportShareEnabled
                            ? "mdi mdi mdi-clipboard-list text-info opacity-50"
                            : "mdi mdi mdi-clipboard-list text-white"
                        }
                        style={{ fontSize: 25 }}
                      ></i>
                    </li>
                    <li
                      class="list-group-item border-0 p-0  bg-white"
                      style={{ width: 1 }}
                    ></li>
                    <li
                      onClick={() => {
                        this.openChatBox();
                      }}
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-col justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                    >
                      <i
                        className={
                          this.state.chatOpen
                            ? "bx bx-chat text-info opacity-50"
                            : "bx bx-chat text-white"
                        }
                        style={{ fontSize: 25, lineHeight: "1.6" }}
                      ></i>
                    </li>

                    <li
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                      onClick={() => this.setState({ addprtPanel: true })}
                    >
                      <i
                        className="mdi mdi-account-multiple-plus-outline text-white"
                        style={{ fontSize: 25 }}
                      ></i>
                    </li>
                    <li
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                      onClick={() => {
                        this.getParticipantList();
                      }}
                    >
                      <i
                        className={
                          this.state.ptListOpen
                            ? "mdi mdi-account-group-outline text-info"
                            : "mdi mdi-account-group-outline text-white"
                        }
                        style={{ fontSize: 25 }}
                      ></i>
                    </li>
                    <li
                      class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
                      style={{ width: 40 }}
                      onClick={() => {
                        this.executeCommand("muteEveryone", "video");
                      }}
                    >
                      <i
                        className={
                          "mdi mdi-account-tie-voice-off-outline text-white"
                        }
                        style={{ fontSize: 23, lineHeight: "1.7" }}
                      ></i>
                    </li>
                    <li
                      onClick={() => this.executeCommand("hangup")}
                      class="list-group-item border-0 p-0 d-flex flex-col justify-content-center align-items-ceenter"
                      style={{ width: 40, backgroundColor: "#ff0303" }}
                    >
                      <i
                        className="mdi mdi-phone-hangup text-white"
                        style={{ fontSize: 25, lineHeight: "1.5" }}
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </Container>
          </div>
          <Modal
            size="xl"
            isOpen={this.state.modal_center}
            toggle={this.tog_center}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
                Observation
              </h5>
              <button
                onClick={() => this.setState({ modal_center: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.obContentLoaded ? (
                <>
                  <Row>
                    <Col
                      xl="12"
                      lg="12"
                      sm="12"
                      className="mb-3 d-flex justify-content-center"
                    >
                      <img
                        src={this.state.snshot}
                        style={{
                          width: "60%",
                          height: "auto",
                          backgroundColor: "blue",
                        }}
                      ></img>
                    </Col>
                    <Col xl="12" lg="12" sm="12">
                      <div>
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          {this.state.login_status ? (
                            <Alert color="danger">{this.state.login_msg}</Alert>
                          ) : null}
                          {/* Check the Username and Password */}

                          <div className="form-group">
                            <AvField
                              name="title"
                              label="Title"
                              className="form-control"
                              placeholder="Enter Title for observation"
                              required
                              disabled={this.state.imageLoading}
                            />
                          </div>

                          <div className="form-group mt-3">
                            <AvField
                              name="description"
                              label="Description"
                              className="form-control"
                              placeholder="Enter Description"
                              type="textarea"
                              disabled={this.state.imageLoading}
                            />
                          </div>

                          <div className="mt-4 row">
                            <div className="col-auto ">
                              <Button
                                className="waves-effect waves-light "
                                color="danger"
                                disabled={this.state.imageLoading}
                                onClick={() =>
                                  this.setState({ modal_center: false })
                                }
                              >
                                Cancel{" "}
                              </Button>
                            </div>
                            <div className="col-auto ">
                              <Button
                                className=" waves-effect waves-light"
                                color="success"
                                type="submit"
                                disabled={this.state.imageLoading}
                              >
                                Confirm{" "}
                              </Button>
                            </div>
                          </div>
                          {this.state.imageLoading ? (
                            <div className="mt-4 row">
                              <div
                                className="spinner-border text-secondary m-1 col-2 "
                                role="status"
                              ></div>
                              <div className="col-10 d-flex align-item-center">
                                <span className="text-secondary">
                                  Please wait, image editior is loading...
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </AvForm>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}
            </div>
          </Modal>
          <Modal
            size="xl"
            isOpen={this.state.modal_fullscreen}
            toggle={this.tog_fullscreen}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
                Observation -{" "}
                <span className="text-primary">{this.state.obrTitle}</span>
              </h5>
              <button
                onClick={() => this.setState({ modal_fullscreen: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="d-flex flex-row h-100">
                <div style={{ width: "calc(100% - 360px)" }}>
                  <OBRImageEditor
                    imagePreviewUrl={this.state.imagePreviewUrl}
                    img_name={this.state.obInfo.img_name}
                    mandc={this.state.mandc}
                    ref={this.obreditor}
                  />
                </div>
                <div
                  className="ms-2"
                  style={{
                    width: 360,
                    borderLeft: "1px #eff2f7 solid",
                    backgroundColor: "#e9f2f8",
                    padding: "0 10px",
                  }}
                >
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={this.handleValidSubmit}
                  >
                    <div className="form-group mt-3">
                      <div
                        className="pb-2"
                        style={{ borderBottom: "1px #eff2f7 solid" }}
                      >
                        <span className="font-size-18">Observations</span>
                      </div>
                      <AvField
                        name="comment"
                        label=""
                        className="form-control mt-3"
                        placeholder="Enter your observation"
                        type="textarea"
                        style={{
                          minHeight: this.state.height - 200,
                          width: "100%",
                        }}
                        value={
                          this.state.mandc !== null &&
                          this.state.mandc !== undefined
                            ? this.state.mandc.muser_comment
                            : this.state.commentText
                        }
                        onChange={(event) => {
                          this.setState({ commentText: event.target.value });
                        }}
                        disabled={this.state.imageLoading}
                      />
                    </div>
                  </AvForm>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={this.tog_fullscreen}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      disabled={this.state.savingImage}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.convertAsImage();
                      }}
                      disabled={this.state.savingImage}
                    >
                      {this.state.savingImage ? (
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      ) : null}
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            size="md"
            isOpen={this.state.addprtPanel}
            toggle={this.tog_addprtPanel}
            centered={true}
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="exampleModalFullscreenLabel2"
              >
                Add People
              </h5>
              <button
                onClick={() => this.setState({ addprtPanel: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {this.state.addprtPanel ? (
              <div className="modal-body">
                <Row>
                  <Col className="col-12 mb-3">
                    <div
                      className="mb-1 pb-3"
                      style={{ borderBottom: "1px #e8eaef solid" }}
                    >
                      <label
                        className={
                          this.state.isemailError ? "text-danger" : null
                        }
                      >
                        Invite
                      </label>
                      <ReactMultiEmail
                        placeholder="Enter mail id"
                        emails={emails}
                        className={
                          !this.state.isemailError
                            ? "border border-dark"
                            : "border border-danger"
                        }
                        onChange={(_emails: string[]) => {
                          this.setState({
                            isemailError: false,
                            emails: _emails,
                            isemailEmpty: _emails.length > 0 ? false : true,
                          });
                        }}
                        validateEmail={(email) => {
                          return isEmail(email); // return boolean
                        }}
                        getLabel={(
                          email: string,
                          index: number,
                          removeEmail: (index: number) => void
                        ) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span
                                data-tag-handle
                                onClick={() => {
                                  this.setState({ isemailError: false });
                                  removeEmail(index);
                                }}
                              >
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                      {this.state.isemailError ? (
                        <label className="text-danger font-size-10">
                          {this.state.inviteError}
                        </label>
                      ) : null}
                    </div>

                    <div
                      className="w-100 mt-3"
                      style={{ height: 200, overflowY: "auto" }}
                    >
                      <label className="text-dark"> People Joined </label>
                      {this.state.meetingInfo.participants.map((item, idx) => {
                        return (
                          <div
                            className="d-flex flex-row align-items-center p-2"
                            style={{ borderBottom: "1px #e8eaef solid" }}
                          >
                            <div className="avatar-xs me-2">
                              <span className="avatar-title rounded-circle bg-info bg-soft">
                                <i className="mdi mdi-account font-size-15 text-white"></i>
                              </span>
                            </div>
                            <div>
                              <div>{item.displayName}</div>{" "}
                              <div>{item.email_id}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Button
                        disabled={!this.state.inviteOver}
                        className="me-2"
                        color="danger"
                        onClick={() => {
                          this.setState({
                            isemailError: false,
                            addprtPanel: false,
                            emails: [],
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!this.state.inviteOver}
                        color="primary"
                        onClick={() => this.invitePeople()}
                      >
                        Invite
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}
          </Modal>
          <Modal
            size="xl"
            isOpen={this.state.reportModal}
            toggle={this.tog_report}
            centered={true}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="exampleModalFullscreenLabel2"
              >
                Report
              </h5>
              <button
                onClick={() => this.setState({ reportModal: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {this.state.reportModal ? (
              <div className="modal-body" style={{ padding: "0 1rem" }}>
                <Row>
                  <Report meetingInfo={this.state.meetingInfo} />
                </Row>
                <Row className="my-2">
                  <div className="d-flex flex-column align-items-end me-2">
                    <Button
                      color={this.state.isReportShared ? "danger" : "primary" }
                      className="d-inline-block"
                      style={{ width: 200 }}
                      onClick={()=>{  this.setState({isReportShared:this.state.isReportShared ? false : true}, ()=>{ this.openReport()})}}
                    >
                      <i className={this.state.isReportShared ? "mdi mdi-share-off text-white me-2" : "mdi mdi-share text-white me-2" }/>
                      {this.state.isReportShared ? "Stop Sharing" : "Share Report" }
                    </Button>
                  </div>
                </Row>
              </div>
            ) : null}
          </Modal>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default Hoster;
