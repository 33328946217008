import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Alert, Col, Container,Row  } from "reactstrap"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"

// import images
import profile from "../../assets/images/logo_blue.png";

import Logo from "../../assets/images/logo-light-1.png";


var urlSocket = require('../../helpers/urlSocket');

export default class Login2 extends Component {


  constructor(props) {
    super(props);
    this.state = {
        login_status: false,
        loading: false,
        login_msg: '',
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
}


   // Login Submit
   handleValidSubmit(event, values) {

    // this.props.loginUser(values, this.props.history);
    let deviceInfo = { ipAddress: "", macAddress: "" }
    try {
        urlSocket.post('/authentication/login', {
            username: values.username,
            password: values.password,
            mac_address: "",
            device_info: [deviceInfo],
            //   

        })
            .then((response) => {
                console.log("login",response.data)

                if (response.data.response_code == 500) {
                        this.setState({ loading: true, login_status: true, login_msg: response.data.message })
                        const loginSession = response.data
                        sessionStorage.setItem("authUser", JSON.stringify(loginSession));
                        if(response.data.user_data.usertype == "99")
                        {
                          this.props.history.push("/admindashboard");
                          //!response.data.user_data.ftis ? this.props.history.push("/ftisusrpfl"):this.props.history.push("/admindashboard");
                        }
                        else
                        {
                            this.props.history.push("/dashboard");
                        }
                }
                else {
                    this.setState({ login_status: true, login_msg: response.data.message })

                }
            })
    }
    catch (error) {
        console.log(error.toString())
    }

    this.remove_sessions();

}
remove_sessions() {
    sessionStorage.removeItem("tm_list");
    sessionStorage.removeItem("entities_list_page");
    sessionStorage.removeItem("audit_parameters");
    sessionStorage.removeItem("userNodeInfo");
}
componentDidMount() {
   // this.props.apiError("");
}


  render() {
    return (
      <React.Fragment>
        <div>
        <MetaTags>
            <title>eaudit</title>
          </MetaTags>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="dashboard" className="d-block auth-logo">
                          <img
                            src={Logo}
                            alt=""
                            height="30"
                            className="auth-logo-dark"
                          />
                          <img
                            src={Logo}
                            alt=""
                            height="30"
                            className="auth-logo-light"
                          />
                        </Link>
                      </div>

                                    <div className="my-auto">
                                        <div>
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to eaudit.</p>
                                        </div>
            
                                        <div className="mt-4 p-2">

                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                {this.state.login_status ? <Alert color="danger">{this.state.login_msg}</Alert> : null}
                                                {/* Check the Username and Password */}


                                                <div className="form-group">
                                                    <AvField name="username" label="Username" className="form-control" placeholder="Enter Username" required />
                                                </div>

                                                <div className="form-group mt-3">
                                                    <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                                </div>

                                                <div className="mt-3">
                                                    <button className="btn btn-danger btn-block waves-effect waves-light" type="submit"> {this.state.loading ? "Loading" : "Log In"} </button>
                                                </div>
                                            </AvForm>
                                            <div className="mt-5 text-center">
                                                <p>Don't have an account ? <a href="register" className="fw-medium text-primary"> Signup now </a> </p>
                                            </div>
                                        </div>
                                    </div>


                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} eManufacturing. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
