import React, { Component } from "react";
import { Container, Row, Col, Button, } from "reactstrap";



import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";



import { isEmpty, size, map } from "lodash";
import moment from "moment";

var urlSocket = require("../../helpers/urlSocket");
var randomColor = require("randomcolor");

var _ = require('lodash');

class report_page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            usertype: "",
            dataloaded: false,
            auditStatusData: [
              {
                status: "Active",
                count: 0,
                color: "#fbae17",
                id: "1",
                badgeClass: "success",
              },
              {
                status: "In active",
                count: 0,
                color: "#303335",
                id: "0",
                badgeClass: "danger",
              },
            ],
            userHLevel: "All",
            resultData: [],
            ddchange: false,
            open: false,
            labelData: [],
            selectedLabel: false,
            selectedEOPT: [],
            height: window.innerHeight,
            custom_tbx: [],
            emails: [],
            isemailEmpty: false,
            scheduleMeeting: false,
            datalist:false,
            datetime:new Date(),
            enddatetime:new Date(),
          };
    }


    componentWillMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var auditData = JSON.parse(sessionStorage.getItem("auditData"));
    
        this.setState(
          {
            img_max_mb: data.img_max_mb,
            userInfo: data.user_data,
            usercode: data.user_data.user_code,
            mappinginfo: data.user_data.mappinginfo,
            designation: data.user_data.designation,
            configData: data.config_data,
            auditData: auditData,
            dataloaded: false,
          },
          function () {
            this.loadMeetings();
          }
        );
      }
    
      loadMeetings = () => {
        try {
          urlSocket
            .post("mngmtg/listmeetings", {
              userInfo: {
                company_id: this.state.userInfo.company_id,
                user_id: this.state.userInfo._id,
              },
            })
            .then((response) => {
              console.log("response.data.data", response.data.data);
              this.setState({
                statusBasedFilteredData: response.data.data,
                tableDataloaded: true,
                allowTableRowToSelect: true,
                dataloaded: true,
                datalist:true,
                scheduleMeeting:false
              });
            });
        } catch (error) {}
      };

      navigateToMeeting(meetingInfo){
        sessionStorage.removeItem("usermeetingInfo");
        sessionStorage.setItem("usermeetingInfo", JSON.stringify(meetingInfo));
        this.props.history.push("/mtnginfo");
      }


    render() {
        if (this.state.dataloaded) {
            const { emails } = this.state;
            const { SearchBar } = Search;
            // const { users } = this.state
            const options = {
              // pageStartIndex: 0,
      
              sizePerPage: 10,
              totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
              custom: true,
            };
            const columns = [
              {
                text: "id",
                dataField: "_id",
                sort: true,
                hidden: true,
                formatter: (cellContent, item) => <>{item._id}</>,
              },
              {
                dataField: "mname",
                text: "Name",
                sort: true,
                formatter: (cellContent, item) => {
                  return (
                  <>
                    <div
                      style={{
                        margin: "5px 0px",
                        fontWeight: "600",
                        fontSize: "13px",
                      }}
                    >
                      {item.mname}
                    </div>
                    <div className="font-size-11">
                      <span className="font-size-12 text-primary">{moment(item.scheduled_on).format("LLLL")}</span> (IST)
                    </div>
                  
                  </>
                )}
              },
      
              {
                dataField: "created_on",
                isDummyField: true,
                hidden: true,
                text: "updated on",
              },
      
              {
                dataField: "menu",
                isDummyField: true,
                editable: false,
                text: "Action",
                formatter: (cellContent, item) => {
                  var isBefore = moment(item.scheduled_on).isBefore(new Date())
                  return (
                  <div
                    className="d-flex"
                    style={{ display: "flex", alignContent: "center" }}
                  >
                    <Button
                      type="button"
                      //color={isBefore ? "secondary" : "success"}
                      color={"primary"}
                      size="sm"
                      className="btn-rounded waves-effect waves-light ps-3 pe-3"
                      onClick={() => {
                        this.navigateToMeeting(item)
                      }}
                      //disabled={isBefore}
                    >
                      Report
                    </Button>
                  </div>
                )}
              },
            ];
            const defaultSorted = [
              {
                dataField: "created_on", // if dataField is not match to any column you defined, it will be ignored.
                order: "desc", // desc or asc
              },
            ];
      
            const selectRow = {
              mode: "checkbox",
              clickToSelect: true, // this.state.allowTableRowToSelect,
              onSelect: (row, isSelect, rowIndex, e) => {
                console.log("row selectedd", row, isSelect, rowIndex, e);
                this.getSelectedEOPT(row, isSelect, rowIndex, e);
              },
              // onSelectAll: (isSelect, rows, e) => {
              //     console.log("select all", rows,)
              // },
              // nonSelectable: this.state.label.endpoints
            };
            return (
              <React.Fragment>
                <div className="page-content" style={{ minHeight: "100vh" }}>
                  <Container fluid>
                    {/* <Breadcrumbs title="" breadcrumbItem="Dashboard" /> */}
      
                    <div className="d-xl-flex">
                      <div className="w-100">
                        <div className="d-md-flex">
                          <div
                            className="col-12   "
                           
                          >
                            <div
                              style={{
                                height: this.state.height - 150,
                                overflowY: "auto",
                              }}
                            >
      
                              <Row>
                                <h6 className="mt-4 font-size-13">My Meetings</h6>
                                {this.state.datalist ? (
                                  <PaginationProvider
                                    keyField="id"
                                    data={this.state.statusBasedFilteredData}
                                    columns={columns}
                                    pagination={paginationFactory(options)}
                                  >
                                    {({ paginationProps, paginationTableProps }) => (
                                      <ToolkitProvider
                                        keyField="id"
                                        data={this.state.statusBasedFilteredData}
                                        columns={columns}
                                        search
                                      >
                                        {(toolkitprops) => (
                                          <React.Fragment>
                                            <Row
                                              className="mb-2"
                                              style={{ paddingTop: 10 }}
                                            >
                                              <Col lg="12">
                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                  <div className="position-relative">
                                                    <SearchBar
                                                      {...toolkitprops.searchProps}
                                                    />
                                                    <i className="bx bx-search-alt search-icon" />
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col xl="12">
                                                <div className="table-responsive">
                                                  <BootstrapTable
                                                    {...toolkitprops.baseProps}
                                                    {...paginationTableProps}
                                                    defaultSorted={defaultSorted}
                                                    classes={
                                                      "table align-middle table-nowrap table-hover"
                                                    }
                                                    bordered={false}
                                                    striped={false}
                                                    responsive
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row className="align-items-md-center mt-30">
                                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                <PaginationListStandalone
                                                  {...paginationProps}
                                                />
                                              </Col>
                                            </Row>
                                          </React.Fragment>
                                        )}
                                      </ToolkitProvider>
                                    )}
                                  </PaginationProvider>
                                ) : null}
                              </Row>
                            </div>
                          </div>
      
                          
                        </div>
                      </div>
                    </div>
      
                    
                  </Container>
                </div>
              </React.Fragment>
            );
          } else {
            return null;
          }
        }
      }

export default report_page;
