import React, { Component, createRef, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";


import urlSocket from "../../helpers/urlSocket";

class OBRList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataloaded: false,
    };
  }

  componentDidMount() {
    this.getOBRList();
  }

  getOBRList = () => {
    try {
      urlSocket
        .post("mngobvrs/getobservationlist", {
          meetingInfo: this.props.meetingInfo,
        })
        .then((response) => {
          if (response.data.response_code === 500) {
            this.setState({
              obrList: response.data.data,
              dataloaded: true,
            });
            console.log("data", response.data.data);
          }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  };

  getOBRComments(obrInfo) {
    try {
      urlSocket
        .post("mngobvrs/getobrcomments", {
          observationInfo: obrInfo,
          userInfo: this.props.userInfo,
        })
        .then((response) => {
          if (response.data.response_code === 500) {
            var mandc = response.data.data;
            this.props.loadImageEditor(obrInfo, mandc);
          }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  render() {
    if (this.state.dataloaded) {
      const { emails } = this.state;
      const { SearchBar } = Search;
      // const { users } = this.state
      const options = {
        // pageStartIndex: 0,

        sizePerPage: 6,
        totalSize: this.state.obrList.length, // replace later with size(users),
        custom: true,
      };
      const columns = [
        {
          text: "id",
          dataField: "_id",
          sort: true,
          hidden: true,
          formatter: (cellContent, item) => <>{item._id}</>,
        },
        {
          dataField: "title",
          text: "Observation",
          sort: true,
          formatter: (cellContent, item, i) => {
            return (
              <>
               
                <div
                  key={i + "obr"}
                  style={{
                    margin: "5px 0px",
                    fontWeight: "400",
                    fontSize: "13px",
                    overflowWrap:"break-word",
                  }}
                  className={i==0 ? "text-info ont-size-12" :"font-size-12 text-dark"}
                >
                  {item.title}
                </div>
                <div className="font-size-12 text-secondary">
                  <span>
                    {moment(item.created_on).format("llll")}
                  </span>{" "}
                  (IST)
                </div>
              </>
            );
          },
        },

        {
          dataField: "created_on",
          isDummyField: true,
          hidden: true,
          text: "updated on",
        },

        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, item, i) => {
            return (
              <div
                className="d-flex"
                style={{ display: "flex", alignContent: "center" }}
              >
                <Button
                  type="button"
                  color={i==0 ? "info" : "secondary"}
                  size="sm"
                  className="btn-rounded waves-effect waves-light ps-3 pe-3 me-2"
                  onClick={() => {
                    this.getOBRComments(item);
                  }}
                >
                  Edit
                </Button>
              </div>
            );
          },
        },
      ];
      // const defaultSorted = [
      //   {
      //     dataField: "created_on", // if dataField is not match to any column you defined, it will be ignored.
      //     order: "desc", // desc or asc
      //   },
      // ];

      const selectRow = {
        mode: "checkbox",
        clickToSelect: true, // this.state.allowTableRowToSelect,
        onSelect: (row, isSelect, rowIndex, e) => {
          console.log("row selectedd", row, isSelect, rowIndex, e);
          this.getSelectedEOPT(row, isSelect, rowIndex, e);
        },
        // onSelectAll: (isSelect, rows, e) => {
        //     console.log("select all", rows,)
        // },
        // nonSelectable: this.state.label.endpoints
      };

      return (
        <div className="h-100">
          {this.state.obrList.length > 0 ? (
            <PaginationProvider
              keyField="id"
              data={this.state.obrList}
              columns={columns}
              pagination={paginationFactory(options)}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={this.state.obrList}
                  columns={columns}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <Row className="mb-2" style={{ paddingTop: 10 }}>
                        <Col lg="12">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitprops.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              // defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-wrap table-hover"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          ) : (
            <div className="d-flex flex-col justify-content-center align-items-center h-100">
                <div style={{height:'auto'}}>
                  <p className="text-danger">No observations found</p>
                </div>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default OBRList;
