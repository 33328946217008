import React, { Component } from "react";

import { Container, Row, Col, CardBody, Card, Alert, Button } from "reactstrap";
import { Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";


import BGImage from "../../assets/images/om.jpg";
import logoDark from "../../assets/images/360-logo-new.png";


import _ from "lodash";
import moment from "moment";


var urlSocket = require("../../helpers/urlSocket");

class MeetingLogin extends Component {
  domain = "jitsi.autolearn.in";
  api = {};

  constructor(props) {
    super(props);
    this.state = {
      login_status: false,
      loading: false,
      login_msg: "",
      height: window.innerHeight,
      isVideoMuted: false,
      isAudioMuted: false,
      dpname: "unknown",
      dataloaded: false,
      isMenuExpanded:true,
      jitsiLoaded:false,
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    
  }

  componentDidMount() {
    this.getParticipantInfo();
    
  }


  getParticipantInfo() {
    
    console.log("====== am working=======")
    //window.removeEventListener('storage', this.checkAuth)
    var location = window.location.href;
    sessionStorage.setItem("participantURL", JSON.stringify(location));

    var splitString = location.split("?");
    var getUserCode = splitString[splitString.length - 1];
    var meetingCode = splitString[splitString.length - 2];

    try {
      urlSocket
        .post("mngmtg/patricpantinfo", {
          meeting_code: meetingCode,
        })
        .then((response) => {
          if (response.data.response_code == 500) {
            var meetingInfo = response.data.data;
            var getUserInfo = _.find(meetingInfo.participants, {
              user_code: getUserCode,
            });

            this.setState(
              {
                parMeetingInfo: meetingInfo,
                partInfo: getUserInfo,
                meetingCode,
                dataloaded: true,
              },
              () => {
                if (window.JitsiMeetExternalAPI) {
                  if(meetingInfo.status !== "2") this.startMeet();
                } else {
                  alert("JitsiMeetExternalAPI not loaded");
                }
              }
            );

            
          }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  startMeet = () => {
    const options = {
      roomName: this.state.meetingCode,
      width: "100%",
      height: "100%",
      configOverwrite: {
        prejoinConfig: {
          enabled: true,
          hideExtraJoinButtons: [],
          toolbarButtons: [],
        },
        startWithVideoMuted: this.state.isVideoMuted,
        startWithAudioMuted: this.state.isAudioMuted,
        toolbarButtons: [],
        enableDisplayNameInStats: false,
        backgroundAlpha: 1,
        disableFilmstripAutohiding: true,
        generateRoom: false,
        remoteVideoMenu: {
          disableKick: true,
        },
      },

      interfaceConfigOverwrite: {
        filmStripOnly: true,
        DISABLE_VIDEO_BACKGROUND: true,
        SHOW_BRAND_WATERMARK: false,
        SHOW_JITSI_WATERMARK: false,
      },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName: this.state.dpname,
      },
    };
    this.api = new window.JitsiMeetExternalAPI(this.domain, options);
    this.setState({jitsiLoaded:true})
  };



  // custom events
  executeCommand(command) {
    this.api.executeCommand(command);
    if (command == "hangup") {
      //return this.props.history.push("/thank-you");
    }

    if (command == "toggleAudio") {
      this.setState({ isAudioMuted: !this.state.isAudioMuted });
    }

    if (command == "toggleVideo") {
      this.setState({ isVideoMuted: !this.state.isVideoMuted });
    }
  }

  // Login Submit
  handleValidSubmit(event, values) {

    try {

      urlSocket.post("mngmtg/participantmeetingauth", {
        roomName : this.state.parMeetingInfo.code,
        participantCode : this.state.partInfo.user_code,
        authInfo : values,
        mStatus:"1"
      })
      .then(response=>{
        if(response.data.response_code === 500)
        {
          var people = response.data.data
          sessionStorage.removeItem("usermeetingInfo");
          sessionStorage.removeItem("partInfo");
          sessionStorage.removeItem("confPlayerControl");

          var playerControl = {
            isAudioMuted: this.state.isAudioMuted,
            isVideoMuted: this.state.isVideoMuted,
            displayInfo: values,
          };

          console.log("playerControl", playerControl)

          var meetingURL = window.location.href;
          sessionStorage.setItem(
            "usermeetingInfo",
            JSON.stringify(this.state.parMeetingInfo)
          );
          sessionStorage.setItem(
            "partitionInfo",
            JSON.stringify(this.state.partInfo)
          );
          sessionStorage.setItem(
            "confPlayerControl",
            JSON.stringify(playerControl)
          );
          sessionStorage.setItem(
            "hostMeetingStatus",
            JSON.stringify(response.data.message)
          );
          sessionStorage.setItem("meetingURL", JSON.stringify(meetingURL));
          sessionStorage.setItem("people", JSON.stringify(people));
          this.props.history.push("/prtmtng");
        }
        else if( response.data.response_code === 504 && response.data.message === "incorrect password")
        {
          this.setState({ passcodeError: true, message:"Password incorrect" });
        }
        else if( response.data.response_code === 504 && response.data.message === "already logged")
        {
          this.setState({ passcodeError: true, message:"User already logged in" });
        }
      })
      
    } catch (error) {
        console.log("catch error", error)
    }


  }

  render() {
    if (this.state.dataloaded) {
      const buttonStyle = {
        width:30, height:40, padding:5, display:"flex", alignItems:"center", justifyContent:"center", marginRight:5
      }
  
      return (
        <React.Fragment>
          <div
            className="page-content"
            style={{ minHeight: "100vh", padding: 0 }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 5,
                alignItems: "center",
              }}
            >
              <div
                className="my-3"
                style={{ textAlign: "end", paddingLeft: 20 }}
              >
                <img src={logoDark} alt="" height="50" />
              </div>
              <div style={{ width: "100%", padding: "0px 10px" }}>
                <span className="font-size-18 fw-bold">
                  MEET 360
                </span>
              </div>
            </div>
            <Container fluid className="p-0">
              {this.state.parMeetingInfo.status === "2" ? (
                <>
                  <div
                    className="d-flex flex-column 
                  flex-row align-items-center 
                  justify-content-center"
                    style={{ height: "80vh" }}
                  >
                    <div style={{ width: "contain", display: "contents" }}>
                      <img
                        src={BGImage}
                        style={{
                          width: "50%",
                          // height:300
                        }}
                      ></img>
                      <div
                        style={{
                          width: "contain",
                          fontSize: 30,
                          margin: "10px 0",
                        }}
                      >
                        Sorry! This Meeting is end.
                      </div>
                      <div style={{ width: "contain", fontSize: 17 }}>
                        Organized by{" "}
                        <span> {this.state.parMeetingInfo.organized_by}</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Row className="g-0">
                  <div
                    className="w-100"
                    style={{
                      height: this.state.height - 200,
                      overflow: "hidden",
                    }}
                  >
                    <Row className="d-flex h-100 flex-row align-items-center justify-content-center">
                      <Col className="col-auto">
                        <div
                          id="jitsi-iframe"
                          style={{ width: 600, height: 400 }}
                        ></div>
                        {
                          this.state.jitsiLoaded && <div className="d-md-flex w-100">
                          <ul className="list-group list-group-horizontal-md py-1">
                            <li
                              onClick={() => {
                                this.executeCommand("toggleVideo");
                              }}
                              className="list-group-item border-0 p-0 d-flex flex-row justify-content-center align-items-center"
                              style={buttonStyle}
                            >
                              <i
                                className={
                                  this.state.isVideoMuted
                                    ? "mdi mdi-video-off text-dark"
                                    : "mdi mdi-video text-dark"
                                }
                                style={{ fontSize: 25 }}
                              ></i>
                            </li>
                            <li
                              onClick={() => {
                                this.executeCommand("toggleAudio");
                              }}
                              className="list-group-item border-0 p-0  d-flex flex-row justify-content-center align-items-center"
                              style={buttonStyle}
                            >
                              <i
                                className={
                                  this.state.isAudioMuted
                                    ? "mdi mdi-microphone-off text-dark"
                                    : "mdi mdi-microphone text-dark"
                                }
                                style={{ fontSize: 25 }}
                              ></i>
                            </li>
                          </ul>
                        </div>
                        }
                        
                      </Col>

                      <Col className="col-auto overflow-hidden">
                        <div style={{ width: 400 }}>
                          <div style={{ width: "100%", padding: "0px 10px" }}>
                            <span className="font-size-18">
                              {this.state.parMeetingInfo.mname}<br />
                            </span>{" "}
                            <div className="mt-2">
                            <span className="font-size-11 text-secondary mt-2">
                              Organized by
                            </span>{" "}
                            
                            <span>
                              {" "}
                              {this.state.parMeetingInfo.organized_by}
                            </span>
                            <div className="font-size-11 text-primary">
                              {moment(
                                this.state.parMeetingInfo.scheduled_on
                              ).format("LLLL")}
                            </div>
                            </div>
                          </div>

                          <div className="mt-4 p-2">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleValidSubmit}
                            >
                              {this.state.login_status ? (
                                <Alert color="danger">
                                  {this.state.login_msg}
                                </Alert>
                              ) : null}
                              {/* Check the Username and Password */}

                              <div className="form-group">
                                <AvField
                                  name="displayname"
                                  label="Your Display Name"
                                  className="form-control"
                                  placeholder="Enter Your Display Name"
                                  required
                                />
                              </div>

                              <div className="form-group mt-3">
                                <AvField
                                  name="password"
                                  label="Passcode"
                                  value=""
                                  type="password"
                                  required
                                  placeholder="Enter Passcode"
                                />
                              </div>

                              <div className="mt-4 d-block">
                                <Button
                                  className="w-100 waves-effect waves-light"
                                  style={{
                                    backgroundColor: "#1a73e8",
                                    border: 0,
                                  }}
                                  type="submit"
                                >
                                  {" "}
                                  {this.state.loading
                                    ? "Loading"
                                    : "Join Meeting"}{" "}
                                </Button>
                                {this.state.passcodeError ? (
                                  <div className="my-2">
                                    <span className="text-danger">
                                      {this.state.message}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </AvForm>
                          </div>
                        </div>

                        <div className="mt-2 mt-md-2">
                          <p className="mb-0">
                            © {new Date().getFullYear()} eParampara
                            Technologies.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              )}
            </Container>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default MeetingLogin;
