import React, { Component, createRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown, Tooltip
} from "reactstrap";

class ConfControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuExpanded: false,
      started360: false,
      mute:this.props.socketUser.mute,
      permission:{}
    };
  }



  render() {
    const buttonStyle = {
      width:40, height:40, borderRadius:20, padding:5, display:"flex", alignItems:"center", justifyContent:"center", marginRight:5
    }
    const buttonStyle2 = {
      width:35, height:40,  padding:5, display:"flex", alignItems:"center", justifyContent:"center", marginRight:5
    }
    const hangupButton = {
      width:40, height:40, borderRadius:20, padding:5, backgroundColor: "#ff0303", display:"flex", alignItems:"center", justifyContent:"center", marginRight:5
    }
    return (
      <Row>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop}
          target="cameraonoff"
          toggle={() => this.setState({ tttop: !this.state.tttop })}
        >
          {" "}
          Camera On / Off
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop1}
          target="miconoff"
          toggle={() => this.setState({ tttop1: !this.state.tttop1 })}
        >
          {" "}
          Mic On / Off
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop2}
          target="sharescreen"
          toggle={() => this.setState({ tttop2: !this.state.tttop2 })}
        >
          {" "}
          Share Screen
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop3}
          target="tileview"
          toggle={() => this.setState({ tttop3: !this.state.tttop3 })}
        >
          {" "}
          Tile view / Filmstrip
        </Tooltip>
        {this.props.socketUser.permission[2].status &&<Tooltip
          placement="top"
          isOpen={this.state.tttop4}
          target="invitepeople"
          toggle={() => this.setState({ tttop4: !this.state.tttop4 })}
        >
          {" "}
          Invite people
        </Tooltip>}
        <Tooltip
          placement="top"
          isOpen={this.state.tttop5}
          target="leavemeeting"
          toggle={() => this.setState({ tttop5: !this.state.tttop5 })}
        >
          {" "}
          Leave Meeting
        </Tooltip>
        {this.props.socketUser.permission[1].status && <Tooltip
          placement="top"
          isOpen={this.state.tttop6}
          target="sharecontent"
          toggle={() => this.setState({ tttop6: !this.state.tttop6 })}
        >
          {" "}
          Share Content
        </Tooltip> }
        <Tooltip
          placement="top"
          isOpen={this.state.tttop7}
          target="chatmessage"
          toggle={() => this.setState({ tttop7: !this.state.tttop7 })}
        >
          {" "}
          Chat Messages
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop8}
          target="peoplejoined"
          toggle={() => this.setState({ tttop8: !this.state.tttop8 })}
        >
          {" "}
          People Joined
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tttop9}
          target="observationlist"
          toggle={() => this.setState({ tttop9: !this.state.tttop9 })}
        >
          {" "}
          Observation points
        </Tooltip>
        {this.props.socketUser.permission[4].status && <Tooltip
          placement="top"
          isOpen={this.state.tttop10}
          target="report"
          toggle={() => this.setState({ tttop10: !this.state.tttop10 })}
        >
          {" "}
          Report
        </Tooltip>}
        
       
        <Col lg="4" md="3">
          <div className="d-flex h-100 flex-row justify-content-start align-items-center">
            <div style={{ paddingLeft: 20 }}>
              <span className="font-size-17 text-white">
                {this.props.displayName}{" "}
              </span>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {this.props.socketUser.MuteMicAll && (
                <span className="text-white">
                  {" "}
                  | &nbsp;&nbsp;&nbsp;&nbsp;
                  <i
                    className={"mdi mdi-microphone-off text-white"}
                    style={{ fontSize: 20 }}
                  ></i>{" "}
                  Host muted all mic
                </span>
              )}
            </div>
          </div>
        </Col>
        <Col lg="4">
          <div className="col d-flex flex-row justify-content-center">
            <div className="d-flex flex-row p-2" style={{ paddingVertical: 5 }}>
              <ul class="list-group list-group-horizontal-md p-1">
                <li
                  onClick={() => this.props.execCommand("toggleVideo")}
                  id="cameraonoff"
                  class="bg-dark"
                  style={buttonStyle}
                >
                  <i
                    className={
                      this.props.isVideoMuted
                        ? "mdi mdi-video-off text-white"
                        : "mdi mdi-video text-white"
                    }
                    style={{ fontSize: 20 }}
                  ></i>
                </li>
                <li
                  onClick={() =>
                    !this.props.socketUser.MuteMicAll
                      ? this.props.execCommand("toggleAudio")
                      : this.props.askToUnmute()
                  }
                  class={
                    this.props.socketUser.MuteMicAll ||
                    this.props.socketUser.muteByCreator
                      ? "bg-primary"
                      : "bg-dark"
                  }
                  id="miconoff"
                  style={{
                    ...buttonStyle,
                    width:
                      this.props.socketUser.MuteMicAll ||
                      this.props.socketUser.muteByCreator
                        ? 135
                        : 40,
                  }}
                >
                  {this.props.socketUser.MuteMicAll ||
                  this.props.socketUser.muteByCreator ? (
                    <span style={{ cursor: "default", color: "#ffffff" }}>
                      Ask to unmute
                    </span>
                  ) : (
                    <i
                      className={
                        this.props.isAudioMuted
                          ? "mdi mdi-microphone-off text-white"
                          : "mdi mdi-microphone text-white"
                      }
                      style={{ fontSize: 20 }}
                    ></i>
                  )}
                </li>
                <li
                  onClick={() =>
                    this.props.streamingStarted
                      ? null
                      : this.props.tgless("toggleShareScreen")
                  }
                  id="sharescreen"
                  class="bg-dark"
                  style={buttonStyle}
                >
                  <i
                    className={
                      this.props.streamingStarted
                        ? "mdi mdi-monitor-share text-soft text-secondary"
                        : "mdi mdi-monitor-share text-white"
                    }
                    style={{ fontSize: 20 }}
                  ></i>
                </li>

                <li
                  onClick={() =>
                    this.props.streamingStarted
                      ? null
                      : this.props.execCommand("toggleTileView")
                  }
                  id="tileview"
                  class="bg-dark"
                  style={buttonStyle}
                >
                  <i
                    className={
                      this.props.streamingStarted
                        ? "mdi mdi-crop-landscape text-secondary"
                        : this.props.tileViewMode
                        ? "mdi mdi-view-grid-outline text-white"
                        : "mdi mdi-crop-landscape text-white"
                    }
                    style={{ fontSize: 25 }}
                  ></i>
                </li>

                {this.props.socketUser.permission[2].status && (
                  <li
                    class="bg-dark"
                    id="invitepeople"
                    style={{ ...buttonStyle }}
                    onClick={() => {
                      this.props.addprtPanel();
                    }}
                  >
                    <i
                      className="mdi mdi-account-multiple-plus-outline text-white"
                      style={{ fontSize: 20 }}
                    ></i>
                  </li>
                )}

                {/* 
            
            {
              this.props.socketUser.permission[0].status &&
            <li
              style={{...buttonStyle, backgroundColor:this.state.mute?"#ff0303":"#343a40"}}
              onClick={() => {
                this.setState({mute:this.state.mute?false:true}, ()=>{this.props.muteUnMuteAll(this.state.mute);})
                
              }}
            >
              <i
                className={this.state.mute ? "mdi mdi-account-tie-voice-off-outline text-white" : "mdi mdi-account-tie-voice-outline text-white"}
                style={{ fontSize: 18, lineHeight: "1.7" }}
              ></i>
            </li>
            } */}

                {/* #343a40 !important */}

                <li
                  onClick={() => {
                    //this.props.userRole === "Organizer" ? this.props.meetingClose() : this.props.leaveRoom();
                    this.props.meetingClose();
                  }}
                  id="leavemeeting"
                  style={{ ...hangupButton }}
                >
                  <i
                    className="mdi mdi-phone-hangup text-white"
                    style={{ fontSize: 25, lineHeight: "1.5" }}
                  ></i>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col lg="4" md="3">
          <div className="col d-flex flex-row justify-content-end">
            <div className="d-flex flex-row p-2" style={{ paddingVertical: 5 }}>
              <ul class="d-flex flex-row list-group list-group-horizontal-md p-1 justify-content-center align-items-center">
                {this.props.socketUser.permission[1].status && (
                  <li
                    style={{ ...buttonStyle2, marginRight: 20 }}
                    id="sharecontent"
                    onClick={() => {
                      if (this.props.jitsiloaded) {
                        this.setState({ streamMode: "webrtc" });
                        this.props.openWebRTC();
                      }
                    }}
                  >
                    <i
                      className={"bx bx-broadcast text-success"}
                      style={{ fontSize: 20, lineHeight: "1.6" }}
                    ></i>
                  </li>
                )}

                <li
                  className="position-relative"
                  id="chatmessage"
                  onClick={() => {
                    this.props.openChatBox();
                  }}
                  style={{ ...buttonStyle2 }}
                >
                  <i
                    className={
                      this.props.chatOpen
                        ? "bx bx-chat text-info opacity-50"
                        : "bx bx-chat text-white"
                    }
                    style={{ fontSize: 20, lineHeight: "1.6" }}
                  ></i>
                  {this.props.unread && !this.props.chatOpen && (
                    <span
                      style={{ left: 5 }}
                      className="position-absolute top-1 start-0 translate-middle badge rounded-circle bg-success p-1"
                    >
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  )}
                </li>

                <li
                  className="position-relative"
                  style={{ ...buttonStyle2 }}
                  id="peoplejoined"
                  onClick={() => {
                    this.props.getParticipantList();
                  }}
                >
                  <i
                    className={
                      this.props.ptListOpen
                        ? "mdi mdi-account-group-outline text-info"
                        : "mdi mdi-account-group-outline text-white"
                    }
                    style={{ fontSize: 20 }}
                  ></i>{" "}
                  <span
                    className="position-absolute top-5 translate-middle badge rounded-pill bg-primary font-size-10"
                    style={{ cursor: "default" }}
                  >
                    {this.props.participantCount}
                  </span>
                </li>

                <li
                  style={{ ...buttonStyle2 }}
                  id="observationlist"
                  onClick={() => this.props.openOBRBox()}
                >
                  <i
                    className={
                      this.props.obrlistEnabled
                        ? "mdi mdi-format-list-bulleted text-info opacity-50"
                        : "mdi mdi-format-list-bulleted text-white"
                    }
                    style={{ fontSize: 20 }}
                  ></i>
                </li>
                {this.props.socketUser.permission[4].status && (
                  <li
                    style={{ ...buttonStyle2 }}
                    id="report"
                    onClick={() => {
                      this.props.reportModal();
                    }}
                  >
                    <i
                      className={
                        this.props.reportShareEnabled
                          ? "mdi mdi mdi-clipboard-list text-info opacity-50"
                          : "mdi mdi mdi-clipboard-list text-white"
                      }
                      style={{ fontSize: 20 }}
                    ></i>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ConfControls;


