import { List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";

const sidebarItems = [
  { name: "home", label: "Home", color: "red" },
  {
    name: "billing",
    label: "Billing",
    child: [
      {
        name: "Bill 1",
        label: "Bill 1",
        child: [
          {
            name: "statements",
            label: "Bill 1.1",
            child: [
              { name: "statements", label: "Bill 1.1.1" },
              { name: "reports", label: "Bill 1.1.2" }
            ]
          },
          { name: "reports", label: "Reports" }
        ]
      },
      { name: "reports", label: "Reports" }
    ]
  },
  {
    name: "settings",
    label: "Settings",
    color: "green",
    child: [
      { name: "profile", label: "Profile" },
      { name: "insurance", label: "Insurance" },
      {
        name: "notifications",
        label: "Notifications",
        child: [
          { name: "email", label: "Email" },
          {
            name: "desktop",
            label: "Desktop",
            child: [
              { name: "schedule", label: "Schedule" },
              { name: "frequency", label: "Frequency" }
            ]
          },
          { name: "sms", label: "SMS" }
        ]
      }
    ]
  }
];


const Sidebar = () => {
  return (
    <List disablePadding dense>
      {
        sidebarItems.map((sidebarItem, index) => (
          <RecursiveSideBar key={`${sidebarItem.name}${index}`}  {...sidebarItem} />
        ))
      }
    </List>
  )
}

const RecursiveSideBar = ({ label, color, child, depthStep = 10, depth = 0, ...rest }) => {
  return (
    <>
      <ListItem button dense {...rest} style={{ background: color }}>
        <ListItemText style={{ paddingLeft: depth * depthStep }}>
          <span>{label}</span>
        </ListItemText>
      </ListItem>
      {
        Array.isArray(child) && (
          <List disablePadding dense>
            {
              child.map((subItem) => (
                <RecursiveSideBar key={subItem.name} depth={depth + 1} depthStep={depthStep} {...subItem} />
              ))
            }
          </List>
        )
      }
    </>
  )
}

export default Sidebar;
