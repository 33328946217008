
import uuid from 'react-uuid'
var urlSocket = require("../../helpers/urlSocket")

const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }




let imageprocess = {
  convertBaseToURL: (image_url) => {
    var ImageURL = image_url;
    var block = ImageURL.split(";");
    var contentType = block[0].split(":")[1]; // In this case "image/gif"
    var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    var blob = b64toBlob(realData, contentType);

    return blob

  },

 
  
};

 export default imageprocess;
