import React, { Component, createRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,

} from "reactstrap";

class KRPanoControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isMenuExpanded:false,
    };
  }

  render() {
    return (
      <ul class="list-group list-group-horizontal-md p-1">
        <li
          class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
        >
          <i
            className={
              this.props.videoPlaying
                ? "mdi mdi-pause text-white"
                : "mdi mdi-play text-white"
            }
            style={{ fontSize: 25 }}
            onClick={() => this.props.togglePlay()}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
        >
          <i
            className={
              this.props.audioMute
                ? "mdi mdi-volume-high text-white"
                : "mdi mdi-volume-off text-white"
            }
            style={{ fontSize: 25 }}
            onClick={() => this.props.toggleMute()}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
        >
          <i
            className="mdi mdi-virtual-reality text-white"
            style={{ fontSize: 25 }}
            onClick={() => this.props.vrmode()}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
        >
          <i
            className="mdi mdi-camera text-white"
            style={{ fontSize: 25 }}
            onClick={() => this.props.takeScreenShot()}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
          onMouseDown={() => this.props.krpanoControls("leftkeyDown")}
          onMouseUp={() => this.props.krpanoControls("leftRightkeyUp")}
        >
          <i
            className={"mdi mdi-arrow-left-thick text-white"}
            style={{ fontSize: 25 }}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
          onMouseDown={() => this.props.krpanoControls("topkeyDown")}
          onMouseUp={() => this.props.krpanoControls("topBottomkeyUp")}
        >
          <i
            className={"mdi mdi-arrow-up-thick text-white"}
            style={{ fontSize: 25 }}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
          onMouseDown={() => this.props.krpanoControls("bottomkeyDown")}
          onMouseUp={() => this.props.krpanoControls("topBottomkeyUp")}
        >
          <i
            className={"mdi mdi-arrow-down-thick text-white"}
            style={{ fontSize: 25 }}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
          onMouseDown={() => this.props.krpanoControls("rightkeyDown")}
          onMouseUp={() => this.props.krpanoControls("leftRightkeyUp")}
        >
          <i
            className={"mdi mdi-arrow-right-thick text-white"}
            style={{ fontSize: 25 }}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0 flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
          onMouseDown={() => this.props.krpanoControls("zoomInDown")}
          onMouseUp={() => this.props.krpanoControls("zoomInOutUp")}
        >
          <i
            className={"mdi mdi-magnify-plus text-white"}
            style={{ fontSize: 25 }}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0  flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
          onMouseDown={() => this.props.krpanoControls("zoomOutDown")}
          onMouseUp={() => this.props.krpanoControls("zoomInOutUp")}
        >
          <i
            className={"mdi mdi-magnify-minus text-white"}
            style={{ fontSize: 25 }}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0  flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: this.props.krpanoSyncStopped
              ? "rgba(52,58,64,0.5)"
              : "rgba(255,3,3,0.6)",
          }}
          onClick={() => this.props.krpanoSyncStartStop()}
        >
          <i
            className={
              this.props.krpanoSyncStopped
                ? "mdi mdi-sync-off text-white"
                : "mdi mdi-sync text-white"
            }
            style={{ fontSize: 25 }}
          ></i>
        </li>
        <li
          class="list-group-item border-0 p-0  flex-row justify-content-center align-items-ceenter"
          style={{
            width: 40,
            display: this.state.isMenuExpanded ? "none" : "flex",
            backgroundColor: "rgba(52,58,64,0.5)",
          }}
          // onClick={() => this.props.krpanoControls("view")}
        >
          <Dropdown
            isOpen={this.state.dropup1}
            direction="up"
            toggle={() => this.setState({ dropup1: !this.state.dropup1 })}
          >
            <DropdownToggle
              className="btn btn-secondary"
              style={{ padding: "0 5px" }}
            >
              <i
                className={
                  this.props.isMenuExpanded
                    ? "mdi mdi-application-settings text-white"
                    : "mdi mdi-application-settings text-white"
                }
                style={{ fontSize: 25 }}
              ></i>
            </DropdownToggle>
            <DropdownMenu
              style={{
                top: 50,
                bottom: 50,
                backgroundColor: "rgba(255,255,255,0.8)",
              }}
            >
              <DropdownItem
                onClick={() => this.props.krpanoControls("normalview")}
              >
                Normal View
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.krpanoControls("flatview")}
              >
                Flat View
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.krpanoControls("fisheyeview")}
              >
                Fisheye View
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.krpanoControls("stereoview")}
              >
                Stereographic View
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.krpanoControls("archview")}
              >
                Architectural View
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.krpanoControls("panniniview")}
              >
                Pannini View
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.krpanoControls("ltplanetview")}
              >
                Little Planet View
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </li>

        <li
          class="list-group-item border-0 p-0  bg-dark d-flex flex-row justify-content-center align-items-ceenter"
          style={{ width: 40 }}
          onClick={() => {
            this.setState({
              isMenuExpanded: this.state.isMenuExpanded ? false : true,
            });
          }}
        >
          <i
            className={
              this.state.isMenuExpanded
                ? "fas fa-chevron-right text-white"
                : "fas fa-chevron-left text-white"
            }
            style={{
              fontSize: 20,
              lineHeight: "1.8",
            }}
          ></i>
        </li>
      </ul>
    );
  }
}

export default KRPanoControls
