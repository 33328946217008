import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import { AvForm, AvField } from "availity-reactstrap-validation";

import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";

import jsPDF, { jsPDFAPI } from "jspdf";

import _ from "lodash";
import { isEmpty, size, map } from "lodash";
import moment from "moment";

var urlSocket = require("../../helpers/urlSocket");

class ReportComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mtgInfo:false
    };
  }

  componentWillMount() {
    var meetingInfo = this.props.meetingInfo;

    console.log("meetingInfo", meetingInfo);
    let start = moment(meetingInfo.mstarted_on, "YYYY-MM-DD hh:mm:ss"); // some random moment in time (in ms)
    let end = moment(meetingInfo.mends_on, "YYYY-MM-DD hh:mm:ss"); // some random moment after start (in ms)
    let diff = end.diff(start);

    // execution
    let f = moment.utc(diff).format("HH:mm:ss");

    
    // document.addEventListener("keydown", function(event) {
    //     if( event.key === "Escape")
    //     {

    //     }
    //   })

    this.setState(
      {
        
        meetingInfo,
        totalDuration:f,
        height: window.innerHeight,
        mtgInfo:false,
        dataloaded: false,
      },
      function () {
        this.getConfigData();
      }
    );
  }

  getConfigData(){
    try {
        urlSocket
          .get("appconfig/appconfig")
          .then((response) => {
            var configData = response.data.data;
            this.setState({
                configData, 
                previewURL: configData.img_url,
            }, ()=>{
                this.getOBRList()
            });
          });
      } catch (error) {
        console.log("catch error", error);
      }
  }



  getOBRList = () => {
    try {
      urlSocket
        .post("mngobvrs/getobservationlist", {
          meetingInfo: this.state.meetingInfo,
        })
        .then((response) => {
          if (response.data.response_code === 500) {
            this.setState({
              obrList: response.data.data,
              mtgInfo:true,
              dataloaded: true,
            });
            console.log("data", response.data.data);
          }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  };

  getMC = (data) => {
   // console.log("data", data);

    try {
      urlSocket
        .post("mngobvrs/getmclist", {
          observationInfo: data,
        })
        .then((response) => {
          if (response.data.response_code === 500) {
            console.log("response.data.data", response.data.data)
            var mc = response.data.data;
            if (mc.length > 0) {
              this.setState({
                mc: mc,
                mcInfo: true,
                observationInfo: data,
              });
            } else {
              this.setState({
                observationInfo: data,
                mtgInfo:false,
                mcInfo: false,
              });
            }
          }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  };

  printPdf = () => {
    console.log("Print");
    var doc = new jsPDF("p","pt","a4");
    doc.html(document.querySelector('#print-observation'), {
      callback: function(pdf) {        
        pdf.save("observation-report.pdf");
      }
    });
  }

  render() {
    if (this.state.dataloaded) {
      const { emails } = this.state;
      const { SearchBar } = Search;
      // const { users } = this.state
      const options = {
        // pageStartIndex: 0,

        sizePerPage: 8,
        totalSize: this.state.obrList.length, // replace later with size(users),
        custom: true,
      };
      const columns = [
        {
          text: "id",
          dataField: "_id",
          sort: true,
          hidden: true,
          formatter: (cellContent, item) => <>{item._id}</>,
        },
        {
          dataField: "title",
          text: "List",
          sort: true,
          formatter: (cellContent, item, i) => {
            return (
              <>
                <div
                  key={i + "obr"}
                  style={{
                    margin: "5px 0px",
                    fontWeight: "600",
                    fontSize: "13px",
                  }}
                  className="text-secondary"
                >
                  {item.title}
                </div>
              </>
            );
          },
        },

        {
          dataField: "created_on",
          isDummyField: true,
          hidden: true,
          text: "updated on",
        },

        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, item) => {
            return (
              <div
                className="d-flex"
                style={{ display: "flex", alignContent: "center" }}
              >
                <Button
                  type="button"
                  color="primary"
                  size="sm"
                  className="btn-rounded waves-effect waves-light ps-3 pe-3 me-2"
                  onClick={() => {
                    this.getMC(item);
                  }}
                >
                  Show
                </Button>
              </div>
            );
          },
        },
      ];
      const defaultSorted = [
        {
          dataField: "created_on", // if dataField is not match to any column you defined, it will be ignored.
          order: "desc", // desc or asc
        },
      ];

      const selectRow = {
        mode: "checkbox",
        clickToSelect: true, // this.state.allowTableRowToSelect,
        onSelect: (row, isSelect, rowIndex, e) => {
          console.log("row selectedd", row, isSelect, rowIndex, e);
          this.getSelectedEOPT(row, isSelect, rowIndex, e);
        },
        // onSelectAll: (isSelect, rows, e) => {
        //     console.log("select all", rows,)
        // },
        // nonSelectable: this.state.label.endpoints
      };
      return (
          <div  style={{ minHeight: this.state.height-190, paddingBottom:0, borderBottom: "1px solid #dadada" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                paddingBottom: 20,
                alignItems: "center",
                borderBottom: "1px solid #dadada",
              }}
            >
              <div style={{ width: "80%", padding: "0 20px" }}>
                <span className="font-size-15 fw-bold text-primary">
                  Meeting : {this.state.meetingInfo.mname}
                </span>
                <div className="font-size-12">
                  {moment(this.state.meetingInfo.scheduled_on).format("LLLL")}{" "}
                  (IST)
                </div>
              </div>
            </div>
              <div className="d-xl-flex h-100">
                <div className="w-100">
                  <div className="d-md-flex h-100">
                    <div className="col-4 fixed  ">
                      <div
                        style={{

                          overflowY: "auto",
                        }}
                      >
                        <Row>
                          <h6 className="mt-4 font-size-17">Observations</h6>
                          <PaginationProvider
                            keyField="id"
                            data={this.state.obrList}
                            columns={columns}
                            pagination={paginationFactory(options)}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={this.state.obrList}
                                columns={columns}
                                search
                              >
                                {(toolkitprops) => (
                                  <React.Fragment>
                                    <Row
                                      className="mb-2"
                                      style={{ paddingTop: 10 }}
                                    >
                                      <Col lg="12">
                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitprops.searchProps}
                                            />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-hover"
                                            }
                                            bordered={false}
                                            striped={false}
                                            responsive
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Row>
                      </div>
                    </div>


                    <div
                      className="w-100"
                      style={{
                        borderLeft: "1px solid #dadada",
                        height:this.state.height-190,
                        overflow: "auto",
                        padding: 20,
                      }}
                    >
                      {/* <div style={{
                        marginBottom: "15px"
                      }}>
                        <Button className="btn-rounded waves-effect waves-light ps-3 pe-3" id="" onClick={() => {this.printPdf()}}>Generate PDF</Button>
                      </div> */}
                      {this.state.mcInfo ? (
                        <div id="print-observation">
                          <div className="mb-3">
                            <span className="font-size-15 text-primary">
                              Observation - {this.state.observationInfo.title}
                            </span>
                          </div>
                          {this.state.mc.map((item, idx) => {
                            console.log("item", item.muser_comment)
                            return (
                              <div
                              key={"mandc"+idx}
                                style={{
                                  borderBottom:
                                    idx < this.state.mc.length - 1
                                      ? "1px solid #dadada"
                                      : null,
                                }}
                                className="mb-3 pb-2"
                              >
                                <div>
                                  <div className="mb-2">
                                    <div className="font-size-13 text-primary">
                                      Observation by {item.muser_dname}
                                    </div>
                                    <span className="font-size-12">
                                      {item.muser_mailid}
                                    </span>
                                  </div>
                                  <div className="mb-3">
                                    <img 
                                    //crossOrigin="anonymous"
                                      src={
                                        this.state.previewURL + item.file_name
                                      }
                                      style={{ width: "50%" }}
                                    />
                                  </div>
                                  <div className="fw-bold">Comment </div>
                                  <div className="mt-1">
                                    <div>{item.muser_comment}</div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                          {this.state.mtgInfo ? 
                            <div>
                              <div className="mb-3">
                                <div className="font-size-15 fw-bold text-primary">Meeting : {this.state.meetingInfo.mname}</div>
                                {/* <div className="font-size-13">Conducted on : {this.state.meetingInfo.scheduled_on}</div> */}
                                <div className="font-size-13">Organised by : {this.state.meetingInfo.organized_by}</div>
                              </div>

                              <div className="mb-3">
                                <div className="font-size-13">Scheduled on : {moment(this.state.meetingInfo.mstarted_on).format("LLLL")}</div>
                              </div>

                              <div>
                                <div className="font-size-14 fw-bold mb-2">Participants</div>
                                {
                                  this.state.meetingInfo.participants.map((item, idx)=>{
                                    return(<div key={"part"+idx} className="">
                                      <div className="font-size-13 text-primary">{item.email_id}</div>
                                      {/* <div className="font-size-13" >{moment(item.joined_on).format("LLLL")}</div> */}
                                    </div>)
                                  })
                                }
                              </div>
                              
                            </div>
                           : (
                            <div>
                              <h4>No Information found</h4>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
          </div>
      );
    } else {
      return null;
    }
  }
}

export default ReportComp;
