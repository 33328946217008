var axios = require('axios');

var urlSocket = axios.create({
  /* LIVE URL*/
// baseURL: 'http://localhost:8096/',
   baseURL: 'https://360api.autolearn.in/',
  // baseURL: 'https://autolearn.in/demo360api/',
  mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
  // baseURL: 'http://eaudit.eparampara.com/api/',

  timeout: 0,


})

module.exports = urlSocket;